import benefitOne from "./dist/images/benefit1.png";
import benefitTwo from "./dist/images/benefit2.png";
import benefitThree from "./dist/images/Suzuki_car.png";
import benefitFour from "./dist/images/benefit4.png";
import cars from "./dist/images/cars.png";
import drivers from "./dist/images/drivers.png";
import uber from "./dist/images/uber.png";
import bolt from "./dist/images/bolt.png";
import littleCab from "./dist/images/littleCab.png";
import swvl from "./dist/images/Swvl 1.png";
import ola from "./dist/images/ola.png";
import grab from "./dist/images/grab.png";
import didi from "./dist/images/didi.png";
import gojek from "./dist/images/gojek.png";
import alchemist from "./dist/images/alchemist.svg";
import blackFounders from "./dist/images/black_founder.svg";
import ATV from "./dist/images/ATV.svg";
import googleStartups from "./dist/images/google-startups.svg";
import quesnay from "./dist/images/quesnay.svg";
import istanbul from "./dist/images/istanbul.svg";
import westerwelle from "./dist/images/westerwelle.svg";
import smartCities from "./dist/images/smartCities.svg";
import mobility54 from "./dist/images/mobility54.svg";
import EUAfrica from "./dist/images/EUAfrica.png";
import vehicle from "./dist/images/vehicle.svg";
import inspection from "./dist/images/inspection.svg";
import eSigning from "./dist/images/e-signing.svg";
import accounting from "./dist/images/accounting.svg";
import analysis from "./dist/images/analysis.svg";
import driver from "./dist/images/driver.svg";
import telematics from "./dist/images/telematics.svg";
import wallet from "./dist/images/wallet.svg";
import marketplace from "./dist/images/marketplace.svg";
import reputation from "./dist/images/reputation.svg";
import partnerLogo from "./dist/images/partner_logo.svg";
import driverLogo from "./dist/images/instadriver_logo.svg";
import product1 from "./dist/images/p_feature1.png";
import product2 from "./dist/images/p_feature2.png";
import product3 from "./dist/images/p_feature3.png";
import product4 from "./dist/images/p_feature4.png";
import product5 from "./dist/images/p_feature5.png";
import product6 from "./dist/images/product6.png";
import product7 from "./dist/images/product7.png";
import product8 from "./dist/images/product8.png";
import product9 from "./dist/images/product9.png";
import product10 from "./dist/images/product10.png";
import cleanCar from "./dist/images/cleanCar.png";
import garage from "./dist/images/garage.png";
import instadriverLogo from "./dist/images/instadriver-logo.svg";
import fleetsimplifyLogo from "./dist/images/fleetsimplify.svg";
import planImage1 from "./dist/images/calender.svg";
import planImage2 from "./dist/images/secure.svg";
import planImage3 from "./dist/images/diamond.svg";
import planImage4 from "./dist/images/commission.svg";
import people from "./dist/images/people.png";
import deleteAccount1 from "./dist/images/del_img1.png";
import deleteAccount2 from "./dist/images/del_img2.png";
import deleteAccount3 from "./dist/images/del_img3.png";
import deleteAccount4 from "./dist/images/del_img4.png";
import deleteAccount5 from "./dist/images/del_img5.png";
import deleteAccount6 from "./dist/images/del_img6.png";

export const accolades = [
  {
    id: 1,
    description:
      "The Google for Startups Black Founders Fund is an initiative by Google that provides $150,000, non-dilutive cash awards to Black-led startups with high potential. The fund aims to support and uplift Black entrepreneurs. In July 2023, Fleetsimplify was a recipient.",
    url: "https://blog.google/intl/en-africa/company-news/announcing-the-2023-black-founders-fund-cohort-in-africa/",
    img: blackFounders,
  },
  {
    id: 2,
    description:
      "Mobility 54 Investment is an venture capital arm of Toyota Tsusho. We got award in form of investment from Mobility 54 and CFAO after we won 2nd position in mobility track in the “Digital Transformation Challenge for Africa 2022” hosted in Paris. ",
    url: "https://www.mobility54.com/news/202209pitchevent",
    img: mobility54,
  },
  {
    id: 3,
    description:
      "Google for Startups Accelerator Africa is a 3 month virtual accelerator program for high potential Seed to Series A tech startups based in Africa. Fleetsimplify got into GFSAA Class 7.",
    url: "https://africa.googleblog.com/2022/03/meet-google-for-startups-accelerator.html",
    img: googleStartups,
  },
  {
    id: 4,
    description:
      "Alchemist Accelerator, which is based in Silicon Valley, is one of the top startups accelerators in the US. Fleetsimplify, got accepted and funded by Alchemist.",
    url: "https://www.alchemistaccelerator.com/portfolio?class=24",
    img: alchemist,
  },
  {
    id: 5,
    description:
      "Smart Cities Innovation Programme (SCIP) cherry-picked and supported 30 high-potential start-ups from Africa divided into 3 tracks: Smart Mobility, Smart Housing and Fintech for Affordability. ",
    url: "https://afrilabs.com/promoting-african-start-ups-for-the-continents-urban-future-smart-cities-innovation-programme-launched-by-rwandan-german-development-cooperation/",
    img: smartCities,
  },
  {
    id: 6,
    description:
      "DIGILOGIC is a H2020 funded project helping startups, SMEs and innovators from Europe and Africa to jointly develop smart logistic solutions, in close cooperation with industries and investors, securing sustainability and success.",
    url: "https://community.digilogic.africa/challenges",
    img: EUAfrica,
  },
  {
    id: 7,
    description:
      "Startup Istanbul hosts a world-class startup competition in Istanbul. They chery-pick 100 best startups from across the world for its competition. Fleetsimplify won 3rd position.",
    url: "https://twitter.com/istanbulstartup/status/1186291018557263873",
    img: istanbul,
  },
  {
    id: 8,
    description:
      "African Tech Vision, is an organization supporting early-stage female tech founders from Africa. The startups get selected after a rigorous selection & interview process.",
    url: "https://techtrendske.co.ke/26-startups-selected-to-join-african-tech-visions-2nd-mentorship-program-for-african-female-founders/",
    img: ATV,
  },
  {
    id: 9,
    description:
      "Backed by Quesnay, Quesnay Female Founders in Mobility is a program that supports women entrepreneurs in the mobility ecosystem. In 2020, Fleetsimplify was Top 5 Finalists of the program.",
    url: "https://www.quesnays.com/ff-mobility-2020",
    img: quesnay,
  },
  {
    id: 10,
    description:
      "Westerwelle Foundation is a Germany-headquartered foundation that empowers entrepreneurs in Africa, Asia, and Latin America through programmes and startup hubs.",
    url: "https://www.linkedin.com/posts/westerwellefoundation_yfp-2021-activity-6849313834474213376-eIOo",
    img: westerwelle,
  },
];

export const howItWorks = [
  {
    title: "Calculate earnings your vehicle can possibly generate",
    content:
      "Even before you onboard your vehicle, see what revenues it can generate. If you are ok with that, proceed to onboard your vehicle. ",

    button: {
      text: "Calculate Earnings",
      link: "/rent-vehicle",
    },
  },
  {
    title: "Agree to management agreement",
    content:
      "Our agreement is standard and available online. No hidden terms. You read the agreement, and if ok with it, you will e-sign during the onboarding exercise.",
    button: {
      text: "View Agreement",
      link: "/agreement",
    },
  },
  {
    title: "Onboard via the app",
    content:
      "We have built an app for our clients to onboard their cars, see the performance of their cars, access revenues in real-time, communicate in-app, see asset maintenance history, see car location and mileage, etc. ",
    button: {
      text: "View App Screenshots",
      link: "/partner-app-screenshots",
    },
  },
  {
    title:
      "Access your money via the app’s ewallet. You make money stress-free",
    content:
      "Drivers pay via the mobile app and you access the money in real-time via the app. The ewallet feature enables both the driver and partner to pay and receive money. Fleetsimplify handles all fleet management work while you earn money hassle-free. ",
    button: {
      text: "See Management Plan",
      link: "/pricing?type=management-plan",
    },
  },
];

export const benefitsData = [
  {
    title: "You  earn well, and without hustling",
    content:
      "Once you onboard your car to Fleetsimplify via the app, that’s it. We handle the rest. From onboarding your vehicle to ride-sharing apps, hiring drivers, collecting revenue, to taking care of vehicle service and repair, et cetera. ",
    image: benefitOne,
  },
  {
    title: "Track performance of your business and your vehicles on our app.",
    content:
      "We provide total transparency to our customers via the app that we built for them. The app shows you how much the vehicle has earned, its realtime location, KM covered, service done, asset maintenance history, et cetera. ",
    image: benefitTwo,
  },
  {
    title: "Get back your vehicle in great condition",
    content:
      "Our drivers handle the vehicles with utmost care and responsibility. Our tech tools help to realize that. Once you end your contract, your vehicle is in good shape.",
    image: benefitThree,
  },
  {
    title:
      "For automakers and autodealers. You don't worry about loss of revenue due to low sales.",
    content:
      "No more vehicles wallowing in the parking waiting for buyers. We monetize the vehicle for you. Besides, the world is moving towards shared mobility. Ownership of vehicles will be less and less. ",
    image: benefitFour,
  },
];

export const numbersData = [
  {
    image: cars,
    title: "Vehicles Monetized ",
    count: "500+",
  },
  { image: drivers, title: "Drivers Matched", count: "2500+" },
];

export const platforms = [uber, bolt, littleCab, swvl, ola, grab, didi, gojek];

export const faqs = [
  {
    title:
      "What happens when my vehicle gets into an accident? What happens when my vehicle gets into an accident?",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, et libero imperdiet morbi nunc amet pellentesque laoreet. Orci accumsan viverra interdum orci magna eget faucibus massa nulla. Sem at arcu dolor suspendisse. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, et libero imperdiet morbi nunc amet pellent",
  },
  {
    title: "What happens when my vehicle gets into an accident?",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, et libero imperdiet morbi nunc amet pellentesque laoreet. Orci accumsan viverra interdum orci magna eget faucibus massa nulla. Sem at arcu dolor suspendisse.",
  },
  {
    title: "What happens when my vehicle gets into an accident?",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, et libero imperdiet morbi nunc amet pellentesque laoreet. Orci accumsan viverra interdum orci magna eget faucibus massa nulla. Sem at arcu dolor suspendisse.",
  },
  {
    title: "What happens when my vehicle gets into an accident?",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, et libero imperdiet morbi nunc amet pellentesque laoreet. Orci accumsan viverra interdum orci magna eget faucibus massa nulla. Sem at arcu dolor suspendisse.",
  },
  {
    title: "What happens when my vehicle gets into an accident?",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, et libero imperdiet morbi nunc amet pellentesque laoreet. Orci accumsan viverra interdum orci magna eget faucibus massa nulla. Sem at arcu dolor suspendisse.",
  },
  {
    title: "What happens when my vehicle gets into an accident?",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, et libero imperdiet morbi nunc amet pellentesque laoreet. Orci accumsan viverra interdum orci magna eget faucibus massa nulla. Sem at arcu dolor suspendisse.",
  },
  {
    title: "What happens when my vehicle gets into an accident?",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, et libero imperdiet morbi nunc amet pellentesque laoreet. Orci accumsan viverra interdum orci magna eget faucibus massa nulla. Sem at arcu dolor suspendisse.",
  },
  {
    title: "What happens when my vehicle gets into an accident?",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, et libero imperdiet morbi nunc amet pellentesque laoreet. Orci accumsan viverra interdum orci magna eget faucibus massa nulla. Sem at arcu dolor suspendisse.",
  },
  {
    title: "What happens when my vehicle gets into an accident?",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, et libero imperdiet morbi nunc amet pellentesque laoreet. Orci accumsan viverra interdum orci magna eget faucibus massa nulla. Sem at arcu dolor suspendisse.",
  },
  {
    title: "What happens when my vehicle gets into an accident?",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, et libero imperdiet morbi nunc amet pellentesque laoreet. Orci accumsan viverra interdum orci magna eget faucibus massa nulla. Sem at arcu dolor suspendisse.",
  },
  {
    title: "What happens when my vehicle gets into an accident?",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, et libero imperdiet morbi nunc amet pellentesque laoreet. Orci accumsan viverra interdum orci magna eget faucibus massa nulla. Sem at arcu dolor suspendisse.",
  },
  {
    title: "What happens when my vehicle gets into an accident?",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, et libero imperdiet morbi nunc amet pellentesque laoreet. Orci accumsan viverra interdum orci magna eget faucibus massa nulla. Sem at arcu dolor suspendisse.",
  },
];

export const technology = [
  {
    icon: driver,
    title: "Driver Onboarding Portal",
    content:
      "This is Linkedin for drivers. A professional platform exclusive for drivers. All drivers in the world will be creating professional profiles/digital CV’s on our platform. The drivers are vetted and get verification badges. ",
  },
  {
    icon: vehicle,
    title: "Vehicle Onboarding Portal",
    content:
      "Car owners who want to monetize their cars in the gig economy list their cars on our platform. These cars are verified and given gig-ready badges. Which means that drivers on the portal can be matched to them for monetization in the ride-hailing apps.",
  },
  {
    icon: marketplace,
    title: "Matching Marketplace",
    content:
      "On the platform, the vetted and verified drivers are matched to the gig cars listed. Drivers and partners schedule on the app vehicle hand-over and pick-up. They are able to adjust rental fees and handle issues digitally.",
  },
  {
    icon: wallet,
    title: "In-App transactions via E-wallet",
    content:
      "With E-Wallets, the vehicle rental fees are paid up in-app and partners can access their funds in real time. All players (i.e.drivers, fleet managers and vehicle owners), experience swift business transactions and sustainable relationships. ",
  },
  {
    icon: telematics,
    title: "Telematics Software",
    content:
      "Vehicles are installed with telematics thus relaying crucial data. With the data collected from the installed telematics, we derive meaningful reports to help partners with their gig business e.g  driver behaviors; preventive maintenance, GPS location, etc",
  },
  {
    icon: analysis,
    title: "Analytics",
    content:
      "We are creating the Palantir for mobility sector. All the datasets on out platform such as monetization history, ratings and reviews, telematics reports, et cetera will be crunched to produce meaningful analytics to help in many things.",
  },
  {
    icon: accounting,
    title: "Accounting Module",
    content:
      "With e-wallets, we automated accounting and hence every player is able to see the performance of their business in real time. Also, we have expense manager, liability recording, profit & loss, et cetera.Users do not need external accounting app.",
  },
  {
    icon: eSigning,
    title: "E-signing",
    content:
      "Just like Google terms and policies are online, vehicle owners and drivers matched will use our online agreement. And the acceptance of the agreements will be digitally. This will eliminate the need hard-copy agreements.",
  },
  {
    icon: inspection,
    title: "Digital Vehicle Inspection",
    content:
      "We have built a vehicle inspection feature where partners and drivers can undertake online/in-app inspection of car during vehicle hand-over and vehicle return. Hence easily detect and resolve car issues with evidence.",
  },
  {
    icon: reputation,
    title: "Reputation Rating Technology",
    content:
      "The driver and partner rate and review each other after the gig ends. This ensures that the driver takes good care of the car, remits agreed revenues, and complies with agreed terms. The rating technology was instrumental for Uber & Bolt.",
  },
];

export const partnerAppData = {
  title: "Instadriver Partner (Partner App)",
  logo: partnerLogo,
  desc: "Instadriver Partner is an app that helps you hire drivers, monetize your vehicles and flaunt your transport-related business. For the gig partner, (i.e. a vehicle owner interested in monetizing car on ride-hailing apps), you  can use the SaaS that comes with Instadriver partner app to self-manage your gig car or select a fleet manager to manage it for you. The tools we have built on the app will help you monetize your car profitably and conveniently. The app is curated for the transport gig economy hence your gig business will now be sustainable. On the app, you can also select a fleet manager to manage the car on your behalf, and you will be able to have access to everything about your business. See some of the features below.",
  features: [
    {
      id: 1,
      img: product1,
      title: "Succint glance of your gig car business",
      desc: "On your home, get a quick view of your revenues to-date, driver, car location, mileage, next service, et cetera.",
    },
    {
      id: 2,
      img: product2,
      title: "E-wallet to access money in real-time",
      desc: "Access an e-wallet. Withdraw your funds anytime. Whenever drivers pay, you instantly access the money.",
    },
    {
      id: 3,
      img: product3,
      title: "View breakdown of all payins",
      desc: "Transparency of all rental fees fetched by your car. Filter by month and year, see gigs and its drivers.",
    },
    {
      id: 4,
      img: product4,
      title: "Know your profit & loss position",
      desc: "All your gig expenses and revenues are recorded. This helps you the net profit for your gig business.",
    },
    {
      id: 5,
      img: product5,
      title: "See regular car inspections done",
      desc: "We do car inspections when a new gig is started and on a monthly basis. You also view this on your app.",
    },
  ],
};

export const driverAppData = {
  title: "Instadriver (Driver App)",
  logo: driverLogo,
  desc: "Instadriver is Linkedin for drivers. In its literal sense. On this platform, drivers create professional profiles aka digital CV. The online professional profile is sufficient enough to make an employer decide whom to hire. Instadriver is also that platform where you will find abundant job ads posted by employers all over the world. This makes it possible for drivers to find good jobs and earn a decent living. Instadriver has a section for gig cars, hence partners or vehicle owners who want to monetize their vehicles on Uber or Bolt come to Instadriver to list their cars for driver matching. Hence, as a driver, you can get matched to these quality cars and start driving for Uber or Bolt and make a living. ",
  features: [
    {
      id: 6,
      img: product6,
      title: "Create an online professional profile",
      desc: "This is your digital CV. Get a unique handle and send it to your potential employers. We are Linkedin for drivers.",
    },
    {
      id: 7,
      img: product7,
      title: "Access local and international jobs",
      desc: "We are creating abundance of jobs. Find tons of local and international jobs and apply with one tap.",
    },
    {
      id: 8,
      img: product8,
      title: "Get matched to gig cars and earn a living",
      desc: "Partners list their cars to be monetized on apps such as Uber and Bolt. Request to get matched to these cars.",
    },
    {
      id: 9,
      img: product9,
      title: "Create hire me cards and get hired fast",
      desc: "Increase the odds of being hired by posting a hire me card. Employers view these cards to hire.",
    },
    {
      id: 10,
      img: product10,
      title: "Manage your gig car partnerships",
      desc: "Total transparency for your partnership. See your payouts, liabilities, schedules, et cetera.",
    },
  ],
};

export const driversLookingForVehicles = {
  title: "Two Steps To Drive For Fleetsimplify",
  desc: "It is very simple to get matched to a gig car from Fleetsimplify. All you need to do is to follow the following two steps. Also watch the videos in each step to get a further explanation of the driver onboarding process.",
  features: [
    {
      id: 1,
      img: cleanCar,
      title:
        "You instantly get access to quality car and start working and earn a decent living.",
      desc: "For vehicle owners to have a sustainable business, it is prudent to onboard vehicles that are in good condition. Fleetsimplify chery-picks best vehicles to onboard to its platform. This helps all parties:  Vehicle owners, drivers and Fleetsimplify have a financially sustainable relationship.",
    },
    {
      id: 2,
      img: garage,
      title: "When vehicles runs into issues, we repair immediately",
      desc: "Unlike other partners, when a vehicle has mechanical issues, it stays for days and weeks before its repaired. This means that the driver will be jobless while the vehicle is at the garage. For Fleetsimplify, we repair our vehicles immediately. In under 24 hours, the vehicle is back on the road.",
    },
  ],
  applications: [
    {
      id: 1,
      logo: instadriverLogo,
      title: "Create driver account on Instadriver app ",
      desc: "Instadriver is a product of Fleetsimplify. Instadriver is a driver-employer marketplace. In other words, it is Linkedin for drivers. Drivers create professional profile for their driving career and they get a unique handle. It’s like a digital CV. Employers looking into hiring drivers come to Instadriver to find them. As a driver, you will find abundant job opportunities, from local jobs to international jobs and also, you will find gig cars to work with and earn a decent living. Our mission is create job opportunities for drivers.",
      videoUrl: "https://youtu.be/-U6dxZVPYgE",
      videoCaption: "How to get hired with an Instadriver driver profile",
    },
    {
      id: 2,
      logo: fleetsimplifyLogo,
      title:
        "Go to “Gig Cars” on Instadriver and get matched to cars listed by Fleetsimplify ",
      desc: "“Gig Cars” is a feature on Instadriver where partners or vehicle owners list their vehicles for driver matching in their efforts to monetize the cars and earn passive revenue stream. To drive for cars provided by Fleetsimplify, you can see the cars under Fleetsimplify logo, or use the search feature to search cars by Fleetsimplify. You will find a lot of cars and all you need to do is to tap “Get matched to this gig car”. Here at Fleetsimplify, we always offer quality cars to our drivers. ",
      videoUrl: "https://youtu.be/gn_bRZEX3co",
      videoCaption: "How can a driver get matched to a gig car",
    },
  ],
};

export const deleteAccountSteps = [
  {
    id:1,
    title:'Delete my account feature',
    desc:'From the sidebar you can access the delete my account feature to begin the process',
    image: deleteAccount1,
  },
  {
    id:2,
    title:'Delete my account page',
    desc:'Here you can see the delete account page and the disclaimer it comes with',
    image: deleteAccount2,
  },
  {
    id:3,
    title:'Selecting your reason for deletion',
    desc:'After reading the disclaimer, if you still want to proceed to deleting your account then you can pick a reason why.',
    image: deleteAccount3,
  },
  {
    id:4,
    title:'Account deletion',
    desc:'After selecting the reason for your account deletion then the “delete my account” button becomes active to be clicked.',
    image: deleteAccount4,
  },
  {
    id:5,
    title:'Deletion pop-up',
    desc:'After clicking the “delete my account” button this pop-up comes up confirming your action.',
    image: deleteAccount5,
  },
  {
    id:6,
    title:'Caution banner',
    desc:'Once the process is done your Homepage now has a count down banner for 30days and can be cancelled anytime within that 30days.',
    image: deleteAccount6,
  },
];

export const earningPlans = {
  plans: [
    {
      id: 1,
      title: "Daily Earning",
      subtitle:
        "Perfect if you have time for a few errands and ready to transfer some risks to fleet manager",
      planImage: planImage1,
      price: {
        monthly: 36000,
        yearly: 432000,
      },
      features: [
        {
          id: 0,
          title:
            "Daily earnings of Kshs. 1400 guaranteed as long as the car works.",
        },
        {
          id: 1,
          title: "All repair & maintenance on car owner",
          tooltipText:
            "In this line of business, and according to our stats and research, car owners service their cars every 1.5 months. That comes to 8 times a year. Given that service cost is Kshs. 3,500 or more, in a year, car owner spends Kshs. 28,000. Divide that by 12 months, service cost per month becomes Kshs. 2,300. This amount has not factored in the monthly repair costs attributed to driver negligence or minor accidents which is something like Kshs. 2000",
        },
        {
          id: 2,
          title: "Wear & tear costs on car owner",
          tooltipText:
            "In this line of business, and according to our stats and research, car owners spend about Kshs. 2000 or more per month on costs related to wear and tear costs..",
        },
        {
          id: 3,
          title:
            "You are free to take your car to a garage of your choice. We will not be liable for poor maintenance and repair work done by your garage.",
        },
        {
          id: 4,
          title: "Insurance cost paid by the car owner",
          tooltipText:
            "In this line of business, insurance cover which includes excess protector is between Kshs. 47,000 to Kshs. 62,000. If you go with Kshs. 47K, it means you will be paying Kshs. 3,917 per month.",
        },
        {
          id: 5,
          title: "Free paint job on car once contract ends",
        },
        {
          id: 6,
          title: "Can choose 6 months or 1 year rental period",
        },
        {
          id: 7,
          title: "Fixed & guaranteed earnings",
        },
        {
          id: 8,
          title:
            "Get an app for 1) Accessing your money via an ewallet & for observing the performance of the business",
        },
      ],
    },
    {
      id: 2,
      title: "Commission Based",
      subtitle: "For those wanting to de-risk their investment.",
      planImage: planImage4,
      price: {
        monthly: 33000,
        yearly: 396000,
      },
      features: [
        {
          id: 0,
          title:
            "Earn 85% of rental fee paid. Car rented at Kshs. 1500 per day. ",
        },
        {
          id: 1,
          title: "All repair & maintenance on car owner",
        },
        {
          id: 2,
          title: "Wear & tear costs on car owner",
        },
        {
          id: 4,
          title: "Insurance cost paid by the car owner",
        },
        {
          id: 5,
          title: "Free paint job on car once contract ends",
        },
        {
          id: 6,
          title: "Can choose 6 months or 1 year rental period",
        },
        {
          id: 7,
          title: "Fixed & guaranteed earnings",
        },
        {
          id: 8,
          title:
            "Get an app for 1) Accessing your money via an ewallet & for observing the performance of the business",
        },
      ],
    },
    {
      id: 3,
      title: "Secure",
      subtitle: "For those wanting to de-risk their investment.",
      planImage: planImage2,
      price: {
        monthly: 30000,
        yearly: 360000,
      },
      features: [
        {
          id: 1,
          title:
            "All repair & maintenance on Fleetsimplify. This includes engine replacement.",
        },
        { id: 2, title: "Wear & tear costs on Fleetsimplify" },
        {
          id: 3,
          title:
            "Witness your car's maintenance at Glive's state-of-art garages by live- streaming it via our app or by visiting the garage at the scheduled maintenance time.",
        },
        { id: 4, title: "Insurance cost paid by the car owner" },
        {
          id: 5,
          title:
            "Enjoy regular free paint touch-ups and a full paint job upon contract end",
        },
        { id: 6, title: "Can choose 6 months or 1 year rental period" },
        { id: 7, title: "Fixed & guaranteed earnings" },
        {
          id: 8,
          title:
            "Car owners will be pre-paid full month-earnings on day 1 after car onboarded and agreement signed. On top of that, they will still get real-time earnings on a daily basis for the current period.",
        },
        {
          id: 9,
          title:
            "Get an app for 1) Accessing your money via an ewallet & for observing the performance of the business",
        },
      ],
    },
    {
      id: 4,
      title: "Luxury",
      subtitle:
        "For those wanting to de-risk their investment & eliminate headaches",
      planImage: planImage3,
      price: {
        monthly: 27000,
        yearly: 324000,
      },
      features: [
        {
          id: 1,
          title:
            "All repair & maintenance on Fleetsimplify. This includes engine replacement.",
        },
        { id: 2, title: "Wear & tear costs on Fleetsimplify" },
        {
          id: 3,
          title:
            "Witness your car's maintenance at Glive's state-of-art garages by live- streaming it via our app or by visiting the garage at the scheduled maintenance time.",
        },
        { id: 4, title: "Insurance cost paid by Fleetsimplify" },
        {
          id: 5,
          title:
            "Enjoy regular free paint touch-ups and a full paint job upon contract end",
        },
        { id: 6, title: "Can choose 6 months or 1 year rental period" },
        { id: 7, title: "Fixed & guaranteed earnings" },
        {
          id: 8,
          title:
            "Car owners will be pre-paid full month-earnings on day 1 after car onboarded and agreement signed. On top of that, they will still get real-time earnings on a daily basis for the current period.",
        },
        {
          id: 9,
          title:
            "Get an app for 1) Accessing your money via an ewallet & for observing the performance of the business",
        },
      ],
    },
  ],
  features: [
    "Free paint job on car once contract ends",
    "Can choose 6 months or 1 year rental period",
    "Fixed & guaranteed earnings",
    "Get an app for 1) Accessing your money via an ewallet & for observing the performance of the business",
  ],
  questions: [
    {
      id: 1,
      question: "Do you currently have the car or intend to buy?",
      answers: [
        { id: 1, answer: "I have the car", short: "Own" },
        { id: 2, answer: "I intend to buy", short: "Buy" },
      ],
    },
    {
      id: 2,
      question: "How long you intend to rent your car to Fleetsimplify?",
      answers: [
        { id: 1, answer: "6 months rental period", short: "6 months" },
        { id: 2, answer: "1 year rental period", short: "1 year" },
      ],
    },
    {
      id: 3,
      question: "Which rental plan do you desire to pick?",
      answers: [
        {
          id: 1,
          answer: "Daily earning plan",
          short: "Basic",
          image: planImage1,
        },
        {
          id: 2,
          answer: "Secure rental plan",
          short: "Secure",
          image: planImage2,
        },
        {
          id: 3,
          answer: "Luxury rental plan",
          short: "Luxury",
          image: planImage3,
        },
        {
          id: 4,
          answer: "Commission based plan",
          short: "Commission",
          image: planImage4,
        },
      ],
    },
  ],
  instructions: [
    "Select your preferred rental period. We have 2 tiers. You can either rent out your car for 6 months or 1 year. Don’t worry, in each contract, you can terminate or renew.",
    "Don’t worry. This is only an intention to pick a rental plan. Once you have successfully onboarded a car, you will be asked to select and agree to a rental plan.",
  ],
};

export const fleetAgreements = {
  luxury: [
    {
      id: 1,
      title: "Monthly Rental Income Guarantee.",
      description:
        "To ensure financial security for car owners, The Company commits to guarantee monthly rental income regardless whether drivers remit the agreed-upon rental fees or not. Even when the car is incapacitated due to repairs or otherwise, the car owner will still receive the monthly rental income as per selected plan.",
      videoUrl: people,
    },
    {
      id: 2,
      title: "Regular Maintenance Cost Covered.",
      description:
        "The Company will provide regular, routine maintenance for the vehicle at no cost to the Vehicle Owner. This includes, but is not limited to, scheduled oil changes, tire rotations, brake checks and replacements, engine tune-ups, battery checks, fluid level checks (including brake fluid, transmission fluid, power steering fluid, and coolant), air filter changes, fuel filter changes, spark plug replacements, belt and hoses check, wheel alignment, and any other maintenance tasks recommended by the vehicle manufacturer in the owner's manual or as per industry standards.",
      videoUrl: people,
    },
    {
      id: 3,
      title: "Repair Cost Covered, even engine replacement.",
      description:
        "The Company shall be responsible for covering the costs of all repairs required as a result of regular ride-hailing operation or damages caused by the assigned driver during the term of the Agreement. This includes, but is not limited to, mechanical repairs due to engine failure, transmission issues, electrical system faults, brake system repairs, and repairs as a result of damages caused by vandalism. Even engine replacement shall be covered by the Company.",
      videoUrl: people,
    },
    {
      id: 4,
      title: "Wear and Tear Coverage.",
      description:
        "The Company agrees to cover the costs of repairs and replacements necessary due to normal wear and tear from the operation of the vehicle for ride-hailing services and other uses.",
      videoUrl: people,
      items: [
        "Replacement of worn - out tires.",
        "Replacement of worn brake pads and discs.",
        "Replacement or repair of worn suspension components, such as shocks and struts.",
        "Repair or replacement of worn-out or damaged interior components, such as seat covers, mats, and other surfaces frequently used by passengers.",
        "Replacement of worn or damaged wiper blades.",
        "Replacement of burnt-out bulbs in headlights, taillights, or interior lighting.",
        "Replacement of malfunctional batteries.",
        "Repair or replacement of power window motors.",
      ],
      additions:
        "In the event of early termination, wear and tear costs, such as tire replacements, are prorated and split between both Parties. This ensures a fair distribution of expenses. However, if the full rental period is completed, no prorating applies, and costs are absorbed by the Company.",
    },
    {
      id: 5,
      title: "Rental Plan Period and Termination.",
      description:
        "The Vehicle Owner can rent out the vehicle to the Company for a period of either six (6) months or one (1) year, and after expiration of the rental period, the agreement can be renewed upon mutual consent. Despite the chosen rental period, either party may terminate this Agreement prematurely by providing a seven (7) days' notice via the Company's dedicated mobile application. And all outstanding payments and obligations shall be settled promptly.",
      videoUrl: people,
    },
    {
      id: 6,
      title: "Vehicle Management, Branding, and Use.",
      description:
        "Under this Agreement, the Company retains exclusive management rights of the vehicle, primarily designating its use for ride-hailing platforms. Nonetheless, the vehicle may occasionally be used for other purposes as deemed necessary by the Company. The Company is permitted to brand the vehicle for promotional or commercial purposes. At the termination of this Agreement, all branding will be removed by the Company, and the vehicle will be restored to its original color and condition, at the Company's expense. However, if a vehicle owner initiates agreement termination before expiration of the rental period, the cost of branding removal will equally be split between the Company and The Vehicle Owner.",
      videoUrl: people,
    },
    {
      id: 7,
      title: "Insurance Responsibility.",
      description:
        "The Company will secure and maintain, at its own cost, comprehensive vehicle insurance for the car during this Agreement. This coverage will meet jurisdictional requirements and cover for potential damages or loss arising from the vehicle's use in ride-hailing services and other car use cases.. The Company will manage all claims and interactions with the insurance provider.",
      videoUrl: people,
    },
    {
      id: 8,
      title: "Post-Rental Paint Job.",
      description:
        "Upon the expiration of the rental period under this Agreement, the Company will provide a free paint job for the vehicle to restore its original color and appearance, at the Company's expense. The quality and type of paint used will match or exceed the original paint on the vehicle at the time of entering this Agreement.",
      videoUrl: people,
    },
    {
      id: 9,
      title: "Mobile Application Access.",
      description:
        "Vehicle Owners will be granted access to a proprietary mobile application, facilitating: 1) succinct overview of their gig car business; 2) Real-time access to earnings via an e-wallet; 3) Detailed insight into all financial transactions; 4) Profit and loss overview; 5) Access to regular vehicle inspection reports, and other reports. This platform ensures transparency, real-time financial accessibility, and simplified accounting for the Vehicle Owners.",
      videoUrl: people,
    },
    {
      id: 10,
      title: "Modification of Agreement.",
      description:
        "Any amendments or modifications to this Agreement must be mutually agreed upon by both Parties and will be acknowledged via the Company's dedicated mobile application. Unilateral changes to any terms of this Agreement by any Party are not permitted. Agreed-upon changes will supersede previous terms and become a part of this Agreement.",
      videoUrl: people,
    },
  ],
  secure: [
    {
      id: 1,
      title: "Monthly Rental Income Guarantee.",
      description:
        "To ensure financial security for car owners, The Company commits to guarantee monthly rental income regardless whether drivers remit the agreed-upon rental fees or not. Even when the car is incapacitated due to repairs or otherwise, the car owner will still receive the monthly rental income as per selected plan.",
      videoUrl: people,
    },
    {
      id: 2,
      title: "Regular Maintenance Cost Covered.",
      description:
        "The Company will provide regular, routine maintenance for the vehicle at no cost to the Vehicle Owner. This includes, but is not limited to, scheduled oil changes, tire rotations, brake checks and replacements, engine tune-ups, battery checks, fluid level checks (including brake fluid, transmission fluid, power steering fluid, and coolant), air filter changes, fuel filter changes, spark plug replacements, belt and hoses check, wheel alignment, and any other maintenance tasks recommended by the vehicle manufacturer in the owner's manual or as per industry standards.",
      videoUrl: people,
    },
    {
      id: 3,
      title: "Repair Cost Covered, even engine replacement.",
      description:
        "The Company shall be responsible for covering the costs of all repairs required as a result of regular ride-hailing operation or damages caused by the assigned driver during the term of the Agreement. This includes, but is not limited to, mechanical repairs due to engine failure, transmission issues, electrical system faults, brake system repairs, and repairs as a result of damages caused by vandalism. Even engine replacement shall be covered by the Company.",
      videoUrl: people,
    },
    {
      id: 4,
      title: "Wear and Tear Coverage.",
      description:
        "The Vehicle Owner agrees to cover the costs of repairs and replacements necessary due to normal wear and tear from the operation of the vehicle for ride-hailing services and other uses.",
      videoUrl: people,
      items: [
        "Replacement of worn - out tires.",
        "Replacement of worn brake pads and discs.",
        "Replacement or repair of worn suspension components, such as shocks and struts.",
        "Repair or replacement of worn-out or damaged interior components, such as seat covers, mats, and other surfaces frequently used by passengers.",
        "Replacement of worn or damaged wiper blades.",
        "Replacement of burnt-out bulbs in headlights, taillights, or interior lighting.",
        "Replacement of malfunctional batteries.",
        "Repair or replacement of power window motors.",
      ],
      additions:
        "In the event of early termination, wear and tear costs, such as tire replacements, are prorated and split between both Parties. This ensures a fair distribution of expenses. However, if the full rental period is completed, no prorating applies, and costs are absorbed by the Company.",
    },
    {
      id: 5,
      title: "Rental Plan Period and Termination.",
      description:
        "The Vehicle Owner can rent out the vehicle to the Company for a period of either six (6) months or one (1) year, and after expiration of the rental period, the agreement can be renewed upon mutual consent. Despite the chosen rental period, either party may terminate this Agreement prematurely by providing a seven (7) days' notice via the Company's dedicated mobile application. And all outstanding payments and obligations shall be settled promptly.",
      videoUrl: people,
    },
    {
      id: 6,
      title: "Vehicle Management, Branding, and Use.",
      description:
        "Under this Agreement, the Company retains exclusive management rights of the vehicle, primarily designating its use for ride-hailing platforms. Nonetheless, the vehicle may occasionally be used for other purposes as deemed necessary by the Company. The Company is permitted to brand the vehicle for promotional or commercial purposes. At the termination of this Agreement, all branding will be removed by the Company, and the vehicle will be restored to its original color and condition, at the Company's expense. However, if a vehicle owner initiates agreement termination before expiration of the rental period, the cost of branding removal will equally be split between the Company and The Vehicle Owner.",
      videoUrl: people,
    },
    {
      id: 7,
      title: "Insurance Responsibility.",
      description:
        "The Vehicle Owner is responsible for obtaining and sustaining comprehensive vehicle insurance at their own expense throughout this Agreement. This coverage will meet jurisdictional requirements and cover for potential damages or loss arising from the vehicle's use in ride-hailing services and other car use cases. However, The Company, as part of its management service, will manage all claims and interactions with the insurance provider so that the vehicle owner can have peace of mind when it comes to the insurance claim process.",
      videoUrl: people,
    },
    {
      id: 8,
      title: "Post-Rental Paint Job.",
      description:
        "Upon the expiration of the rental period under this Agreement, the Company will provide a free paint job for the vehicle to restore its original color and appearance, at the Company's expense. The quality and type of paint used will match or exceed the original paint on the vehicle at the time of entering this Agreement.",
      videoUrl: people,
    },
    {
      id: 9,
      title: "Mobile Application Access.",
      description:
        "Vehicle Owners will be granted access to a proprietary mobile application, facilitating: 1) succinct overview of their gig car business; 2) Real-time access to earnings via an e-wallet; 3) Detailed insight into all financial transactions; 4) Profit and loss overview; 5) Access to regular vehicle inspection reports, and other reports. This platform ensures transparency, real-time financial accessibility, and simplified accounting for the Vehicle Owners.",
      videoUrl: people,
    },
    {
      id: 10,
      title: "Modification of Agreement.",
      description:
        "Any amendments or modifications to this Agreement must be mutually agreed upon by both Parties and will be acknowledged via the Company's dedicated mobile application. Unilateral changes to any terms of this Agreement by any Party are not permitted. Agreed-upon changes will supersede previous terms and become a part of this Agreement.",
      videoUrl: people,
    },
  ],
  basic: [
    {
      id: 1,
      title: "Monthly Rental Income Guarantee.",
      description:
        "To ensure financial security for car owners, The Company commits to guarantee monthly rental income regardless whether drivers remit the agreed-upon rental fees or not.",
      videoUrl: people,
    },
    {
      id: 2,
      title: "Routine Maintenance Obligation.",
      description:
        "The Vehicle Owner will cover the cost of regular, routine maintenance for the vehicle. This includes, but is not limited to, scheduled oil changes, tire rotations, brake checks and replacements, engine tune-ups, battery checks, fluid level checks (including brake fluid, transmission fluid, power steering fluid, and coolant), air filter changes, fuel filter changes, spark plug replacements, belt and hoses check, wheel alignment, and any other maintenance tasks recommended by the vehicle manufacturer in the owner's manual or as per industry standards. Nonetheless, the Company, as part of its management duty, will arrange for the vehicle's garage visits and oversee the maintenance to ease this responsibility for the Vehicle Owner.",
      videoUrl: people,
      additions:
        "In the event the Vehicle Owner fails to cover these costs, to the extent that the vehicle is no longer roadworthy, the Company reserves the right to suspend its operation. In such a case, the rental income will only be remitted for the days the vehicle was operational.",
    },
    {
      id: 3,
      title: "Repair Cost Liability and Preventative Measures",
      description:
        "The Vehicle Owner shall be responsible for covering the costs of all repairs required as a result of regular ride-hailing operation or damages caused by the assigned driver during the term of the Agreement. This includes, but is not limited to, mechanical repairs due to engine failure, transmission issues, electrical system faults, brake system repairs, and repairs as a result of damages caused by vandalism.",
      additions:
        "To help mitigate these costs, the Company, leveraging its robust management capabilities and technologies, will proactively engage in regular inspections, driver monitoring through telematics, and the enactment of preventive maintenance routines. This is help extend the life of the vehicle, reduce overall repair costs, and thereby enhance the profitability for the Vehicle Owner.",
      videoUrl: people,
    },
    {
      id: 4,
      title: "Wear and Tear Coverage.",
      description:
        "The Vehicle Owner agrees to cover the costs of repairs and replacements necessary due to normal wear and tear from the operation of the vehicle for ride-hailing services and other uses.",
      videoUrl: people,
      items: [
        "Replacement of worn - out tires.",
        "Replacement of worn brake pads and discs.",
        "Replacement or repair of worn suspension components, such as shocks and struts.",
        "Repair or replacement of worn-out or damaged interior components, such as seat covers, mats, and other surfaces frequently used by passengers.",
        "Replacement of worn or damaged wiper blades.",
        "Replacement of burnt-out bulbs in headlights, taillights, or interior lighting.",
        "Replacement of malfunctional batteries.",
        "Repair or replacement of power window motors.",
      ],
      additions:
        "In the event the Vehicle Owner fails to cover these costs, to the extent that the vehicle is no longer roadworthy, the Company reserves the right to suspend its operation. In such a case, the rental income will only be remitted for the days the vehicle was operational.",
    },
    {
      id: 5,
      title: "Rental Plan Period and Termination.",
      description:
        "The Vehicle Owner can rent out the vehicle to the Company for a period of either six (6) months or one (1) year, and after expiration of the rental period, the agreement can be renewed upon mutual consent. Despite the chosen rental period, either party may terminate this Agreement prematurely by providing a seven (7) days' notice via the Company's dedicated mobile application. And all outstanding payments and obligations shall be settled promptly.",
      videoUrl: people,
    },
    {
      id: 6,
      title: "Vehicle Management, Branding, and Use.",
      description:
        "Under this Agreement, the Company retains exclusive management rights of the vehicle, primarily designating its use for ride-hailing platforms. Nonetheless, the vehicle may occasionally be used for other purposes as deemed necessary by the Company. The Company is permitted to brand the vehicle for promotional or commercial purposes. At the termination of this Agreement, all branding will be removed by the Company, and the vehicle will be restored to its original color and condition, at the Company's expense. However, if a vehicle owner initiates agreement termination before expiration of the rental period, the cost of branding removal will equally be split between the Company and The Vehicle Owner.",
      videoUrl: people,
    },
    {
      id: 7,
      title: "Insurance Responsibility.",
      description:
        "The Vehicle Owner is responsible for obtaining and sustaining comprehensive vehicle insurance at their own expense throughout this Agreement. This coverage will meet jurisdictional requirements and cover for potential damages or loss arising from the vehicle's use in ride-hailing services and other car use cases. However, The Company, as part of its management service, will manage all claims and interactions with the insurance provider so that the vehicle owner can have peace of mind when it comes to the insurance claim process.",
      videoUrl: people,
    },
    {
      id: 8,
      title: "Post-Rental Paint Job.",
      description:
        "Upon the expiration of the rental period under this Agreement, the Company will provide a free paint job for the vehicle to restore its original color and appearance, at the Company's expense. The quality and type of paint used will match or exceed the original paint on the vehicle at the time of entering this Agreement.",
      videoUrl: people,
    },
    {
      id: 9,
      title: "Mobile Application Access.",
      description:
        "Vehicle Owners will be granted access to a proprietary mobile application, facilitating: 1) succinct overview of their gig car business; 2) Real-time access to earnings via an e-wallet; 3) Detailed insight into all financial transactions; 4) Profit and loss overview; 5) Access to regular vehicle inspection reports, and other reports. This platform ensures transparency, real-time financial accessibility, and simplified accounting for the Vehicle Owners.",
      videoUrl: people,
    },
    {
      id: 10,
      title: "Modification of Agreement.",
      description:
        "Any amendments or modifications to this Agreement must be mutually agreed upon by both Parties and will be acknowledged via the Company's dedicated mobile application. Unilateral changes to any terms of this Agreement by any Party are not permitted. Agreed-upon changes will supersede previous terms and become a part of this Agreement.",
      videoUrl: people,
    },
  ],
};
