import React from "react";
import styled from "styled-components";

const EmptyMakes = () => {
  return (
    <Wrapper className="w-100 bg-white min-height-50 p-4">
      <div className="bg-dark-white text-dark-gray p-2 text-center font-15 table-row border-3 mb-2">
        No Vehicle Makes Added. Tap the button “Add Make” to make your list
      </div>
    </Wrapper>
  );
};

export default EmptyMakes;

const Wrapper = styled.div`
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.23);
  border-radius: 3px;
`;
