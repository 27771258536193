import React, { useState, useEffect } from "react";
import MessageTemplate from "./MessageTemplate";
import CustomMessageInput from "./CustomMessageInput";
import styled from "styled-components";
import CompanyInfo from "./CompanyInfo";
import { createBulkApplication } from "../../redux/actions/application_actions";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";

// Your Request Has Been Sent Successfully
// Thanks for reaching out to Fleetsimplify. The right person will get back to you to schedule a meeting and discuss next steps.

const BulkOnboarding = () => {
  const [values, setValues] = useState({
    applicationType: "SINGLE",
    fullname: "",
    email: "",
    mobileNumber: "",
    mobilityPlayerType: "AUTOMAKER",
    companyName: "",
    position: "",
    customMessage: "",
  });
  const [companyInfo, setCompanyInfo] = useState(true);
  const [msgTempDetails, setMsgTempDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleChange = (e) => {
    if (e.target.name === "mobilityPlayerType") {
      if (
        e.target.value === "INDIVIDUAL_CAR_OWNER" ||
        e.target.value === "I_WANT_TO_PURCHASE_IN_BULK"
      ) {
        setValues({
          ...values,
          position: "",
          companyName: "",
          [e.target.name]: e.target.value,
        });
      } else {
        if (e.target.name === "mobileNumber") {
          setValues({
            ...values,
            [e.target.name]: e.target.value.replace(/[|&;$%@"<>()+,\D]/g, ""),
          });
        } else {
          setValues({ ...values, [e.target.name]: e.target.value });
        }
      }
    } else {
      if (e.target.name === "mobileNumber") {
        setValues({
          ...values,
          [e.target.name]: e.target.value.replace(/[|&;$%@"<>()+,\D]/g, ""),
        });
      } else {
        setValues({ ...values, [e.target.name]: e.target.value });
      }
    }
  };

  const useThisTemplateHandler = (msg) => {
    setValues({ ...values, customMessage: msg });
  };

  // const isSubmittable = () => {
  //   if (companyInfo) {
  //     return (
  //       values.fullname &&
  //       values.email &&
  //       values.mobileNumber &&
  //       values.customMessage &&
  //       values.mobilityPlayerType &&
  //       values.companyName &&
  //       values.position
  //     );
  //   } else {
  //     return (
  //       values.fullname &&
  //       values.email &&
  //       values.mobileNumber &&
  //       values.customMessage &&
  //       values.mobilityPlayerType
  //     );
  //   }
  // };

  const isCompanyInfo = () => {
    if (
      values.mobilityPlayerType === "Automaker" ||
      values.mobilityPlayerType === "Autodealer" ||
      values.mobilityPlayerType === "Fleet company"
    ) {
      setCompanyInfo(true);
    } else {
      setCompanyInfo(false);
    }
  };

  useEffect(() => {
    isCompanyInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.mobilityPlayerType,]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      await dispatch(createBulkApplication(values));
      setLoading(false);

      setValues({
        applicationType: "SINGLE",
        fullname: "",
        email: "",
        mobileNumber: "",
        mobilityPlayerType: "AUTOMAKER",
        companyName: "",
        position: "",
        customMessage: "",
      });
      setShowSuccessModal(true);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  return (
    <Wrapper className="driver-vehicles min-height-70 page-height padding-65 mb-5 pb-5">
      <div className="row justify-content-center">
        <div className="col-lg-10 px-4 px-lg-0">
          <div className="row px-3 px-2">
            <div className="col-xl-8 min-height-35">
              <div>
                <h1 className="font-50 font-weight-900 text-light-black">
                  Bulk-Onboarding & Partnership
                </h1>
                <p className="text-dark-gray font-17">
                  For bulk-onbaording or for business partnership, it would be
                  great if we open a discussion. Use the template below or add
                  your custom message to reach out the to Fleetsimplify top
                  executives.
                </p>
              </div>
              <div className="space" style={{ height: 90 }} />
              <form>
                <div className="row">
                  <div className="col-md-8 col-xl-7">
                    <div className="mb-5">
                      <div className="form-group" id="mobility">
                        <label
                          for="formGroupExampleInput"
                          className="font-16 mb-3 text-dark-gray font-weight-500"
                        >
                          {" "}
                          Type of Mobility Player
                        </label>
                        <div className="input-container">
                          <select
                            className="form-control font-15 mobility-select text-dark-gray"
                            id="mobilityPlayerType"
                            name="mobilityPlayerType"
                            value={values.mobilityPlayerType}
                            onChange={handleChange}
                            required
                          >
                            <option value="Automaker">Automaker</option>
                            <option value="Autodealer">Auto dealer</option>
                            <option value="Fleet company">Fleet company</option>
                            <option value="Individual car owner">
                              Individual car owner
                            </option>
                            <option value="I want to purchase in bulk">
                              I want to purchase in bulk
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    {companyInfo && (
                      <CompanyInfo changeHandler={handleChange} />
                    )}

                    <div className="mb-4 pb-1 form-group">
                      <label
                        for="formGroupExampleInput"
                        className="font-16 mb-2 text-dark-gray font-weight-500"
                      >
                        {" "}
                        Contact Details
                      </label>
                      <input
                        type="text"
                        className="form-control font-15 mobility-select text-dark-gray mb-5"
                        id="fullname"
                        name="fullname"
                        placeholder="Your name"
                        onChange={handleChange}
                        required
                        value={values.fullname}
                      />

                      <input
                        type="email"
                        className="form-control font-15 mobility-select text-dark-gray mb-5"
                        id="email"
                        name="email"
                        placeholder="Email address"
                        onChange={handleChange}
                        required
                        value={values.email}
                      />

                      <input
                        type="text"
                        className="form-control font-15 mobility-select text-dark-gray mb-5"
                        id="mobileNumber"
                        name="mobileNumber"
                        placeholder="Mobile number"
                        onChange={handleChange}
                        required
                        value={values.mobileNumber}
                      />
                    </div>

                    <MessageTemplate
                      setMsgTempDetails={setMsgTempDetails}
                      changeHandler={useThisTemplateHandler}
                    />
                    <div className="space" style={{ height: 90 }} />
                    <CustomMessageInput
                      msgTempDetails={msgTempDetails}
                      changeHandler={handleChange}
                      message={values.customMessage}
                    />

                    <button
                      className="btn viewBtn"
                      // type=""
                      disabled={loading}
                      onClick={handleSubmit}
                    >
                      {loading ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <h4>Your Request Has Been Sent Successfully!</h4>
          <p className="mt-4">
            Thanks for reaching out to Fleetsimplify. The right person will get
            back to you to schedule a meeting and discuss next steps.
          </p>
        </Modal.Body>
      </Modal>
    </Wrapper>
  );
};

export default BulkOnboarding;
const Wrapper = styled.div`
  .mobility-select {
    height: 40px;
    border-radius: 4px;
    background-color: #f5f5f5;
    appearance: auto;
  }
`;
