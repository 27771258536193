import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import { Icon } from "@iconify/react";
// import bxsTrashAlt from "@iconify/react/bx/bxs-trash-alt";
// import bxsEdit from "@iconify/react/bx/bxs-edit";
import { fetchFaqs, deleteFaq } from "../../../redux/actions/faq_actions";
import Loading from "../../../components/Loading";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";

const FaqsTable = (props) => {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [toDeleteId, setToDeleteId] = useState(null);
  const token = useSelector(
    (state) => state.auth_reducers.auth_credentials.token
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      let data = await dispatch(fetchFaqs());
      setFaqs((faqs) => [...data]);
      setLoading(false);
    };
    getData();
  }, [dispatch]);

  const handleDeleteFaqModal = (e, id) => {
    setShowDeleteModal(!showDeleteModal);
    setToDeleteId(id);
  };

  const handleDeleteFaq = async () => {
    let tempFaqs = faqs.filter((faq) => faq._id !== toDeleteId);
    setFaqs(tempFaqs);
    setShowDeleteModal(false);
    await dispatch(deleteFaq(token, toDeleteId));
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <TableWrapper className="p-4">
      {faqs && faqs.length > 0 ? (
        <table className="table table-bordered table-striped table-hover faqs-table">
          <tbody>
            {faqs &&
              faqs.map((faq, idx) => {
                return (
                  <tr key={faq.id}>
                    <th>{idx + 1}</th>
                    <td className="pr-0">
                      <TableContent>
                        <p className="m-0">{faq.question}</p>
                        <div>
                          <Link
                            to={{
                              pathname: `/admin/faqs/edit/${faq._id}`,
                              // state: {
                              //   id: faq._id,
                              // },
                            }}
                          >
                            Edit
                            {/* <Icon icon={bxsEdit} className="icon edit-icon" /> */}
                          </Link>
                          <span
                            onClick={(e) => handleDeleteFaqModal(e, faq._id)}
                          >
                            Delete
                            {/* <Icon
                              icon={bxsTrashAlt}
                              className="icon delete-icon"
                            /> */}
                          </span>
                        </div>
                      </TableContent>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : (
        <p>No faqs</p>
      )}

      <Modal
        show={showDeleteModal}
        onHide={handleDeleteFaqModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h3>Delete this FAQ?</h3>
        </Modal.Header>
        <Modal.Body>
          <div>
            Heads Up! You are about to delete an faq. Deleting this faq means
            that it will no longer appear among faqs. Are you sure you want to
            delete?
          </div>

          <div className="d-flex align-items-center justify-content-md-end justify-content-center p-3 flex-wrap">
            <button
              onClick={handleDeleteFaqModal}
              className="btn btn-blue my-2 mx-2"
            >
              Cancel
            </button>
            <button
              onClick={handleDeleteFaq}
              className="btn btn-danger my-2 w-sm-100 mx-2"
            >
              Yes, Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </TableWrapper>
  );
};

export default FaqsTable;

const TableWrapper = styled.div`
  background-color: #fff;
  border: 1px solid #d4d4d4;
`;

const TableContent = styled.div`
  display: flex;
  p {
    flex: 16;
  }

  div {
    flex: 2;
    display: flex;
    justify-content: space-evenly;

    span {
      cursor: pointer;
    }
  }
`;
