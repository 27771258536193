export const FETCH_INQUIRIES_START = "FETCH_INQUIRIES_START";
export const FETCH_INQUIRIES_SUCCESS = "FETCH_INQUIRIES_SUCCESS";
export const FETCH_INQUIRIES_ERROR = "FETCH_INQUIRIES_ERROR";
export const SET_INQUIRIES = "SET_INQUIRIES";
export const SET_ACTIVE_INQUIRY = "SET_ACTIVE_INQUIRY";
export const SET_INQUIRY_READ = "SET_INQUIRY_READ";
export const CHECK_INQUIRY = "CHECK_INQUIRY";
export const UNCHECK_INQUIRY = "UNCHECK_INQUIRY";
export const DELETE_INQUIRY = "DELETE_INQUIRY";
export const DELETE_INQUIRIES = "DELETE_INQUIRIES";
export const DELETE_START = "DELETE_START";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const DELETE_ERROR = "DELETE_ERROR";
export const APPLY_FILTERS = "APPLY_FILTERS";
export const REMOVE_FILTERS = "REMOVE_FILTERS";
