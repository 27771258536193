import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import ProgressLine from './ProgressLine';
import { fleetAgreements } from '../../data';
// import ReactPlayer from 'react-player';
import { FaShare } from 'react-icons/fa';

export default function AgreementVideos({ activeIndex, duration, agreementData }) {
  const [activeAgreement, setActiveAgreement] =useState(agreementData[0]);

  useEffect(() => {
    // select the active agreement
    setActiveAgreement(fleetAgreements.luxury[activeIndex]);
  }, [activeIndex]);

  return (
    <Wrapper className='w-100'>
      <h3 className="text-dark-gray font-17 mb-3 mt-3">Watch a concise 3 minute video that provides a layman's explanation of all clauses.</h3>
      <div className="d-flex gap-1 gap-sm-3 justify-content-center">
        <div className="w-100 agreement-wrapper position-relative">
          <div className="progress-container d-flex gap-1">
            {Array.from({ length: Number(agreementData?.length) }).map((_, index) => (
              <ProgressLine
                key={index}
                duration={duration}
                isActive={index === activeIndex}
              />
            ))}
          </div>
          {/* <ReactPlayer
              url={activeAgreement?.videoUrl}
              playing={false}
              controls={true}
              muted={false}
              pip={true}
              stopOnUnmount={false}
              width="100%"
              height="100%"
            /> */}
          <img src={activeAgreement?.videoUrl} alt="people" className='w-100 h-100 img-fluid' />
          <p className="text-white text-center font-20 agreement_title fw-bold w-100">{activeAgreement?.title}</p>
        </div>
        <div className="text-center text-dark-gray">
          <FaShare className="icon-50 bg-dark-white p-2 rounded-circle" />
          <div className="font-weight-bold my-1">Share</div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .agreement-wrapper {
    height: 650px;
    background: var(--white);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 0;
    border: 1px solid #914A9C;
    position: relative;
    overflow: hidden;

    .agreement_title {
      position: absolute;
      bottom: 0;
      transform: translate(-50%, -50%);
      left: 50%;
      text-shadow: 2px 2px #000000;
    }
  }
  .progress-container {
    width: 100%;
    position: absolute;
    top: 0 !important;
    padding: 1rem 0.5rem 1.5rem 0.5rem;
    background: linear-gradient(
      180deg,
      #6e6e6e 0%,
      rgba(217, 217, 217, 0) 100%
    );

    .progress {
      width: 100%;
      height: 6px;
      background-color: #ededed;
      border-radius: 15px;
    }

    .progress-bar {
      height: 100%;
      border-radius: 15px;
      background-color: #4caf50;
      transition: width 1s linear; /* Smooth transition effect */
    }
  }
`;