import axios from "axios";
import { getAuthConfig } from "../Util/Util";

// const BASE_URL = "http://localhost:3002/api";
// const BASE_URL = "https://api.fleetsimplify.com/api/";
const BASE_URL = "https://fleetsimplify-be-7dn2ie6d5q-ew.a.run.app/api/";

axios.defaults.baseURL = BASE_URL;

export const PostRoute = () => ({
  Post: async (url, data, config) => await axios.post(url, data, config),
  Get: async (url, data, config) => await axios.get(url, data, config),
  Delete: async (url, config) => await axios.delete(url, config),
  Patch: async (url, data, config) => await axios.patch(url, data, config),
  Put: async (url, data, config) => await axios.put(url, data, config),
});

export const getUserInfo = async (token) => {
  try {
    let config = await getAuthConfig(token);

    let response = await PostRoute().Get("admin", config);

    return response.data.response_data;
  } catch (error) {
    console.error("error in fetching user info", error);
  }
};
