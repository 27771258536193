export const CREATE_VEHICLE_MAKE_REQUEST = "CREATE_VEHICLE_MAKE_REQUEST";
export const CREATE_VEHICLE_MAKE_SUCCESS = "CREATE_VEHICLE_MAKE_SUCCESS";
export const CREATE_VEHICLE_MAKE_ERROR = "CREATE_VEHICLE_MAKE_ERROR";

export const GET_VEHICLE_MAKES_REQUEST = "GET_VEHICLE_MAKES_REQUEST";
export const GET_VEHICLE_MAKES_SUCCESS = "GET_VEHICLE_MAKES_SUCCESS";
export const GET_VEHICLE_MAKES_ERROR = "GET_VEHICLE_MAKES_ERROR";

export const GET_VEHICLE_MAKE_REQUEST = "GET_VEHICLE_MAKE_REQUEST";
export const GET_VEHICLE_MAKE_SUCCESS = "GET_VEHICLE_MAKE_SUCCESS";
export const GET_VEHICLE_MAKE_ERROR = "GET_VEHICLE_MAKE_ERROR";

export const EDIT_VEHICLE_MAKE_REQUEST = "EDIT_VEHICLE_MAKE_REQUEST";
export const EDIT_VEHICLE_MAKE_SUCCESS = "EDIT_VEHICLE_MAKE_SUCCESS";
export const EDIT_VEHICLE_MAKE_ERROR = "EDIT_VEHICLE_MAKE_ERROR";

export const DELETE_VEHICLE_MAKE_REQUEST = "DELETE_VEHICLE_MAKE_REQUEST";
export const DELETE_VEHICLE_MAKE_SUCCESS = "DELETE_VEHICLE_MAKE_SUCCESS";
export const DELETE_VEHICLE_MAKE_ERROR = "DELETE_VEHICLE_MAKE_ERROR";

export const GET_ALL_VEHICLES_REQUEST = "GET_ALL_VEHICLES_REQUEST";
export const GET_ALL_VEHICLES_SUCCESS = "GET_ALL_VEHICLES_SUCCESS";
export const GET_ALL_VEHICLES_ERROR = "GET_ALL_VEHICLES_ERROR";

export const GET_VEHICLE_REQUEST = "GET_VEHICLE_REQUEST";
export const GET_VEHICLE_SUCCESS = "GET_VEHICLE_SUCCESS";
export const GET_VEHICLE_ERROR = "GET_VEHICLE_ERROR";

export const DELETE_VEHICLE_REQUEST = "DELETE_VEHICLE_REQUEST";
export const DELETE_VEHICLE_SUCCESS = "DELETE_VEHICLE_SUCCESS";
export const DELETE_VEHICLE_ERROR = "DELETE_VEHICLE_ERROR";
