import { Modal } from 'react-bootstrap';
import { RiCloseCircleFill } from 'react-icons/ri';
import styled from 'styled-components';

const AnsQuestionWarningModal = ({
  show,
  onHide,
  emptyQuesNo,
}) => {

  return (
    <ModalWrapper show={show} size="lg">
      <div className="px-1 pt-1 text-right">
        <RiCloseCircleFill
          onClick={() => onHide()}
          className="icon-30 text-medium-dark"
        />
      </div>
      <div className="pt-2 pb-4 px-4 px-md-5">

        <h5 className="my-3 text-center text-maroon font-weight-bold">
          {`Please answer Question number ${emptyQuesNo} before proceeding to the 3rd and last step.`}
        </h5>
        <p className="text-medium-gray font-17">For efficiency and for our zeal to provide stellar services, we only accept a limited number of cars per month. Make sure you provide your contact details in the next step and tap <span className="font-weight-bold text-dark-gray">“Prioritize me”</span> for us to prioritize you on our waitlist.But first answer the questions on this page</p>
      </div>
    </ModalWrapper>
  );
};

export default AnsQuestionWarningModal;
const ModalWrapper = styled(Modal)`
  .modal-content {
    max-width: 700px;
    margin: auto;
    border: none;
    border-radius: 9px;
    overflow: auto;
  }
`;
