import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../dist/images/logo.png";
import { logout } from "../../redux/actions/auth_actions";
import { getAllInquiries } from "../../redux/actions/inquiry_actions";

const AdminHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector(
    (state) => state.auth_reducers.auth_credentials.token
  );

  useEffect(() => {
    if (token) {
      dispatch(getAllInquiries(token));
    }
  }, [dispatch, token]);

  const inquiries = useSelector((state) => state.inquiry_reducers.inquiries);

  const handleLogout = (e) => {
    dispatch(logout());
    let route = "/admin/login";
    navigate(route);
  };

  return (
    <div className="admin-header">
      <div className="admin-header-left d-flex align-items-center">
        <div className="admin-header-logo d-flex align-items-center mr-3">
          <div
            className="logo d-flex align-items-center cursor-pointer"
            onClick={() => navigate("/")}
          >
            <img
              src={logo}
              alt="Fleetsimplify logo"
              className="img-fluid cursor-pointer"
            />
            <span className="font-30 m-0 p-0  logo-text">Fleetsimplify</span>
          </div>
        </div>
      </div>
      <div className="admin-header-right d-flex align-items-center">
        <div className="header-icons d-flex align-items-center">
          <Link to="/admin/inquiries" className="icon-container">
            <span className="fa fa-envelope"></span>
            {inquiries && inquiries.filter((inq) => !inq.read).length > 0 && (
              <span className="text-counter">
                {inquiries && inquiries.filter((inq) => !inq.read).length}
              </span>
            )}
          </Link>
        </div>
        <div className="avatar d-flex align-items-center">
          <img src={logo} alt="avatar" className="avatar-img" />
        </div>
        <div className="sidebar-signout d-flex align-items-center">
          <p
            className="sidebar-item d-flex align-items-center m-0 p-0 cursor-pointer"
            onClick={handleLogout}
          >
            <span className="fa fa-sign-out"></span>&nbsp;
            <span>Logout</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
