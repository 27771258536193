import React, { useEffect } from 'react'
import styled from 'styled-components'
import AgreementVideos from './AgreementVideos';
import { fleetAgreements } from '../../data';
import GotoRentVehicle from '../pricingAndEarning/GotoRentVehicle';

export default function Agreement({ activePlanTab, activeProgressBarIndex, setActiveProgressBarIndex }) {
  const duration = 5; // Duration for each progress bar in seconds

  useEffect(() => {
    if (activeProgressBarIndex < (fleetAgreements[activePlanTab]?.length - 1)) {
      const timeout = setTimeout(() => {
        setActiveProgressBarIndex((prevIndex) => prevIndex + 1); // Move to the next progress bar
      }, duration * 1000); // Move to the next progress bar after the duration has elapsed

      return () => clearTimeout(timeout); // Clear the timeout when the component unmounts or the activeProgressBarIndex changes
    }

    return; // Add a default return statement for when activeProgressBarIndex is not less than 10
  }, [activeProgressBarIndex, duration, activePlanTab, setActiveProgressBarIndex]);

  return (
    <Wrapper>
      <div className="section_wrapper my-4 my-md-5 px-3 py-4 py-md-5">
        <div className="d-flex flex-column flex-lg-row gap-2 gap-lg-3 gap-xl-5">
          <div className="w-100 pl-xl-4">
            <h2 className="font-weight-bold text-dark-gray font-30">
              Fleet Management Agreement
            </h2>
            <div className="font-15 text-dark-gray ">
              <p className="">The following are the terms between Fleetsimplify (The Company) and The Partner (The Car Owner) for the <span className="text-blue font-weight-bold">“{activePlanTab.charAt(0).toUpperCase() + activePlanTab.slice(1)}  Rental Plan”</span></p>
              <div>
                {fleetAgreements[activePlanTab]?.map((agreement, index) => {
                  const { id, title, description, items, additions } = agreement;
                  return (
                    <div
                      key={index}
                      onClick={() => setActiveProgressBarIndex(index)}
                      className={`${activeProgressBarIndex === index && 'active'} agreement p-2 p-md-3 font-15 cursor-pointer`}
                    >
                      <h6 className="font-weight-bold text-dark mb-1">{`${id} > ${title}`}</h6>
                      <p className="text-dark-gray m-0">{description}</p>
                      {items && (
                        <>
                          <p className="m-1">The following is regarded as wear and tear costs:</p>
                          <ul className="m-0">
                            {items.map((item, index) => (<li key={index} className="text-dark-gray">{item}</li>))}
                          </ul>
                        </>
                      )}
                      {additions && <p className="mt-2 mb-0">{additions}</p>}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="max-w-500 mx-auto">
            <AgreementVideos
              activeIndex={activeProgressBarIndex}
              setActiveIndex={setActiveProgressBarIndex}
              duration={duration}
              agreementData={fleetAgreements[activePlanTab]}
            />
          </div>
        </div>
      </div>
      <GotoRentVehicle/>
    </Wrapper>
  )
}

const Wrapper = styled.div`
 .section_wrapper {
    background: #FFFFFE;
    outline: 0.5px solid #998D8D;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    min-height: 500px;

    .active.agreement, .agreement:hover {
      background: #FFFDEF;
      outline: 1px solid #246EB9;
      border-radius: 5px;
    }
 }
`