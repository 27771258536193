import React from 'react'
import styled from 'styled-components';
import { AiFillCar, AiFillHome } from 'react-icons/ai';
import { FaWallet } from 'react-icons/fa';
import { ImProfile } from 'react-icons/im';
import { GiReceiveMoney } from 'react-icons/gi';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import { BiIdCard, BiTask } from 'react-icons/bi';
import { CgBox, CgImage } from 'react-icons/cg';

export default function ProductFeatures({ productData }) {
  const [activeFeature, setActiveFeature] = React.useState(1);
  const [activeFeatureImage, setActiveFeatureImage] = React.useState(productData.features[0].img);
  
  return (
    <Wrapper>
      <div className="my-4 my-lg-5 d-flex flex-column flex-lg-row align-items-center justify-content-center position-relative">
        <div className="feature_contents py-5">
          <div className="contents w-100 py-4 px-4 py-lg-5  d-flex flex-column gap-4">
            {productData.features.map((feature) => (
              <div
                role="button"
                key={feature.id}
                className={`${activeFeature === feature.id ? 'bg-white text-light-black' : 'bg-transparent border text-white'} d-flex align-items-center gap-3 rounded-pill p-3`}
                onClick={() => {
                  setActiveFeature(feature.id);
                  setActiveFeatureImage(feature.img);
                }}
              >
                {feature.id === 1 && (
                  <AiFillHome className={`${activeFeature === feature.id ? 'text-deep-blue' : ' text-white'}  icon-25`} />
                )}
                {feature.id === 2 && (
                  <FaWallet className={`${activeFeature === feature.id ? 'text-deep-blue' : ' text-white'}  icon-25`} />
                )}
                {feature.id === 3 && (
                  <GiReceiveMoney className={`${activeFeature === feature.id ? 'text-deep-blue' : ' text-white'}  icon-30`} />
                )}
                {feature.id === 4 && (
                  <RiMoneyDollarCircleFill className={`${activeFeature === feature.id ? 'text-deep-blue' : ' text-white'}  icon-30`} />
                )}
                {feature.id === 5 && (
                  <BiTask className={`${activeFeature === feature.id ? 'text-deep-blue' : ' text-white'}  icon-30`} />
                )}
                {feature.id === 6 && (
                  <ImProfile className={`${activeFeature === feature.id ? 'text-deep-blue' : ' text-white'}  icon-30`} />
                )}
                {feature.id === 7 && (
                  <CgBox className={`${activeFeature === feature.id ? 'text-deep-blue' : ' text-white'}  icon-30`} />
                )}
                {feature.id === 8 && (
                  <AiFillCar className={`${activeFeature === feature.id ? 'text-deep-blue' : ' text-white'}  icon-30`} />
                )}
                {feature.id === 9 && (
                  <BiIdCard className={`${activeFeature === feature.id ? 'text-deep-blue' : ' text-white'}  icon-30`} />
                )}
                {feature.id === 10 && (
                  <CgImage className={`${activeFeature === feature.id ? 'text-deep-blue' : ' text-white'}  icon-30`} />
                )}
                <div className="">
                  <h6 className="font-20 font-weight-900 ">{feature.title}</h6>
                  <p className="font-15 m-0">{feature.desc}</p>
                </div>
              </div>
            ))}
            <div className="text-center text-orange font-weight-bold font-25"><em>... And many more features...</em></div>
          </div>
        </div>
        <div className="feature_img text-center h-100 px-4 pb-4 px-lg-5 pb-lg-5">
          <div className="my-3 dots d-flex gap-2 justify-content-center align-items-center">
            {productData.features.map((feature) => (
              <div key={feature.id} className={`dot ${feature.id === activeFeature && 'dot-active'}`}></div>
            ))}
          </div>
          <img src={activeFeatureImage} alt="product" className="img-fluid" />
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  .feature_contents {
    width: 100%;
    max-width: 520px;

    @media (min-width: 992px) {
       z-index: 2;
    margin-right: -15px;
    }
   
    .contents {
      background: #246EB9;
      border-radius: 77px;
    }
  }
  .feature_img {
    z-index: 1;
    width: 100%;
    max-width: 550px;
    background: #F3F3F3;
    border: 0.3px solid #246EB9;
    border-radius: 77px;

    .dots {
      .dot {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #AE9E9E;
      }
      .dot-active {
        background: #246EB9;
      }
    }
  }
  `;