import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import React from "react";

export const PrivateRoute = (props) => {
  let token = useSelector(
    (state) => state.auth_reducers.auth_credentials.token
  );

  return token ? <Outlet /> : <Navigate to={"/admin/login"} />;
};
