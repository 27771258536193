const RentVehicleCategory = "rent_out_vehicle";
const SelectedVehicleAction = "selected_car";
const ToggledToYearly = "toggled_yearly";
const ToggledUSD = "toggled_usd";
const ViewedTerms = "viewed_terms";
const AnsweredQuestion = "answered_question";
const GoToLastStep = "landed_on_step_3";
const SubmittedDetails = "submitted_details";
const ClickedDownloadApp = "clicked_download_app";

export default RentVehicleCategory;
export {
  SelectedVehicleAction,
  ToggledToYearly,
  ToggledUSD,
  ViewedTerms,
  AnsweredQuestion,
  GoToLastStep,
  SubmittedDetails,
  ClickedDownloadApp,
};
