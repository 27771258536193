import * as t from "../../types/inquiry_types";

const initialState = {
  fetching: false,
  //   inquiry: null,
  inquiry: [],
  inquiries: [],
  //   inquiries: null,
  filteredInquiries: [],
  toDelete: [],
  deleting: false,
  filters: [],
};

const filterUtil = (inq, filters) => {
  let count = 0;
  for (let i = 0; i < filters.length; i++) {
    if (filters[i] === "read") {
      if (inq.read === true) {
        count += 1;
      }
    }
    if (filters[i] === "unread") {
      if (inq.read === false) {
        count += 1;
      }
    }
    if (filters[i] === "last-hour") {
      if (
        (new Date().getTime() - new Date(inq.time).getTime()) / 3600000 <=
        3600
      ) {
        count += 1;
      }
    }
    if (filters[i] === "12hrs") {
      if (
        (new Date().getTime() - new Date(inq.time).getTime()) / 3600000 <=
        43200
      ) {
        count += 1;
      }
    }
  }
  return count;
};

export const inquiry_reducers = (state = initialState, action) => {
  switch (action.type) {
    case t.FETCH_INQUIRIES_START:
      return { ...state, fetching: true };
    case t.FETCH_INQUIRIES_SUCCESS:
      return { ...state, fetching: false };
    case t.FETCH_INQUIRIES_ERROR:
      return { ...state, fetching: false };
    case t.SET_ACTIVE_INQUIRY:
      return { ...state, inquiry: action.data };
    case t.SET_INQUIRIES:
      return {
        ...state,
        inquiries: action.data,
        inquiry: action.data.length > 0 ? action.data[0] : null,
        toDelete: [],
      };
    case t.SET_INQUIRY_READ:
      return {
        ...state,
        inquiries: state.inquiries.map((inq) => {
          if (inq._id === action.data._id) {
            inq.read = true;
            return inq;
          }
          return inq;
        }),
      };
    case t.DELETE_INQUIRY:
      return {
        ...state,
        inquiries: state.inquiries.filter((inq) => {
          return inq.id !== action.data.id;
        }),
        inquiry: state.inquiries[0],
      };
    case t.CHECK_INQUIRY:
      return {
        ...state,
        inquiries: state.inquiries.map((inq) => {
          if (inq._id === action.data._id) {
            inq.selected = !action.data.selected;
            return inq;
          }
          return inq;
        }),
        toDelete: [...state.toDelete, action.data],
      };
    case t.UNCHECK_INQUIRY:
      return {
        ...state,
        inquiries: state.inquiries.map((inq) => {
          if (inq._id === action.data._id) {
            inq.selected = !action.data.selected;
            return inq;
          }
          return inq;
        }),
        toDelete: state.toDelete.filter((d) => {
          return d._id !== action.data._id;
        }),
      };
    case t.DELETE_INQUIRIES:
      return { ...state, inquiries: [] };
    case t.DELETE_START:
      return { ...state, deleting: true };
    case t.DELETE_SUCCESS:
      return { ...state, deleting: false };
    case t.DELETE_ERROR:
      return { ...state, deleting: false };
    case t.APPLY_FILTERS:
      return {
        ...state,
        filters: action.data,
        filteredInquiries: state.inquiries.filter((inq) => {
          return filterUtil(inq, action.data) === action.data.length;
        }),
      };
    case t.REMOVE_FILTERS:
      return {
        ...state,
        filteredInquiries: [],
        filters: [],
        // inquiries: state.inquiries.map((inq) => {
        //   for (let i = 0; i < state.filteredInquiries.length; i++) {
        //     if (state.filteredInquiries[i]._id === inq._id) {
        //       inq = state.filteredInquiries[i];
        //     }
        //   }
        //   return inq;
        // }),
      };

    default:
      return state;
  }
};
