import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { createWaitingApplication } from "../../redux/actions/application_actions";
import checkedIcon from "../../dist/icons/check_green.svg";
import { Col, Form, Row } from "react-bootstrap";
import { validateEmail, validatePhoneNumber } from "../../Util/Util";
import { TrackGoogleAnalyticsEvent } from "../../Util/google-analytics";
import RentVehicleCategory, {
  SubmittedDetails,
} from "../../Util/google-analytics/events/rentVehicle";

const UserContactInfo = () => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    applicationType: "SINGLE",
    fullname: "",
    email: "",
    mobileNumber: "",
    vehicleMake: "",
    vehicleModel: "",
    vehicleManufactureYear: "",
    priority: "",
  });
  const [successful, setSuccessful] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(null);
  const dispatch = useDispatch();

  console.log(isPhoneNumberValid);

  const applicationProgressData = useSelector(
    (state) => state.application_reducers.applicationProgress
  );

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    if (e.target.name === "mobileNumber") {
      setIsPhoneNumberValid(validatePhoneNumber(e.target.value));
    }
    if (e.target.name === "email") {
      setIsEmailValid(validateEmail(e.target.value));
    }
  };

  const submittable =
    values &&
    values.fullname &&
    values.email &&
    values.mobileNumber &&
    values.priority &&
    isPhoneNumberValid &&
    isEmailValid;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsPhoneNumberValid(validatePhoneNumber(values && values.mobileNumber));
    setIsEmailValid(validateEmail(values && values.email));

    if (submittable) {
      const payload = {
        ...values,
        ...applicationProgressData.step1,
        ...applicationProgressData.step2,
      };
      setLoading(true);
      await dispatch(createWaitingApplication(payload));
      setLoading(false);
      setValues({
        applicationType: "SINGLE",
        fullname: "",
        email: "",
        mobileNumber: "",
        vehicleMake: "",
        vehicleModel: "",
        vehicleManufactureYear: "",
        priority: "",
      });
      setSuccessful(true);
      TrackGoogleAnalyticsEvent(
        RentVehicleCategory,
        SubmittedDetails,
        window.location.pathname
      );
    } else {
      setValidated(true);
    }
  };
  return (
    <Wrapper className="max-w-900 py-sm-3">
      {successful ? (
        <div className="col-md-10 mx-auto text-center">
          <img src={checkedIcon} alt="checked" className="img-fluid" />
          <p className="text-blue font-17 mt-3">
            Contact Details submitted successfully. If you have a car, download
            the app below. Onboarding of your car is taking place on
            Fleetsimplify-created app called{" "}
            <strong>"Instadriver Partner".</strong>
          </p>
        </div>
      ) : (
        <Form noValidate validated={validated}>
          <Row className="mb-3">
            <Col md="10" className="mb-md-3 mx-auto">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Your Name"
                  name="fullname"
                  className="text-center"
                  onChange={handleChange}
                  value={values && values.fullname}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid name.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="tel"
                  className="text-center"
                  placeholder="Mobile Number"
                  name="mobileNumber"
                  onChange={handleChange}
                  value={values && values.mobileNumber}
                  required
                />
                {!isPhoneNumberValid && isPhoneNumberValid !== null && (
                  <div className="text-danger font-15">
                    Please provide a valid mobile number.
                  </div>
                )}
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="email"
                  className="text-center"
                  placeholder="Your Email"
                  name="email"
                  onChange={handleChange}
                  value={values && values.email}
                  required
                />
                {!isEmailValid && isEmailValid !== null && (
                  <div className="text-danger font-15">
                    Please provide a valid email.
                  </div>
                )}
              </Form.Group>
              <div className="mt-3 my-md-4 text-dark-gray">
                <h2 className="font-weight-bold text-dark-gray font-20 mb-3 pt-md-3">
                  Select priority option below
                </h2>
                {[
                  {
                    option: "Prioritize my car for this month intake",
                    short: "This Month",
                  },
                  {
                    option: " Prioritize my car for the next month intake",
                    short: "Next Month",
                  },
                  { option: "Do not prioritize me", short: "None" },
                ].map(({ option, short }, index) => (
                  <Form.Check
                    type="radio"
                    name="priority"
                    key={index}
                    id={`priorityOption1${index}`}
                    rt
                    className="mb-2"
                    label={option}
                    value={short}
                    onChange={handleChange}
                    required
                  />
                ))}
              </div>
              <button
                className="btn viewBtn w-100 mt-3"
                type="submit"
                disabled={loading && !submittable}
                onClick={handleSubmit}
              >
                {loading
                  ? "Loading..."
                  : "Submit Contact Details to Fleetsimplify"}
              </button>
            </Col>
          </Row>
        </Form>
      )}
    </Wrapper>
  );
};

export default UserContactInfo;

const Wrapper = styled.div`
  .viewBtn {
    &:hover {
      outline: 2px solid #435cb5;
    }
  }
`;
