import React from "react";
import { useNavigate } from "react-router-dom";
import FaqsTable from "./AdminFAQ/FaqsTable";

const AdminFaqs = () => {
  const navigate = useNavigate();

  const handleGoToAddFaqPage = () => {
    const route = `/admin/faqs/new`;

    navigate(route);
  };

  return (
    <div className="admin-faqs">
      <div className="row">
        <div className="col-lg-12 d-flex align-items-center justify-content-between">
          <h2>{`Manage CMS > FAQs`}</h2>

          <button
            className="btn btn-blue font-18"
            onClick={handleGoToAddFaqPage}
          >
            + Add Question
          </button>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-lg-12">
          <FaqsTable />
        </div>
      </div>
    </div>
  );
};

export default AdminFaqs;
