import React from "react";
import { benefitsData } from "../../data";
import BenefitsCard from "./BenefitsCard";

const Benefits = () => {
  return (
    <div id="benefits" className="row p-0 m-0 pt-lg-5 justify-content-center">
      <div className="col-lg-11 mx-auto d-flex align-items-center flex-column py-4">
        <div className="row font-weight-900 font-50 text-light-black mb-5">
          <h3 className="font-50 font-weight-900">
            Benefits of Renting Your Vehicle With Fleetsimplify
          </h3>
        </div>
      </div>
      <div className="p-0 m-0">
        {benefitsData &&
          benefitsData.map((data, idx) => (
            <BenefitsCard key={idx} data={data} id={idx} />
          ))}
      </div>
    </div>
  );
};

export default Benefits;
