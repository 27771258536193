import React from "react";
import styled from "styled-components";

export default function ButtonOutlineBlue({
  title,
  buttonText,
  short,
  onButtonClick,
  maxWidth,
  minWidth,
  image,
}) {
  return (
    <Wrapper
      type="button"
      maxWidth={maxWidth}
      minWidth={minWidth}
      isActive={title === short}
      onClick={onButtonClick}
      className="w-100 h-auto mx-auto px-3 py-2 font-weight-bold font-15 text-center d-flex flex-column align-items-center justify-content-center "
    >
      {image && <img src={image} alt={"img"} className="img-fluid mb-2" />}
      <div>{buttonText}</div>
    </Wrapper>
  );
}

const Wrapper = styled.button`
  background: ${(props) => (props.isActive ? "#246EB9" : "#fff")};
  color: ${(props) => (props.isActive ? "#fff" : "#595959")};
  max-width: ${(props) => props.maxWidth && props.maxWidth};
  min-width: ${(props) => props.minWidth && props.minWidth};
  border: 3px solid #0274b3;
  border-radius: 8px;

  &:hover {
    background: #246eb9;
    color: #fff;
  }
`;
