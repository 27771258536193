import React from "react";
import LatestArticles from "../components/Blogs/LatestArticles";
import Articles from "../components/Blogs/Articles";
import BlogWrapper from "../components/Blogs/BlogWrapper";

const Blogs = () => {
  return (
    <BlogWrapper>
      <LatestArticles />
      <Articles />
    </BlogWrapper>
  );
};

export default Blogs;
