import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/Loading";
import Uploads from "../../../components/RentVehicleSteps/Uploads";
import { getSingleApplication } from "../../../redux/actions/application_actions";

const ApplicationMedia = (props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [media, setMedia] = useState({});

  const token = useSelector(
    (state) => state.auth_reducers.auth_credentials.token
  );

  useEffect(() => {
    const getData = async () => {
      let id = props.match.params.id;
      setLoading(true);
      let response = await dispatch(getSingleApplication(id, token));
      setMedia(response && response.mediaUploaded);
      setLoading(false);
    };
    getData();
  }, [token,dispatch, props]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="container my-4">
      <h1 className="mb-3">Application Media</h1>
      <p className="font-18 mt-4">Exterior Images uploaded</p>
      <Uploads media={media && media.exterior} />
      <p className="font-18 mt-4">Interior Images uploaded</p>
      <Uploads media={media && media.interior} />
      <p className="font-18 mt-4">Images Inside Bonet uploaded</p>
      <Uploads media={media && media.insideBonet} />
      <p className="font-18 mt-4">Front Images uploaded </p>
      <Uploads media={media && media.front} />
      <p className="font-18 mt-4">Rear Images uploaded </p>
      <Uploads media={media && media.rear} />
    </div>
  );
};

export default ApplicationMedia;
