import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { dateUtil } from "../../../Util/DateUtil";

function MessageBox(props) {
  const activeInquiry = useSelector((state) => state.inquiry_reducers.inquiry);

  return (
    <Message className="col-xs-10 col-md-9 mx-auto p-4">
      <p className="p-2">{activeInquiry && activeInquiry.message}</p>

      <p className="m-0 pl-2">
        {activeInquiry && dateUtil(new Date(activeInquiry.createdAt))}
      </p>
    </Message>
  );
}

export default MessageBox;

const Message = styled.div`
  background: #ecf2fa;
  border-radius: 15px 15px 0px 15px;
`;
