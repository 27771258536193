import React from "react";
import { accolades } from "../../data";
import AccoladesCard from "./AccoladesCard";

const AccoladesBackers = () => {
  return (
    <div
      id="platforms"
      className="row pt-lg-5 justify-content-center px-3 mb-5"
    >
      <div className="col-lg-11 mx-auto d-flex align-items-center flex-column py-4">
        <div className="row text-light-black mb-5">
          <h3 className="text-center font-50 font-weight-900">
            Accolades & Backers
          </h3>
          <p className="text-center">
            Fleetsimplify continues to amass accolades and shining milestones.
            It stops at nothing in making sure that it becomes the most
            successful startup in Africa. Here are some of its accolades and
            backers.
          </p>
        </div>
        <div className="row w-100 align-items-center">
          {accolades &&
            accolades.map((data) => (
              <AccoladesCard data={data} key={data.id} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default AccoladesBackers;
