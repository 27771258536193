import React, { useState } from "react";
import { Card, Col } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CgCalendarDates } from "react-icons/cg";
import ReactPlayer from "react-player";
import MediaUploadModal from "./MediaUploadModal";
import { deleteMedia } from "../../services/media";
import { useSelector } from "react-redux";

export default function MediaCard({ media, updateMedia }) {
  const [isEdit, setIsEdit] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);

  const auth_credentials = useSelector(
    (state) => state.auth_reducers.auth_credentials
  );

  return (
    <Col className="media">
      <Card>
        <div className="position-relative">
          {media.isYoutubeVideo ? (
            <ReactPlayer
              url={media?.url}
              playing={false}
              controls={true}
              muted={false}
              pip={true}
              stopOnUnmount={false}
              width="100%"
              height="100%"
            />
          ) : (
            <Card.Img
              variant="top"
              src={media.url}
              className="cursor-pointer"
            />
          )}

          {/* FIXME: SHOW THIS PLAY ICON IF THE MEDIA IS VIDEO */}
          {/* {media.isYoutubeVideo && (
          <FaPlayCircle className="icon-30 position-center position-absolute text-white" />
        )} */}
        </div>
        <Card.Body className="pr-2">
          <div className="media_contents d-flex flex-column justify-content-between">
            <Card.Title className="font-16 d-flex justify-content-between">
              <h6 className="fw-bold">{media?.title}</h6>
              <div style={{ position: "relative" }}>
                <BsThreeDotsVertical
                  className="font-20 cursor-pointer"
                  onClick={() => {
                    setShowToolTip((prevVal) => !prevVal);
                  }}
                />

                {showToolTip && (
                  <div
                    className="d-flex flex-column p-2 shadow-sm"
                    style={{
                      width: "100px",
                      position: "absolute",
                      top: "0px",
                      right: "20px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <button
                      className="mb-4"
                      style={{ border: "none", background: "inherit" }}
                      onClick={(e) => {
                        e.preventDefault();
                        setIsEdit(true);
                        setShowUploadModal(true);
                        setShowToolTip((prevVal) => !prevVal);
                      }}
                    >
                      Edit
                    </button>
                    <button
                      style={{ border: "none", background: "inherit" }}
                      onClick={async (e) => {
                        e.preventDefault();
                        await deleteMedia(media._id, auth_credentials.token);
                        updateMedia("del", undefined, media._id);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
            </Card.Title>
            <Card.Text>
              <p className="text-gray">{media?.description?.slice(0, 20)}...</p>
              <div className="font-15 text-gray">
                <CgCalendarDates className="icon-23 mb-1 mr-1" />
                {new Date(media.createdAt).toLocaleDateString()}
              </div>
            </Card.Text>
          </div>
        </Card.Body>
      </Card>

      {showUploadModal && (
        <MediaUploadModal
          show={showUploadModal}
          onHide={() => setShowUploadModal(false)}
          updateMedia={updateMedia}
          isEdit={isEdit}
          mediaData={media}
        />
      )}
    </Col>
  );
}
