import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

function CustomProgressBar() {
  const progress = useSelector((state) => state.application_reducers.progress);

  return (
    <Wrapper>
      <div className="row mt-5">
        <div
          className="col-11 col-md-7 col-lg-5 mx-auto bg-white justify-content-center"
          style={{ height: "50px" }}
        >
          <div className="row align-items-center h-100">
            <div className="col-10">
              <span className="progress">
                <Bar
                  className="progress-bar bg-success"
                  role="progressbar"
                  aria-valuenow={progress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  progress={progress}
                ></Bar>
              </span>
            </div>
            <div className="col-2">
              <small>{progress}%</small>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default CustomProgressBar;

const Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  left: 0;
  top: 0;
`;

const Bar = styled.div`
  width: ${(props) => props.progress}%;
`;
