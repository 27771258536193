import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { Col } from "react-bootstrap";
import { driversLookingForVehicles } from "../data";
import AppLinksSection from "./AppLinksSection";
import { Link } from "react-router-dom";

const DriversLookingForVehicles = () => {
  const { title, desc, features, applications } = driversLookingForVehicles;

  return (
    <Wrapper className="min-height-70 page-height pt-5">
      <Col xl={9} className='mx-auto pt-lg-4 px-4'>
        <div className="content_header">
          <h2>{title}</h2>
          <p className="px-lg-5 mx-lg-4 mt-lg-4 font-15">{desc}</p>
        </div>
        <div className="apps_section mt-lg-5 pt-3 position-relative">
          <div className="count_1 count">1</div>
          {applications.map((app) => (
            <div key={app.id} className={`app pl-4 pl-md-5 ${app.id === 1 && 'border_left'}`}>
              {app.id === 2 && (
                <div className="count_2 count">2</div>
              )}
              <h5 className="font-35 text-light-black font-weight-bold text-center text-md-start">{app.title}</h5>
              <div className="d-flex flex-column flex-md-row gap-3 gap-sm-5 text-center text-md-start">
                <img src={app.logo} alt="logo" className="mt-4 mx-auto" />
                <div className="pl-lg-5">
                  <p className="mt-lg-4 font-15">{app.desc}</p>
                  {app.id === 1 && (
                    <Link to={`/driver-app-screenshots`} className="btn bg-blue text-white font-15 px-3 py-1 mb-4 mb-lg-5 mt-lg-3">View App Screenshots</Link>
                  )}
                  <div className="">
                    <ReactPlayer
                      url={app.videoUrl}
                      playing={false}
                      controls={true}
                      muted={false}
                      pip={true}
                      stopOnUnmount={false}
                      width="100%"
                      height="400px"
                    />
                    <p className="pt-2 wrapper-content font-25">
                      {app.videoCaption}
                    </p>
                    {app.id === 1 && (
                      <div className="my-4 pb-md-4 app_links">
                        <AppLinksSection isDriver />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="max-1000">
          <h2 className="pt-lg-5">Why Drivers Love Driving For Fleetsimplify</h2>
          {features.map((feature, index) => (
            <div key={index} className="my-4 my-lg-5 pb-lg-5">
              <h5 className="font-35 text-light-black mt-3 mt-lg-4 font-weight-bold">{feature.title}</h5>
              <p className="my-lg-4 font-15">{feature.desc}</p>
              <img src={feature.img} alt="car" width="100%" />
            </div>
          ))}
        </div>
      </Col>
    </Wrapper>
  );
};

export default DriversLookingForVehicles;

const Wrapper = styled.div`
.content_header {
  max-width : 850px;
  margin: 0 auto;
}
h2 {
 font-weight: 900;
 color: #5C5858;
 
 @media (min-width: 992px) {
   font-size: 50px;
   line-height: 59px;
 }
}
.max-1000 {
  max-width: 1000px;
  margin: 0 auto;
}
.btn.btn-blue {
  height: 40px;
}
.apps_section {
  img {
    align-self: flex-start;
  }
  .border_left {
    border-left: 3px solid #246EB9;
  }
  h5 {
    margin-top: -7px;
  }
}
.count {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 22px;
  background: #246EB9;
  color: #FFFFFF;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: -19px;
}
.count_1 {
  top:9px;
}
.app_links {
  max-width: 600px;
}
`;