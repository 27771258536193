import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { auth_reducers } from "../reducers/auth_reducers";
import { faqs_reducers } from "../reducers/faqs_reducers";
import { admin_reducers } from "./admin_reducers";
import { application_reducers } from "./application_reducers";
import { contact_reducers } from "./contact_reducers";
import { inquiry_reducers } from "./inquiry_reducers";
import { vehicle_reducers } from "./vehicle_reducers";

//config for root reducers
const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: [
    "auth_reducers",
    "faqs_reducers",
    "admin_reducers",
    "application_reducers",
    "inquiry_reducers",
    "contact_reducers",
    "vehicle_reducers",
  ],
};

//config for nested reducers
const adminPersistConfig = {
  key: "auth_reducers",
  storage,
  whitelist: ["auth_credentials"],
};

const applicationPersistConfig = {
  key: "application_reducers",
  storage,
  whitelist: ["applicationProgress"],
};

const combinedReducer = combineReducers({
  auth_reducers: persistReducer(adminPersistConfig, auth_reducers),
  faqs_reducers: faqs_reducers,
  admin_reducers: admin_reducers,
  application_reducers: persistReducer(
    applicationPersistConfig,
    application_reducers
  ),
  inquiry_reducers: inquiry_reducers,
  contact_reducers: contact_reducers,
  vehicle_reducers: vehicle_reducers,
});
export const rootReducer = persistReducer(rootPersistConfig, combinedReducer);
