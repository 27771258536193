import React, { useEffect, useState } from "react";
import styled from "styled-components";
import upload from "../../dist/icons/upload.svg";
import {
  addVehicle,
  editVehicle,
  uploadFile,
} from "../../redux/actions/application_actions";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleVehicle,
  getVehicleMakes,
  getAllVehicles,
} from "../../redux/actions/vehicle_actions";
import defaultImg from "../../dist/images/carImgMissing.jpg";
import Loading from "../Loading";
import { useNavigate } from "react-router-dom";

const VehicleForm = ({ formType, vehicleId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [alreadyExists, setAlreadyExists] = useState(false);
  // const [initialEditValues, setInitialEditValues] = useState({});
  const token = useSelector(
    (state) => state.auth_reducers.auth_credentials.token
  );

  useEffect(() => {
    const fetchVehicles = async () => {
      await dispatch(getVehicleMakes());
      await dispatch(getAllVehicles());
    };

    fetchVehicles();
  }, [dispatch]);

  const vehiclesMakes = useSelector(
    (state) => state.vehicle_reducers.vehicleMakes
  );

  const allVehicles = useSelector(
    (state) => state.vehicle_reducers.allVehicles
  );

  const [values, setValues] = useState({
    vehicleMake: "",
    vehicleModel: "",
    yearOfManufacture: "",
    vehiclePhoto: "",
    isAccepted: true,
    vehicleId: "",
  });
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleCheckChange = () => {
    setValues({
      ...values,
      isAccepted: !values.isAccepted,
    });
  };

  useEffect(() => {
    const getData = async () => {
      if (formType === "edit") {
        setLoading(true);
        let response = await dispatch(getSingleVehicle(vehicleId));

        if (response) {
          const {
            vehicleMake,
            vehicleModel,
            yearOfManufacture,
            vehiclePhoto,
            isAccepted,
          } = response;
          setValues({
            vehicleMake,
            vehicleModel,
            yearOfManufacture,
            vehiclePhoto,
            isAccepted,
            vehicleId: vehicleId,
          });
          // setInitialEditValues({ vehicleMake, vehicleModel });
        }
        setLoading(false);
      }
    };
    getData();
  }, [dispatch, formType, vehicleId]);

  useEffect(() => {
    const foundVehicle = vehiclesMakes.find(
      (vehicle) =>
        values && vehicle.vehicleMake.trim() === values.vehicleMake.trim()
    );

    if (foundVehicle) {
      setModels(foundVehicle.vehicleModels);
    }
  }, [values.vehicleMake, values, vehiclesMakes]);

  useEffect(() => {
    if (formType !== "edit") {
      const exists =
        allVehicles &&
        allVehicles.some(
          (v) =>
            v.vehicleMake.trim() === values.vehicleMake.trim() &&
            v.vehicleModel.trim() === values.vehicleModel.trim()
        );
      setAlreadyExists(exists);
    }
  }, [values.vehicleMake, values.vehicleModel, formType, allVehicles]);

  const handleUpload = async (e) => {
    let file = e.target.files[0];
    let data = new FormData();
    data.append("file", file);

    let fileUrl = await dispatch(uploadFile(data));
    setValues({ ...values, vehiclePhoto: fileUrl });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    if (formType === "edit") {
      // const exists =
      //   allVehicles &&
      //   allVehicles.some(
      //     (v) =>
      //       v.vehicleMake.trim() === values.vehicleMake.trim() &&
      //       v.vehicleModel.trim() === values.vehicleModel.trim()
      //   );
      // if (
      //   exists &&
      //   !(
      //     initialEditValues &&
      //     initialEditValues.vehicleModel &&
      //     initialEditValues.vehicleModel.trim() ===
      //       (values && values.vehicleModel.trim())
      //   )
      // ) {
      //   setAlreadyExists(exists);
      //   setTimeout(() => {
      //     setAlreadyExists(false);
      //   }, 4000);
      //   return;
      // }

      await dispatch(editVehicle(token, values));
      navigate("/admin/vehicles");
    } else {
      await dispatch(addVehicle(token, values));
      navigate("/admin/vehicles");
    }
    setSubmitting(false);
  };

  const submitable =
    values &&
    values.vehicleMake &&
    values.vehicleModel &&
    values.yearOfManufacture;

  if (loading) return <Loading />;

  return (
    <Wrapper>
      <form className="row py-4">
        {alreadyExists && (
          <p className="text-danger">{`${values && values.vehicleMake} ${
            values && values.vehicleModel
          } already exists!`}</p>
        )}

        <div className="col-xl-7 pl-0">
          <div className="row mx-0 mb-5">
            <div className="col-md-4 d-flex align-items-center">
              <label
                htmlFor="vehicleMake"
                className="mr-2 font-15 text-dark-gray"
              >
                Vehicle Make
              </label>
            </div>
            <div className="col-md-8">
              <select
                className="form-control font-15 text-dark-gray"
                id="vehicleMake"
                onChange={handleChange}
                name="vehicleMake"
                value={values && values.vehicleMake}
              >
                <option value="">Select Make</option>
                {vehiclesMakes &&
                  vehiclesMakes.map((vehicle) => (
                    <option
                      value={vehicle && vehicle.vehicleMake}
                      key={vehicle._id}
                    >
                      {vehicle && vehicle.vehicleMake}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <div className="row mx-0 mb-5">
            <div className="col-md-4 d-flex align-items-center">
              <label
                htmlFor="vehicleModel"
                className="mr-2  font-15 text-dark-gray"
              >
                Vehicle Model
              </label>
            </div>
            <div className="col-md-8">
              <select
                className="form-control font-15 text-dark-gray"
                id="vehicleModel"
                disabled={!values || !values.vehicleMake}
                name="vehicleModel"
                value={values && values.vehicleModel}
                onChange={handleChange}
              >
                <option value="">Select Model</option>
                {models &&
                  models.map((model) => (
                    <option key={model._id} value={model.model}>
                      {model.model}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <div className="row mx-0 mb-5">
            <div className="col-md-4 d-flex align-items-center">
              <label
                htmlFor="yearOfManufacture"
                className="mr-2  font-15 text-dark-gray"
              >
                Min. Year of Manufacture
              </label>
            </div>
            <div className="col-md-8 mb-5">
              <select
                className="form-control font-15 text-dark-gray"
                id="yearOfManufacture"
                name="yearOfManufacture"
                value={values && values.yearOfManufacture}
                onChange={handleChange}
              >
                <option value="">Select Year of Manufacture</option>
                <option>2020</option>
                <option>2019</option>
                <option>2018</option>
                <option>2017</option>
                <option>2016</option>
                <option>2015</option>
                <option>2014</option>
              </select>
            </div>
            <div className="col-12 text-dark-gray d-flex align-items-center">
              <p className="m-0">Are you accepting this vehicle</p>

              <div
                id="RadioStyle"
                className="form-check form-check-inline  ml-4 d-flex align-items-center"
              >
                <input
                  className="form-check-input"
                  type="radio"
                  name="isAccepted"
                  id="inlineRadio6"
                  checked={
                    values && values.isAccepted && values.isAccepted === true
                      ? true
                      : false
                  }
                  value={values && values.isAccepted === true ? true : false}
                  onChange={handleCheckChange}
                />
                <label className="form-check-label ml-3" htmlFor="inlineRadio6">
                  Yes
                </label>
              </div>
              <div
                id="RadioStyle"
                className="form-check form-check-inline  ml-4 d-flex align-items-center"
              >
                <input
                  className="form-check-input"
                  type="radio"
                  name="isAccepted"
                  id="inlineRadio7"
                  checked={
                    values && values.isAccepted && values.isAccepted === true
                      ? false
                      : true
                  }
                  value={values && values.isAccepted === true ? false : true}
                  onChange={handleCheckChange}
                />
                <label className="form-check-label ml-3" htmlFor="inlineRadio7">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-5 pr-0 text-right">
          <ImageMainWrapper className="mb-3">
            <ImageWrapper
              image={
                values && values.vehiclePhoto ? values.vehiclePhoto : defaultImg
              }
            />
            <span className="bg-white cursor-pointer">
              <div className="d-flex align-items-center h-100">
                <label
                  htmlFor="actual-btn"
                  className="w-100 d-flex justify-content-center align-items-center m-0 cursor-pointer"
                >
                  <span>
                    <img src={upload} alt="upload" height="25px" />
                  </span>
                  <span className="ml-2">Upload</span>
                </label>
                <input
                  type="file"
                  id="actual-btn"
                  hidden
                  accept="image/*"
                  onChange={handleUpload}
                  className="w-100"
                />
              </div>
            </span>
          </ImageMainWrapper>
          <button
            className="btn btn-blue br-5"
            type="submit"
            disabled={!submitable || submitting}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </form>
    </Wrapper>
  );
};

export default VehicleForm;

const Wrapper = styled.div`
  select {
    height: 45px !important;
  }
`;

const ImageMainWrapper = styled.div`
  position: relative;

  > span {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 120px;
    border-radius: 5px;
    height: 35px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 250px;
  background: url(${(props) => props.image}) center/cover no-repeat;
  object-fit: cover;
  position: relative;
  border-radius: 5px;
`;
