import React from 'react'
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ProductDescriptionCard from '../components/Products/ProductDescriptionCard';
import ProductFeatures from '../components/Products/ProductFeatures';
import { driverAppData, partnerAppData } from '../data';
import AppLinksSection from './AppLinksSection';


export default function Products() {
  const location = useLocation();
  const path = location.pathname.split('/')[1];



  return (
    <Wrapper className="faqs page-height py-5">
      {(path === 'products' || path === 'partner-app-screenshots') && (
        <div className="container pt-lg-4">
          <ProductDescriptionCard productData={partnerAppData} />
          <ProductFeatures productData={partnerAppData} />
          <div className="pb-4 pb-md-5 app_links">
            <AppLinksSection />
          </div>
        </div>
      )}
      {(path === 'products' || path === 'driver-app-screenshots') && (
        <div className="container pt-lg-4">
          <ProductDescriptionCard productData={driverAppData} />
          <ProductFeatures productData={driverAppData} />
          <div className="pb-4 pb-md-5 app_links">
            <AppLinksSection isDriver />
          </div>
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.section``;