import React from 'react'
import styled from 'styled-components';

export default function ProductDescriptionCard({ productData }) {
  const {logo, title, desc} = productData;
  return (
    <Wrapper>
      <div className="app_card bg-white w-100 px-3 px-md-4 py-4 border rounded">
        <h2 className="text-center text-lg-start">{title}</h2>
        <div className="px-3 py-4 d-flex flex-column flex-lg-row align-items-center">
          <div className="text-center app_img mb-3 mb-lg-0">
            <img src={logo} alt="instadriver" />
          </div>
          <p className="m-0 font-15">{desc}</p>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
.app_card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  h2 {
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
    color: #3B3B3B;
  }
  p {
    color: #696969;
    flex-basis: 60%;
  }
  .app_img {
    flex-basis: 40%;
    img {
      width: 174px;
    }
  }
}
  `;