import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../dist/images/logo.png";

const navItems = [
  // { id: 1, name: "Blog", path: "/blogs" },
  { id: 2, name: "Rent Out Your Vehicle", path: "/rent-vehicle" },
  {
    id: 3,
    name: "Drivers Looking For Vehicles",
    path: "/drivers-looking-for-vehicles",
  },
  { id: 4, name: "Pricing & Earnings", path: "/pricing" },
  { id: 5, name: "Faqs", path: "/faqs" },
  { id: 6, name: "Products", path: "/products" },
  { id: 7, name: "Media- About Us", path: "/about-us-media" },
  { id: 8, name: "Contact Us", path: "/contact-us" },
];

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const location = useLocation();
  const urlPath = location.pathname.split("/")[1];
  if (urlPath === "admin") return null;

  return (
    <div className="header d-flex align-items-center justify-content-between px-4">
      <div className="d-flex justify-content-between align-items-center">
        <div className="logo">
          <NavLink
            to="/"
            className="d-flex align-items-center"
            onClick={() => {
              setShowMenu(false);
            }}
          >
            <img src={logo} alt="logo" className="img-fluid" />
            <span className="font-30 m-0 p-0 logo-text">Fleetsimplify</span>
          </NavLink>
        </div>
        <div className="header-navbar-menu">
          <p
            className="p-0 m-0 d-flex align-items-center cursor-pointer mr-3"
            onClick={toggleMenu}
          >
            <span className="font-20">Menu</span>&nbsp;
            <span className="fa fa-bars font-25 "></span>
          </p>
        </div>
      </div>

      <div className={`header-nav ${showMenu ? "show" : ""}`}>
        {navItems.map((item) => (
          <NavLink
            key={item.id}
            to={item.path}
            className={({ isActive }) =>
              isActive ? `header-nav-item nav-active` : `header-nav-item`
            }
            onClick={() => {
              setShowMenu(false);
            }}
          >
            {item.name}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default Header;
