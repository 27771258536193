import React, { useEffect, useState } from "react";
import { Container, Form, Col, Button, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import TextEditor from "./widgets/TextEditor";
import styled from "styled-components";
import {
  editFaq,
  fetchFaq,
  createFaq,
} from "../../../redux/actions/faq_actions";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/Loading";

const AdminFAQ = ({ faqType, faqId }) => {
  const [question, setQuestion] = useState("");
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(null);
  const [publishing, setPublishing] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleRouteBack = () => {
    let route = "/admin/faqs";

    navigate(route);
  };

  const token = useSelector(
    (state) => state.auth_reducers.auth_credentials.token
  );

  const handleChange = (e) => {
    setQuestion(e.target.value);
  };

  useEffect(() => {
    const doSomething = async () => {
      if (faqType === "edit") {
        setLoading(true);
        let data = await dispatch(fetchFaq(faqId));
        setQuestion(data && data.question);
        setContent(data && data.answer);
        setDate(new Date(data && data.updatedAt).toDateString());
        setLoading(false);
      } else {
        setDate(new Date(Date.now()).toDateString());
      }
    };

    doSomething();
  }, [dispatch, faqType, faqId]);

  const handlePublish = async (e) => {
    e.preventDefault();
    const data = {
      question: question,
      answer: content,
    };

    setPublishing(true);
    if (faqType === "edit") {
      await dispatch(editFaq(token, faqId, data));
    } else {
      await dispatch(createFaq(token, data));
    }

    setQuestion("");
    setContent("");
    setPublishing(false);

    let route = "/admin/faqs";

    navigate(route);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <TOSContainer>
      <div className="mt-4">
        <div className="d-flex">
          <Button
            variant="warning"
            className="h-25 my-auto"
            onClick={handleRouteBack}
          >
            <i className="fa fa-arrow-left mr-1"></i> Back
          </Button>
          <h3 className="font-weight-bold ml-5 my-4">
            {`Manage CMS  > FAQs > ${
              faqType && faqType === "add"
                ? "Add FAQ"
                : faqType === "edit"
                ? "Edit FAQ"
                : ""
            }`}
          </h3>
        </div>
        <div className="editor-container">
          <Row className="mb-4">
            <Col>
              <Form.Control
                type="text"
                onChange={handleChange}
                className=""
                placeholder="Question here"
                value={question}
              />
            </Col>
            <Col className="text-right">{date}</Col>
          </Row>
          <TextEditor content={content} setContent={setContent} />
        </div>
        <Button
          className="w-8r"
          variant="dark"
          onClick={handlePublish}
          disabled={
            (question && question.length <= 0) ||
            (content && content.length <= 0) ||
            (content && content.trim() === "") ||
            publishing
          }
        >
          {publishing ? "Publishing..." : "Publish"}
        </Button>
      </div>
    </TOSContainer>
  );
};

export default AdminFAQ;

const TOSContainer = styled(Container)`
  padding: 0 1rem;

  .ql-toolbar.ql-snow {
    background-color: #ffff;
  }
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    background: #ffff;
    border-top: 0px;
    min-height: 17rem;
  }

  .btn {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .w-8r {
    width: 8rem;
  }

  .editor-container {
    background-color: white;
    padding: 2rem;
    border-radius: 3px;
    border: 0.5px solid #ccc;
  }
`;
