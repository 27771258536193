import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../dist/images/logo.png";

const AdminCoverSide = () => {
  const navigate = useNavigate();

  return (
    <div className="col-md-6">
      <div className="admin-login-left bg-blue d-flex align-items-center justify-content-center flex-column">
        <p>
          <img
            src={logo}
            alt="Fleetsimplify Logo"
            className="cursor-pointer logo"
            onClick={() => navigate("/")}
          />
        </p>
        <h3 className=" text-white my-3">Welcome to Fleetsimplify</h3>

        <Link to="/" className="text-white">
          Back to Main Site <span className="fa fa-long-arrow-right"></span>{" "}
        </Link>
      </div>
    </div>
  );
};

export default AdminCoverSide;
