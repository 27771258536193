import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import VehicleForm from "../../components/Admin/VehicleForm";
import Loading from "../../components/Loading";
import { getSingleVehicle } from "../../redux/actions/vehicle_actions";

const EditVehicle = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const params = useParams();

  const vehicleId = params.vehicleId;

  useEffect(() => {
    const fetchVehicleData = async () => {
      try {
        await dispatch(getSingleVehicle(vehicleId));
        setLoading(false);
      } catch (err) {
        console.log(err.message);
        setLoading(false);
      }
    };

    fetchVehicleData();
  }, [vehicleId, dispatch]);

  const vehicle = useSelector((state) => state.vehicle_reducers.vehicle);

  const handleRouteBack = () => {
    let route = "/admin/vehicles";
    navigate(route);
  };

  if (loading) return <Loading />;

  if (!(vehicle && vehicle._id))
    return (
      <div className="min-height-60 d-flex align-items-center justify-content-center flex-column">
        <h2>Oops! No such vehicle found</h2>
        <button className="btn btn-blue font-17 br-3" onClick={handleRouteBack}>
          <span className="fa fa-long-arrow-left mr-2"></span> Go to all
          Vehicles
        </button>
      </div>
    );

  return (
    <Wrapper className="col-xl-11 mx-auto admin-faqs ">
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <Button
            variant="warning"
            className="my-auto mr-3"
            onClick={handleRouteBack}
          >
            <i className="fa fa-arrow-left mr-1"></i> Back
          </Button>
          <h2 className="font-weight-bold my-4">{`Vehicles > Edit Vehicle `}</h2>
        </div>
      </div>
      <div className="w-100 bg-white min-height-50 content-box py-4 px-5">
        <VehicleForm formType="edit" vehicleId={vehicleId} />
      </div>
    </Wrapper>
  );
};

export default EditVehicle;

const Wrapper = styled.div`
  .content-box {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.23);
    border-radius: 3px;
  }
`;
