import React from "react";
import styled from "styled-components";
import close from "../../dist/icons/close.svg";

export default function Uploads({ media, deleteFile, editable }) {
  const isVideo = (url) => {
    let sections = url.split(".");
    let extension = url.split(".")[sections.length - 1];
    return extension === "mp4" || extension === "webm" || extension === "ogg";
  };

  return (
    <div className={`uploads-container mt-2`}>
      {media && media.length > 0 ? (
        <>
          {media &&
            media.map((fileUrl, idx) =>
              isVideo(fileUrl) ? (
                <div className="videoWrapper" key={idx}>
                  <iframe
                    width="560"
                    height="349"
                    src={fileUrl}
                    frameborder="0"
                    allowfullscreen
                    title="Uploads"
                  ></iframe>
                  {editable && (
                    <span
                      className="bg-white cancel-upload"
                      onClick={() => deleteFile(fileUrl)}
                    >
                      <img src={close} alt="cancel" height="20px" />
                    </span>
                  )}
                </div>
              ) : (
                <div className="position-relative w-100" key={idx}>
                  <ImageWrapper image={fileUrl} />
                  {editable && (
                    <span
                      className="bg-white cancel-upload"
                      onClick={() => deleteFile(fileUrl)}
                    >
                      <img src={close} alt="cancel" height="20px" />
                    </span>
                  )}
                </div>
              )
            )}
        </>
      ) : (
        <p className="text-center">No uploads!</p>
      )}
    </div>
  );
}

const ImageWrapper = styled.div`
  width: 100%;
  height: 250px;
  background: url(${(props) => props.image}) center/cover no-repeat;
  object-fit: cover;
  position: relative;
  // border-radius: 5px;
`;
