import React from 'react';
import styled from 'styled-components';
import PricingEarning from '../components/pricingAndEarning';
import GotoRentVehicle from '../components/pricingAndEarning/GotoRentVehicle';
const PricingEarningPage = () => {

  return (
    <Wrapper>
      <div className="padding-65 my-3 my-md-5 col-lg-11 mx-auto">
        <div className="text-dark-gray max-w-800 text-center font-17 my-3 mb-md-5">These are the earnings you will get when you rent your car to Fleetsimplify. The less the amount you get, the more you de-risk yourself or the better the plan. For detailed explanation of each feature/perk listed, read the provided “Agreements” for each plan.</div>
        <div className="p-2 max-w-1000 section_wrapper text-center mb-3 mb-md-5">
          <PricingEarning />
        </div>
        <div className="">
          <GotoRentVehicle />
        </div>
      </div>
    </Wrapper>
  );
};

export default PricingEarningPage;

const Wrapper = styled.div`
  .section_wrapper {
    background: #FFFFFE;
    border: 0.5px solid #998D8D;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    min-height: 500px;
  }
`;