// function FlattenData(data) {
//   var result = {};
//   function recurse(cur, prop) {
//     if (Object(cur) !== cur) {
//       result[prop] = cur;
//     } else if (Array.isArray(cur)) {
//       for (var i = 0, l = cur.length; i < l; i++)
//         recurse(cur[i], prop + "[" + i + "]");
//       if (l == 0) result[prop] = [];
//     } else {
//       var isEmpty = true;
//       for (var p in cur) {
//         isEmpty = false;
//         recurse(cur[p], prop ? prop + "." + p : p);
//       }
//       if (isEmpty && prop) result[prop] = {};
//     }
//   }
//   recurse(data, "");
//   return result;
// }

function FlattenArray(data) {
  const arr = [];
  data.forEach((element) => {
    let item = {};
    for (const key in element) {
      if (typeof element[key] === "object") {
        item = { ...item, ...element[key] };
      } else {
        item[key] = element[key];
      }
    }
    arr.push(item);
  });
  return arr;
}

export default FlattenArray;
export { FlattenArray };
