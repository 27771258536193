import React from 'react'
import { Col, FormControl, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { IoMdListBox } from 'react-icons/io'
import ArticleOverview from './ArticleOverview'
import ArticleLists from './ArticleLists'

export default function Articles() {
  const articleImage = 'https://i.ibb.co/RBLLSq5/food.jpg'
  return (
    <Wrapper className='my-4 my-lg-5'>
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="font-25 text-dark-gray mb-0 flex-grow-1">
          <IoMdListBox className='icon-40 text-blue' /> Articles
        </h3>
        <FormControl as="select" className=''>
          <option value="Resent">Resent</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </FormControl>
      </div>
      <Row>
        <Col lg={6} className='mb-3'>
          <ArticleOverview
            articleImage={articleImage}
            readingTime={5}
            category="Tech"
            title="How 5G Technology is Revolutionizing Connectivity and Beyond"
          />
        </Col>
        <Col lg={6} className='mb-3'>
          <ArticleOverview
            articleImage={articleImage}
            readingTime={5}
            category="Tech"
            title="How 5G Technology is Revolutionizing Connectivity and Beyond"
          />
        </Col>
      </Row>
      <ArticleLists />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .form-control, select {
    width: auto;
  }
  .article_overview {
    min-height: 300px;
  }
`
