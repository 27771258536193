import React from 'react';
import { Card, Col } from 'react-bootstrap';
import styled from 'styled-components';

const AccoladesCard = ({ data }) => {
  return (
    <Wrapper lg={4} md={6}>
      <Card className="w-100 h-100 mx-auto mb-4 mb-md-5 bg-white">
        <figure className="mb-0 p-2 text-center d-flex justify-content-center align-items-center">
          <img alt="accolades-img" src={data.img} className="h-100 img-fluid" />
        </figure>
        <Card.Body className="p-4 text-white">
          <Card.Text className="w-100 font-15">
            {data.description}
          </Card.Text>
          <a className="px-2 py-1 mt-4 font-16 w-100 text-white d-block text-center" rel="noreferrer" target="_blank" href={data.url}>View More</a>
        </Card.Body>
      </Card>
    </Wrapper>
  );
};

export default AccoladesCard;

const Wrapper = styled(Col)`
.card {
max-width:400px;
border: .3px solid #acc8e4;
box-shadow: 0px 3.60504px 3.60504px rgba(0, 0, 0, 0.25);
border-radius: 5px;

.card-body {
  background: #246EB9;

  a {
    border-radius: 10px;
    border: 1px solid #fff;
    text-decoration: none;
  }
}

figure {
 height: 215px;
}
}

`