import React, { useEffect } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import Step1 from "./Step1";
import Step2 from "./Step2";
import { useDispatch } from "react-redux";
import StepProgressBar from "../StepProgressBar/StepProgressBar";
import { getAllVehicles } from "../../redux/actions/vehicle_actions";
import Step3 from "./Step3";

const RentVehicleSteps = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const stepProgressTitle = [
    "Select car",
    "View earning plans",
    "Onboard car or sign up",
  ];
  const [currentStepCanProgress, setCurrentStepCanProgress] = useState(false);
  // const applicationProgressData = useSelector(
  //   (state) => state.application_reducers.applicationProgress
  // );
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      await dispatch(getAllVehicles());
    };
    getData();
  }, [dispatch]);

  // const allVehicles = useSelector(
  //   (state) => state.vehicle_reducers.allVehicles
  // );

  // useEffect(() => {
  //   const findCurrentStep = () => {
  //     const { step1, step2 } = applicationProgressData;

  //     if (!carAccepted(step1, allVehicles)) {
  //       setCurrentStep(1);
  //       return;
  //     }

  //     if (!step2.agree) {
  //       setCurrentStep(2);
  //       return;
  //     }
  //     return;
  //   };
  //   // findCurrentStep();
  // }, [applicationProgressData, allVehicles]);

  return (
    <div className="padding-65 mb-5 px-3">
      <Form>
        <div className="max-w-700 d-flex align-items-center mx-auto mb-lg-5">
          <StepProgressBar
            currentStep={currentStep}
            steps={3}
            setCurrentStep={setCurrentStep}
            stepProgressTitle={stepProgressTitle}
            currentStepCanProgress={currentStepCanProgress}
          />
        </div>
        <div>
          <Step1
            currentStep={currentStep}
            setCurrentStepCanProgress={setCurrentStepCanProgress}
            setCurrentStep={setCurrentStep}
          />
          <Step2
            currentStep={currentStep}
            setCurrentStepCanProgress={setCurrentStepCanProgress}
            setCurrentStep={setCurrentStep}
          />
          <Step3 currentStep={currentStep} />
        </div>
      </Form>
    </div>
  );
};

export default RentVehicleSteps;
