import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import styled from "styled-components";
import AddMakes from "../../components/Admin/AddMakesModels/AddMakes";
import DeleteMakeModal from "../../components/Admin/AddMakesModels/DeleteMakeModal";
import EmptyMakes from "../../components/Admin/AddMakesModels/EmptyMakes";
import {
  deleteVehicleMake,
  getVehicleMakes,
} from "../../redux/actions/vehicle_actions";
import Loading from "../../components/Loading";

const AddMakesModels = () => {
  const dispatch = useDispatch();
  const [vehicles, setVehicles] = useState([]);
  const [currentMakeId, setCurrentMakeId] = useState(-1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const credentials = useSelector(
    (state) => state.auth_reducers.auth_credentials
  );

  useEffect(() => {
    const fetchVehicleMakes = async () => {
      setLoading(true);
      await dispatch(getVehicleMakes());
      setLoading(false);
    };

    fetchVehicleMakes();
  }, [dispatch]);

  const vehiclesMakes = useSelector(
    (state) => state.vehicle_reducers.vehicleMakes
  );

  useEffect(() => {
    if (vehiclesMakes) {
      setVehicles(vehiclesMakes);
    }
  }, [vehiclesMakes]);

  const handleDeleteMake = async (vehicleMakeId) => {
    try {
      if (vehicleMakeId) {
        if (vehicleMakeId.toString().length <= 6) {
          setVehicles(
            vehicles.filter((vehicle) => vehicle._id !== vehicleMakeId)
          );
        } else {
          await dispatch(deleteVehicleMake(vehicleMakeId, credentials.token));
        }
      }

      setShowDeleteModal(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleAddNewVehicle = () => {
    setVehicles([
      { _id: vehicles.length, vehicleMake: "", vehicleModels: [] },
      ...vehicles,
    ]);
  };

  return (
    <Wrapper className="col-xl-11 mx-auto admin-faqs ">
      <div className="d-flex justify-content-between">
        <h2 className="font-weight-bold my-4">Vehicle Makes & Models</h2>
        <button
          className="btn btn-blue font-17 br-3"
          type="button"
          onClick={() => handleAddNewVehicle()}
        >
          + Add Make
        </button>
      </div>

      {loading ? (
        <Loading />
      ) : vehicles && vehicles.length <= 0 ? (
        <EmptyMakes />
      ) : (
        vehicles &&
        vehicles.map((vehicle) => (
          <AddMakes
            vehicle={vehicle}
            key={vehicle._id}
            setCurrentMakeId={setCurrentMakeId}
            setShowDeleteModal={setShowDeleteModal}
          />
        ))
      )}

      {showDeleteModal && (
        <DeleteMakeModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          handleDeleteMake={handleDeleteMake}
          currentMakeId={currentMakeId}
        />
      )}
    </Wrapper>
  );
};

export default AddMakesModels;

const Wrapper = styled.div`
  .content-box {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.23);
    border-radius: 3px;
  }
`;
