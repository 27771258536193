import React from "react";
import styled from "styled-components";
import BlogWrapper from "../components/Blogs/BlogWrapper";
import ArticleDetails from "../components/Blogs/ArticleDetails";

const BlogView = () => {
  return (
    <Wrapper>
      <BlogWrapper>
        <ArticleDetails />
      </BlogWrapper>
    </Wrapper>
  );
};

export default BlogView;
const Wrapper = styled.div``