import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TobApplicationBar from "../../../components/Applications/TobApplicationBar";
import Loading from "../../../components/Loading";
import { getWaitingListApplications } from "../../../redux/actions/application_actions";

const WaitingList = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [searchWord, setSearchWord] = useState("");

  const token = useSelector(
    (state) => state.auth_reducers.auth_credentials.token
  );

  useEffect(() => {
    const getApplications = async () => {
      try {
        setLoading(true);
        await dispatch(getWaitingListApplications(token));
        setLoading(false);
      } catch (err) {
        console.log(err.message);

        setLoading(false);
      }
    };

    getApplications();
  }, [token, dispatch]);

  const waitingListApplications = useSelector(
    (state) => state.application_reducers.waitingListApplications
  );

  useEffect(() => {
    if (waitingListApplications) {
      setData(waitingListApplications);
    }
  }, [waitingListApplications]);

  useEffect(() => {
    if (waitingListApplications && searchWord && searchWord.length > 0) {
      let tempData = [...waitingListApplications];
      const word = searchWord.trim().toLowerCase().replace(/\s+/g, " ");

      if (word.length > 0) {
        // eslint-disable-next-line array-callback-return
        tempData = tempData.filter((item) => {
          if (
            item.fullname.trim().toLowerCase().includes(word) ||
            item.vehicleMake.trim().toLowerCase().includes(word) ||
            item.vehicleModel.trim().toLowerCase().includes(word)
          ) {
            return true;
          }
        });

        setData(tempData);
      }
    } else {
      setData(waitingListApplications);
    }
  }, [searchWord, waitingListApplications]);

  return (
    <div className="row applications p-3">
      <TobApplicationBar
        title="Waiting List"
        searchWord={searchWord}
        setSearchWord={setSearchWord}
      />
      {loading ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-lg-12">
            <div className="table-responsive position-relative max-height-80">
              <table className="table table-hover" id="customers">
                <thead>
                  <tr>
                    <th className="bg-dark-white border-right-gray px-3"></th>
                    <th
                      colSpan="3"
                      className="bg-dark-white text-center border-right-gray px-3"
                    >
                      Vehicle Details
                    </th>
                    <th
                      colSpan="3"
                      className="bg-dark-white text-center border-right-gray px-3"
                    >
                      Contact Details
                    </th>

                    <th className="bg-dark-white text-center px-3">
                      Date &amp; Time
                    </th>
                  </tr>
                  <tr>
                    <th
                      className="bg-dark-white min-width-35 d-flex align-items-center justify-content-center"
                      rowSpan="2"
                    >
                      #
                    </th>
                    <th className="application-top-blue ">Car Make</th>
                    <th className="application-top-blue">Car Model</th>
                    <th className="application-top-blue">Car Manufacturer</th>
                    <th className="application-top-red">Name</th>
                    <th className="application-top-red">Email</th>
                    <th className="application-top-red">Mobile No</th>
                    <th className="application-top-blue">Date &amp; Time</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0 ? (
                    data.map((application, index) => (
                      <tr key={index}>
                        <th className="applications-col-light-blue  min-width-35 text-center">
                          {index + 1}
                        </th>
                        <td className="applications-col-white">
                          {application && application.vehicleMake
                            ? application.vehicleMake
                            : "_"}
                        </td>
                        <td className="applications-col-white">
                          {application && application.vehicleModel
                            ? application.vehicleModel
                            : "_"}
                        </td>
                        <td className="applications-col-white">
                          {application && application.vehicleManufactureYear
                            ? application.vehicleManufactureYear
                            : "_"}
                        </td>
                        <td className="applications-col-light-blue">
                          {application && application.fullname}
                        </td>
                        <td className="applications-col-light-blue">
                          {application && application.email}
                        </td>
                        <td className="applications-col-light-blue">
                          {application && application.mobileNumber}
                        </td>

                        <td className="applications-col-light-blue">
                          {application &&
                            application.createdAt &&
                            `${new Date(
                              application.createdAt
                            ).toDateString()} , ${new Date(
                              application.createdAt
                            ).toLocaleTimeString()}`}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center ">
                        <div className="min-height-20 d-flex align-items-center justify-content-center">
                          No current applications
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WaitingList;
