import * as faq_types from "../../types/faq_types";

const initialState = {
  error: null,
};

export const faqs_reducers = (state = initialState, action) => {
  switch (action.type) {
    case faq_types.FAQ_FETCH_ERROR:
      return { ...state, error: action.data };
    case faq_types.FAQS_FETCH_ERROR:
      return { ...state, error: action.data };
    case faq_types.FAQ_CREATE_ERROR:
      return { ...state, error: action.data };
    case faq_types.FAQ_EDIT_ERROR:
      return { ...state, error: action.data };
    default:
      return state;
  }
};
