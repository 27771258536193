import React from "react";
import { technology } from "../../data";
import TechnologyCard from "./TechnologyCard";

const Technologies = () => {
  return (
    <div
      id="technologies"
      className="row m-0 pt-lg-5 justify-content-center px-3 bg-white"
    >
      <div className="col-lg-11 mx-auto d-flex align-items-center flex-column px-0 py-4">
        <div className="row font-weight-900 font-50 text-light-black mb-3">
          <h3 className="font-45 font-weight-900 m-0">
            Technologies Making Our Fleet Management Superior
          </h3>
        </div>
        <div className="font-weight-400 font-18">
          <p className="w-75 mx-auto text-center tech-text">
            We have a combo of technologies that simplifies our fleet management and guarantees epic returns to our customers.  Below are just a few of the technologies used by Fleetsimplify. Our technology-centralism has helped us bring value to our customers and to make their business profitable.
          </p>
        </div>
        <div className="tech-box w-100 m-0 mt-5">
          {technology &&
            technology.map((value, idx) => (
              <TechnologyCard data={value} key={idx} id={idx} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Technologies;
