import * as contact_types from "../../types/contact_types";

const initialState = {
  loading: false,
  error: "",
  success: "",
  contactMessages: [],
};

export const contact_reducers = (state = initialState, action) => {
  switch (action.type) {
    case contact_types.CREATE_CONTACT_MESSAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case contact_types.CREATE_CONTACT_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Message created successfully",
        error: "",
      };

    case contact_types.CREATE_CONTACT_MESSAGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };
    default:
      return {
        ...state,
      };
  }
};
