// import React, { useEffect, useState } from 'react'

export default function ProgressLine({ duration, isActive }) {
  // const [progress, setProgress] = useState(0); // Current progress value

  // useEffect(() => {
  //   let interval;
  //   if (isActive) {
  //     interval = setInterval(() => {
  //       setProgress((prevProgress) => prevProgress + 100 / duration); // Increase progress by a fraction based on duration
  //     }, 1000); // Adjust the interval based on your preference
  //   }

  //   return () => clearInterval(interval); // Clear the interval when the component unmounts or isActive becomes false
  // }, [duration, isActive]);

  // useEffect(() => {
  //   if (progress >= 100) {
  //     setProgress(100); // Ensure progress is exactly 100% when it reaches or exceeds 100
  //   }
  // }, [progress]);

  return (
    <div className="progress">
      <div
        className={`w-100 ${isActive && 'progress-bar'}`}
        // style={{
        //   width: `${progress}%`,
        //   transition: progress >= 100 ? 'none' : 'width 1s linear',
        // }}
      ></div>
    </div>
  );
}
