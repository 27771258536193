import React from 'react'
import { MdKeyboardArrowRight } from 'react-icons/md'
import styled from 'styled-components'

export default function CustomStyledButton({ onClick, title, classNames, hasRightIcon }) {
  return (
    <Wrapper
      onClick={onClick}
      className={`border-0 br-5 ps-md-4 ${classNames}`}
    >
      {title}
      {hasRightIcon && <MdKeyboardArrowRight className="icon-30 mr-1" />}
    </Wrapper>
  )
}

const Wrapper = styled.button`
  padding: 0.5rem;
`