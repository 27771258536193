import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/Loading";
import { fetchAdmin } from "../../../redux/actions/admin_actions";
import { createAdmin, editAdmin } from "../../../redux/actions/auth_actions";

const AdminForm = ({ formType, adminId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    email: "",
    fullname: "",
    password: "",
    role: "ADMIN",
    permission: 1,
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const auth_credentials = useSelector(
    (state) => state.auth_reducers.auth_credentials
  );

  useEffect(() => {
    const fetchEditData = async () => {
      if (formType === "edit") {
        setLoading(true);

        let editData = await dispatch(
          fetchAdmin(adminId, auth_credentials.token)
        );

        setValues({
          email: editData.email,
          fullname: editData.fullname,
          password: editData.password,
          role: "ADMIN",
          permission: editData.permission,
        });
        setLoading(false);
      }
    };
    fetchEditData();
  }, [auth_credentials, dispatch, formType, adminId]);

  const handleRouteBack = () => {
    navigate(-1);
  };

  const submittable =
    formType === "add"
      ? values &&
        values.email &&
        values.fullname &&
        values.password &&
        confirmPassword
      : values && values.email && values.fullname && values.password;

  const handleChange = (e) => {
    if (e.target.name === "confirmPassword") {
      setConfirmPassword(e.target.value);
    } else if (e.target.name === "permission") {
      setValues({ ...values, [e.target.name]: parseInt(e.target.value, 10) });
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  };

  const handleConfirmPassBlur = (e) => {
    // check if passwords match
    if ((values && values.password.trim()) !== confirmPassword.trim()) {
      setPasswordsMatch(false);
    } else {
      setPasswordsMatch(true);
    }
  };

  const handleConfirmPassFocus = (e) => {
    if (!passwordsMatch) {
      setPasswordsMatch(true);
    }
  };

  // const email_exists = useSelector((state) => state.auth_reducers.email_exists);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setSubmitting(true);
      let response;
      if (formType === "edit") {
        if (auth_credentials.permission <= values.permission) {
          response = await dispatch(editAdmin(values, auth_credentials.token));
        } else {
          throw Error("You do not have permission to edit this account");
        }
      } else {
        response = await dispatch(createAdmin(values, auth_credentials.token));
      }

      if (response.response_code !== 200) {
        if (
          response.response_code === 500 &&
          response.response_data.message === "ADMIN_EMAIL_ALREADY_EXISTS"
        ) {
          throw Error("Admin email already exists");
        } else if (response.response_code === 500) {
          throw Error(
            response.response_data.message &&
              response.response_data.message.split("_").join(" ")
          );
        }
      }

      if (formType === "add") {
        setValues({
          email: "",
          fullname: "",
          password: "",
          role: "ADMIN",
          permission: 1,
        });
      }

      setSubmitting(false);
      setSuccess(
        formType === "add"
          ? "Admin account created successfully"
          : "Admin account updated successfully"
      );

      setTimeout(() => {
        navigate("/admin/admins");
      }, 3000);
    } catch (err) {
      setError(err.message);
      setSubmitting(false);
      setTimeout(() => {
        setError("");
        setSuccess("");
      }, 7000);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="admin-form p-3">
      <div className="col-lg-12">
        <button
          className="btn btn-warning min-width-150 br-25"
          onClick={handleRouteBack}
        >
          <i className="fa fa-arrow-left mr-3"></i> Back
        </button>
      </div>

      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-8">
          <div className="form-wrapper ">
            <form className="d-flex align-items-center justify-content-center flex-column">
              <h3 className="font-weight-bold ">
                {formType === "add"
                  ? "Create New Admin"
                  : formType === "edit"
                  ? "Edit Admin Details"
                  : ""}
              </h3>
              {error && (
                <div className="error-box w-100">
                  {error &&
                    error[0].toUpperCase() + error.slice(1).toLowerCase()}
                </div>
              )}
              {success && <p className="success-box my-3">{success}</p>}
              <div className="form-group">
                <span className="fa fa-user"></span>
                <input
                  type="text"
                  name="fullname"
                  id="fullname"
                  className="form-control"
                  autoComplete="off"
                  placeholder="Full Name"
                  onChange={handleChange}
                  value={values && values.fullname}
                />
              </div>
              <div className="form-group">
                <span className="fa fa-envelope"></span>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="form-control"
                  autoComplete="off"
                  placeholder="Email address"
                  onChange={handleChange}
                  value={values.email ?? ""}
                  disabled={formType === "edit"}
                />
              </div>

              {formType !== "edit" && (
                <div className="form-group">
                  <span className="fa fa-lock"></span>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="form-control"
                    autoComplete="off"
                    placeholder="Password"
                    onChange={handleChange}
                    value={values && values.password}
                  />
                </div>
              )}

              {formType !== "edit" && (
                <div className="form-group">
                  <span className="fa fa-lock"></span>
                  <input
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    className="form-control"
                    autoComplete="off"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onBlur={handleConfirmPassBlur}
                    onFocus={handleConfirmPassFocus}
                    disabled={!(values && values.password)}
                    onChange={handleChange}
                  />
                </div>
              )}

              <div className="d-flex justify-content-start">
                {!passwordsMatch && (
                  <small className="text-danger">Passwords do not match!</small>
                )}
              </div>

              <div className="form-group">
                <select
                  name="permission"
                  id="permission"
                  className="form-control"
                  autoComplete="off"
                  placeholder="Permission"
                  value={values && values.permission}
                  onChange={handleChange}
                >
                  {formType === "edit" &&
                    auth_credentials &&
                    auth_credentials.permission <= 0 && (
                      <option value={0}>Super Admin</option>
                    )}
                  {auth_credentials && auth_credentials.permission <= 1 && (
                    <option value={1}>Admin</option>
                  )}
                  <option value={2}>Visitor</option>
                </select>
              </div>

              <button
                type="submit"
                className="btn btn-blue my-3"
                onClick={handleSubmit}
                disabled={!passwordsMatch || !submittable}
              >
                {formType === "add"
                  ? submitting
                    ? "Submitting..."
                    : "Create Account"
                  : formType === "edit"
                  ? submitting
                    ? "Submitting..."
                    : "Edit Account"
                  : ""}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminForm;
