import * as agreement_types from "../../types/agreement_types";
import { PostRoute } from "../../../api";
import { getAuthConfig } from "../../../Util/Util";

const { Get, Patch } = PostRoute();

// admin/agreement
export const fetchAgreement = () => async (dispatch) => {
  try {
    // dispatch({
    //   type: agreement_types.FETCH_AGREEMENT_REQUEST,
    // });

    const response = await Get("admin/agreement/");

    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    // dispatch({
    //   type: agreement_types.FETCH_AGREEMENT_SUCCESS,
    // });
    return response.data.response_data;
  } catch (err) {
    dispatch({
      type: agreement_types.FETCH_AGREEMENT_ERROR,
      data: err.message,
    });
  }
};

export const editAgreement = (data, token) => async (dispatch) => {
  try {
    // dispatch({
    //   type: agreement_types.FETCH_AGREEMENT_REQUEST,
    // });

    const config = await getAuthConfig(token);

    const response = await Patch("admin/agreement/", data, config);

    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    // dispatch({
    //   type: agreement_types.FETCH_AGREEMENT_SUCCESS,
    // });
    // return response.data.response_data;
  } catch (err) {
    dispatch({
      type: agreement_types.EDIT_AGREEMENT_ERROR,
      data: err.message,
    });
  }
};
