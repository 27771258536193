import React from "react";
import RentVehicleSteps from "../components/RentVehicleSteps/RentVehicleSteps";

const RentVehicle = () => {
  return (
    <div className="rent-vehicles page-height">
      <RentVehicleSteps />
    </div>
  );
};

export default RentVehicle;
