import React from "react";
import styled from "styled-components";

const BlogWrapper = ({children}) => {
  return (
    <Wrapper className="page-height pb-4 pb-md-5 bg-light-purple">
      <div className="article_wrapper px-3 py-4 py-lg-5">
        {children}
      </div>
    </Wrapper>
  );
};

export default BlogWrapper;
const Wrapper = styled.div`
  .article_wrapper {
    width: 100%;
    max-width: 1400px;
    margin: auto;

  .tech {
    color: #D61910;
    background:#FFD6D4;
  }
  .travel {
    color: #5540C3;
    background:#E0DBFF;
  }
  .finance {
    color: #D0865D;
    background:#FCF1EB;
  }
  .health {
    color: #206751;
    background:#D2FFF1;
  }
  .fitness {
    color: #2E5AAC;
    background:#EEF2FA;
  }
  }
`