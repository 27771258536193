import React from "react";
// import { Icon } from "@iconify/react";
// import filterCircleFill from "@iconify/react/bi/filter-circle-fill";
// import cloudDownloadFill from "@iconify/react/eva/cloud-download-fill";

const TobApplicationBar = ({
  title,
  searchWord,
  setSearchWord,
  handleDownloadClick,
}) => {
  return (
    <div className="applications px-3 pt-1 pb-4">
      <div className="row">
        <div className="col-lg-12 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center flex-wrap">
            <h3 className="mr-5 text-nowrap">
              Applicants {">"} {title}
            </h3>
            <div className="form-goup">
              <form>
                <div className="input-group d-flex align-items-center p-0 m-0 text-nowrap">
                  <span className="input-group-prepend d-flex align-items-center">
                    <span className="fa fa-search d-flex align-items-center justify-content-center "></span>
                  </span>
                  <input
                    type="Search"
                    className="form-control h-100 search m-0"
                    aria-label="Search field"
                    placeholder="Search applicants, company name, mobility player"
                    value={searchWord}
                    onChange={(e) => setSearchWord(e.target.value)}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="mr-5">
            {/* <Icon icon={filterCircleFill} className="icon filter-icon" />
            <Icon
              icon={cloudDownloadFill}
              onClick={handleDownloadClick}
              className="icon download-icon"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TobApplicationBar;
