import React from "react";
import { platforms } from "../../data";
import PlatformImage from "./PlatformImage";

const PlatformAgnostic = (props) => {
  return (
    <div
      id="platforms"
      className="row pt-lg-5 justify-content-center px-3 mb-5"
    >
      <div className="col-lg-11 mx-auto d-flex align-items-center flex-column py-4">
        <div className="row text-light-black mb-5">
          <h3 className="text-center font-50 font-weight-900">
            Fleetsimplify Is Platform-agnostic
          </h3>
          <p>
            Fleetsimplify is neutral. We onboard our vehicles to any
            ride-sharing or bus-sharing app available. Our overarching goal is
            profit maximization for our customers. A vehicle can be on multiple
            apps.{" "}
          </p>
        </div>
        <div className="row w-100 align-items-center">
          {platforms &&
            platforms.map((data, idx) => (
              <PlatformImage image={data} key={idx} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default PlatformAgnostic;
