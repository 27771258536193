import React from "react";
import works from "../../dist/images/works.png";
import { howItWorks } from "../../data";
import HowItWorksBox from "./HowItWorksBox";

const HowItWorks = () => {
  return (
    <div
      id="home-how-it-works"
      className="row m-0 pt-lg-5 justify-content-center px-3"
    >
      <div className="col-lg-11 mx-auto d-flex align-items-center flex-column py-4">
        <div className="row font-weight-900 font-50 text-light-black mb-5">
          <h3 className="font-50 font-weight-900">
            How It Works For Vehicle Owners
          </h3>
        </div>
        <div className="row m-0">
          <div className="col-lg-6 d-flex align-items-center justify-content-center">
            <img src={works} alt="how it works" className="img-fluid" />
          </div>
          <div className="mt-5 mt-lg-0 col-lg-6 p-0 d-flex align-items-center">
            <div className="content">
              {howItWorks &&
                howItWorks.map((data, idx) => (
                  <HowItWorksBox data={data} key={idx} id={idx} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
