import * as admin_types from "../../types/admin_types";

const initialState = {
  error: null,
};

export const admin_reducers = (state = initialState, action) => {
  switch (action.type) {
    case admin_types.FETCH_ADMIN_ERROR:
      return { ...state, error: action.data };
    case admin_types.FETCH_ADMINS_ERROR:
      return { ...state, error: action.data };

    default:
      return state;
  }
};
