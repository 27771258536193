import React, { useLayoutEffect } from "react";
import { rentalPlanType } from "../../Util/types";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "../../Util/useQuery";
import styled from "styled-components";
import { TabWrapper } from "../common/MonthlyYearlyTab";

export default function RentalPlanTab({
  activePlanTab,
  setActivePlanTab,
  setActiveProgressBarIndex,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();

  const addQuery = (key, value) => {
    let pathname = location.pathname;
    let searchParams = new URLSearchParams(location.search);
    searchParams.set(key, value);
    navigate({
      pathname: pathname,
      search: searchParams.toString(),
    });
  };

  useLayoutEffect(() => {
    const filter = query.get("filter");
    setActivePlanTab(filter ?? rentalPlanType.LUXURY);
  }, [query, setActivePlanTab]);

  return (
    <Wrapper className="">
      <TabWrapper maxWidth="500px" className="date_filter_tab_container">
        <div className="date_filter_tab d-flex p-1">
          <button
            type="button"
            className={`bg-transparent border-0 px-3 py-1 text-decoration-none flex-grow-1 text-center ${
              activePlanTab === rentalPlanType.LUXURY && "tab_active"
            }`}
            onClick={() => {
              setActivePlanTab(rentalPlanType.LUXURY);
              addQuery("filter", rentalPlanType.LUXURY);
              setActiveProgressBarIndex(0);
            }}
          >
            Luxury rental plan
          </button>
          <button
            type="button"
            className={`bg-transparent border-0 px-3 py-1 text-decoration-none flex-grow-1 text-center ${
              activePlanTab === rentalPlanType.SECURE && "tab_active"
            }`}
            onClick={() => {
              setActivePlanTab(rentalPlanType.SECURE);
              addQuery("filter", rentalPlanType.SECURE);
              setActiveProgressBarIndex(0);
            }}
          >
            Secure rental plan
          </button>
          <button
            type="button"
            className={`bg-transparent border-0 px-3 py-1 text-decoration-none flex-grow-1 text-center ${
              activePlanTab === rentalPlanType.BASIC && "tab_active"
            }`}
            onClick={() => {
              setActivePlanTab(rentalPlanType.BASIC);
              addQuery("filter", rentalPlanType.BASIC);
              setActiveProgressBarIndex(0);
            }}
          >
            Basic rental plan
          </button>
        </div>
      </TabWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div``;
