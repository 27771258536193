import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { mediaType } from "../Util/types";
import MediaTab from "../components/Blogs/MediaTab";
import ReactPlayer from "react-player";
import CustomSlider from "../components/common/CustomSlider";
import { v4 as uuidv4 } from "uuid";
import { useWindowSize } from "../Util/helpers/useWindowSize";
import { getAllMediaCategories, getMediaByCategory } from "../services/media";
import { FaPlayCircle } from "react-icons/fa";

// const mediaCategories = [
//   "All",
//   "Office",
//   "Team",
//   "Demos",
//   "Ads",
//   "Company Culture",
//   "Technology",
//   "Testimonials",
//   "Events",
//   "Behind the Scenes",
//   "Dev & Milestones",
//   "Collaborations & Partnership",
//   "Accolades",
//   "Giveaway & CSR",
//   "Media Mentions & Press Coverage",
// ];

// const videos = [
//   {
//     category: "Team",
//     url: "https://www.youtube.com/watch?v=jK4h6Vco1Cs",
//     thumbnail: "https://i3.ytimg.com/vi/jK4h6Vco1Cs/hqdefault.jpg",
//     isYoutube: true,
//   },
//   {
//     category: "Office",
//     url: "https://www.youtube.com/watch?v=TX9fwYbsaCs",
//     thumbnail: "https://i3.ytimg.com/vi/TX9fwYbsaCs/maxresdefault.jpg",
//     isYoutube: true,
//   },
//   {
//     category: "Others",
//     url: "https://cdn.instadriver.co/dist/videos/balqis_ktn_interview.mp4",
//     thumbnail:
//       "https://cdn.instadriver.co/dist/videos/balqis_ktn_interview_thumbnail.png",
//     isYoutube: false,
//   },
//   {
//     category: "Others",
//     url: "https://youtu.be/Qu903q_hyQg?si=fmRUMl-rPC2arAaL",
//     thumbnail: "https://img.youtube.com/vi/Qu903q_hyQg/sddefault.jpg",
//     isYoutube: true,
//   },
// ];

// const photos = [
//   {
//     category: "Team",
//     url: "https://cdn.instadriver.co/fleetsimplify/team_1.jpg",
//   },
//   // {
//   //   category: "Team",
//   //   url: "https://cdn.instadriver.co/fleetsimplify/team_2.jpg",
//   // },
//   // {
//   //   category: "Team",
//   //   url: "https://cdn.instadriver.co/fleetsimplify/team_3.jpg",
//   // },
//   {
//     category: "Team",
//     url: "https://cdn.instadriver.co/fleetsimplify/team_4.jpg",
//   },
//   {
//     category: "Office",
//     url: "https://cdn.instadriver.co/fleetsimplify/office_1.jpg",
//   },
//   {
//     category: "Office",
//     url: "https://cdn.instadriver.co/fleetsimplify/office_2.jpg",
//   },
// ];

const AboutUsMedia = () => {
  const [activeMedia, setActiveMedia] = useState(mediaType.VIDEOS);
  const [activeSlide, setActiveSlide] = useState(0);
  // const [mediaCategory, setMediaCategory] = useState(mediaCategories[0]);
  const [activeMediaContent, setActiveMediaContent] = useState(null);
  const [items, setItems] = useState([]);
  const [instanceId, setInstanceId] = useState(uuidv4());
  const [loading, setLoading] = useState(true);
  const [slideToShow, setSlideToShow] = useState(6);
  const { width } = useWindowSize();
  const [categoryId, setCategoryId] = useState("all");
  const [categories, setCategories] = useState([]);
  const [loadingCategoryMedia, setLoadingCategoryMedia] = useState(false);

  useEffect(() => {
    // Get categories here
    const getData = async () => {
      const response = await getAllMediaCategories(mediaType.VIDEOS, true);

      setCategories(response ?? []);
    };

    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      if (categoryId) {
        const response = await getMediaByCategory(
          activeMedia,
          categoryId === "all" ? "" : categoryId
        );

        setItems(response ?? []);
        setActiveMediaContent(response?.[0]);
        setLoading(false);
        setLoadingCategoryMedia(false);
      }
    };

    getData();
  }, [categoryId, activeMedia]);

  useEffect(() => {
    setActiveSlide(0);

    setInstanceId(uuidv4());

    if (width < 1200) {
      if (width < 1200 && width > 900) {
        setSlideToShow(4);
      } else if (width < 900 && width > 600) {
        setSlideToShow(3);
      } else if (width < 600) {
        setSlideToShow(2);
      }
    } else {
      setSlideToShow(6);
    }
  }, [
    activeMedia,
    // mediaCategory,
    width,
  ]);

  return (
    <Wrapper className="page-height pb-4 pb-md-5 bg-light-purple">
      <div className="content_wrapper px-3 py-4 py-lg-5">
        <div className="">
          <MediaTab
            activeMedia={activeMedia}
            callback={async () => {
              setItems([]);
              setActiveMediaContent(null);
              const response = await getAllMediaCategories(
                activeMedia === mediaType.PHOTOS
                  ? mediaType.VIDEOS
                  : mediaType.PHOTOS,
                true
              );

              setCategories(response ?? []);
            }}
            setActiveMedia={setActiveMedia}
          />
        </div>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div className="d-flex flex-column gap-1 gap-lg-2">
            <div className="category_tab text-gray font-17 w-100 d-flex gap-2 overflow-auto py-2 my-3">
              <button
                className={`category_button border-0 br-8 px-3 py-1 font-weight-bold text-center text-nowrap ${
                  categoryId === "all" ? "category_active" : ""
                }`}
                onClick={() => {
                  setLoadingCategoryMedia(true);
                  setCategoryId("all");
                  // setMediaCategory(undefined);
                }}
              >
                All
              </button>
              {categories
                ?.sort((a, b) => a.index - b.index)
                ?.map((item, idx) => (
                  <button
                    key={idx}
                    className={`category_button border-0 br-8 px-3 py-1 font-weight-bold text-center text-nowrap ${
                      categoryId === item._id ? "category_active" : ""
                    }`}
                    onClick={() => {
                      setLoadingCategoryMedia(true);
                      setCategoryId(item._id);
                      // setMediaCategory(item);
                    }}
                  >
                    {item.name}
                    <span className="text-gray font-15 ms-2">
                      <small>({item.count})</small>
                    </span>
                  </button>
                ))}
            </div>

            {loadingCategoryMedia ? (
              <div>Loading...</div>
            ) : (
              <div className="flex-grow-1">
                {activeMediaContent?.url ? (
                  <div className="d-flex flex-column-reverse flex-md-row gap-2 mb-3">
                    <div className="media br-15 overflow-hidden w-100">
                      {activeMedia === mediaType.VIDEOS ? (
                        // activeMediaContent?.isYoutube ? (
                        <ReactPlayer
                          url={activeMediaContent?.url}
                          playing={false}
                          controls={true}
                          muted={false}
                          pip={true}
                          stopOnUnmount={false}
                          width="100%"
                          height="450px"
                        />
                      ) : // ) : (
                      //   <video height="450px" width="100%" controls={true}>
                      //     <source
                      //       src={activeMediaContent?.media?.url}
                      //       type="video/mp4"
                      //     ></source>
                      //     Your browser does not support the video tag.
                      //   </video>
                      // )
                      activeMediaContent?.url ? (
                        <img
                          src={activeMediaContent?.url}
                          alt="media-img"
                          className="media_image"
                          style={{
                            objectFit: "cover",
                            minHeight: "450px",
                          }}
                        />
                      ) : null}
                    </div>
                    <div className="media_details p-3 p-lg-4 br-15">
                      <h5 className="fw-bold mb-md-3">
                        {activeMediaContent?.title}
                      </h5>
                      <p className="">{activeMediaContent?.description} </p>
                    </div>
                  </div>
                ) : (
                  <div className="mt-3 text-center">No media yet!</div>
                )}

                <CustomSlider
                  infinite
                  slidesToShow={slideToShow}
                  height="170px"
                  width="100%"
                  showArrows
                  instanceId={instanceId}
                  slides={
                    items
                      ? items.map(
                          (item, idx) => (
                            <div
                              key={`img-${idx}`}
                              className={`px-1 pt-1 media_slider ${
                                activeSlide === idx ? "active-slide" : ""
                              }`}
                              onClick={() => {
                                setActiveSlide(idx);
                                setActiveMediaContent(item);
                              }}
                              role="button"
                            >
                              {activeMedia === mediaType.VIDEOS ? (
                                <div
                                  style={{
                                    height: "100px",
                                    width: "100%",
                                    backgroundColor: "#000",
                                  }}
                                  className="d-flex justify-content-center align-items-center"
                                >
                                  <FaPlayCircle className="icon-30 text-white" />
                                </div>
                              ) : (
                                <img
                                  src={item?.url}
                                  alt="media-img"
                                  className="w-100 br-10 media-img"
                                />
                              )}

                              <div className="py-2">
                                <h6 className="fw-bold mb-1">
                                  {item?.title?.slice(0, 15)}
                                  ...
                                </h6>
                                <p className="font-15 text-gray">
                                  {item?.description?.slice(0, 38)}
                                  ...
                                </p>
                              </div>
                            </div>
                          )
                          // )
                        )
                      : []
                  }
                />
              </div>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default AboutUsMedia;
const Wrapper = styled.div`
  .content_wrapper {
    width: 100%;
    max-width: 1440px;
    margin: auto;
  }
  .media {
    img {
      max-width: 100%;
    }
    .media_image {
      width: 100%;
      height: 100%;
      max-height: 450px;
    }
  }
  .category_tab {
    &::-webkit-scrollbar {
      height: 0.5em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #dfe6ff;
      outline: 0.5px solid #afafaf;
    }
    .category_button {
      color: #5c5858;
      background: #eee;
    }
    .category_active {
      color: var(--blue);
      background: #dfe6ff;
      border: 0.5px solid #afafaf !important;
    }
  }
  .media_details {
    max-width: 100%;
    background: #f3f3f3;
    h5,
    p {
      color: #5c5858;
    }
    @media (min-width: 768px) {
      max-width: 350px;
    }
  }
  .media_slider {
    .media-img {
      max-height: 100px;
    }
    p {
      line-height: 1.2;
    }
  }
`;
