import React from "react";
import ContactUs from "./Pages/ContactUs";
import DriversLookingForVehicles from "./Pages/DriversLookingForVehicles";
import Home from "./Pages/Home";
import RentVehicle from "./Pages/RentVehicle";
import Faqs from "./Pages/Faqs";
import AdminLogin from "./Pages/Admin/AdminLogin";
import AppScreenShots from "./Pages/AppScreenShots";
import AdminForgotPassword from "./Pages/Admin/AdminForgotPassword";
import AdminResetPassword from "./Pages/Admin/AdminResetPassword";
import AdminDashboard from "./Pages/Admin/AdminDashboard";
import BulkOnboarding from "./Pages/BulkOnboarding";
import ListOfVehicles from "./Pages/ListOfVehicles";
// import ErrorPage from "./Pages/ErrorPage.js";
import PricingEarningPage from "./Pages/PricingEarningPage";
import Products from "./Pages/Products";
import FleetAgreement from "./Pages/FleetAgreement";
import Blogs from "./Pages/Blogs";
import BlogView from "./Pages/BlogView";
import AboutUsMedia from "./Pages/AboutUsMedia";
import { Route, Routes } from "react-router-dom";
import { PrivateRoute } from "./Util/Access";
import Vehicles from "./Pages/Admin/Vehicles";
import AddMakesModels from "./Pages/Admin/AddMakesModels";
import AddNewAdmin from "./Pages/Admin/AddNewAdmin";
import AddVehicles from "./Pages/Admin/AddVehicles";
import AdminAddFAQ from "./Pages/Admin/AdminAddFAQ";
import AdminAgreement from "./Pages/Admin/AdminAgreement";
import AdminEditFAQ from "./Pages/Admin/AdminEditFAQ";
import AdminFaqs from "./Pages/Admin/AdminFaqs";
import Admins from "./Pages/Admin/Admins";
import ApplicationMedia from "./Pages/Admin/Applications/ApplicationMedia";
import Applications from "./Pages/Admin/Applications/Applications";
import BulkApplications from "./Pages/Admin/Applications/BulkApplications";
import WaitingList from "./Pages/Admin/Applications/WaitingList";
import EditAdminDetails from "./Pages/Admin/EditAdminDetails";
import EditVehicle from "./Pages/Admin/EditVehicle";
import Inquiries from "./Pages/Admin/Inquiries";
import MainDashboard from "./Pages/Admin/MainDashboard";
import Media from "./Pages/Admin/Media";
import DeleteAccount from "./Pages/DeleteAccount";

const routes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/rent-vehicle" element={<RentVehicle />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/about-us-media" element={<AboutUsMedia />} />
      <Route path="/blog/:id" element={<BlogView />} />
      <Route path="/pricing" element={<PricingEarningPage />} />
      <Route path="/delete-account" element={<DeleteAccount />} />
      <Route
        path="/drivers-looking-for-vehicles"
        element={<DriversLookingForVehicles />}
      />
      <Route path="/bulk-onboarding" element={<BulkOnboarding />} />
      <Route path="/vehicles-list" element={<ListOfVehicles />} />
      <Route path="/faqs" element={<Faqs />} />
      {["/products", "/driver-app-screenshots", "/partner-app-screenshots"].map(
        (url, idx) => (
          <Route key={idx} path={url} element={<Products />} />
        )
      )}

      <Route path="/contact-us" element={<ContactUs />} />
      {/* ===================admin routes===================== */}

      <Route path="/admin/login" element={<AdminLogin />} />
      <Route path="/admin/forgot-password" element={<AdminForgotPassword />} />

      <Route path="/admin/reset-password" element={<AdminResetPassword />} />
      <Route path="/" element={<PrivateRoute />}>
        <Route path={"/admin/*"} element={<AdminDashboard />}>
          {["*"].map((url, idx) => (
            <Route key={idx} path={url} element={<MainDashboard />} />
          ))}
          <Route path="admins" element={<Admins />} />
          <Route path="admins/new" element={<AddNewAdmin />} />
          <Route path="admins/edit/:adminId" element={<EditAdminDetails />} />
          <Route path="media" element={<Media />} />
          <Route path="applications" element={<Applications />} />
          <Route path="inquiries" element={<Inquiries />} />
          <Route path="applications/media/:id" element={<ApplicationMedia />} />
          <Route path="bulk/applications" element={<BulkApplications />} />

          <Route path="applications/waiting-list" element={<WaitingList />} />
          {["manage-cms", "faqs"].map((url, idx) => (
            <Route path={url} key={idx} element={<AdminFaqs />} />
          ))}

          <Route path="faqs/edit/:faqId" element={<AdminEditFAQ />} />
          <Route path="faqs/new" element={<AdminAddFAQ />} />
          <Route path="vehicles" element={<Vehicles />} />
          <Route path="add-vehicle" element={<AddVehicles />} />

          <Route path={"edit-vehicle/:vehicleId"} element={<EditVehicle />} />

          <Route path="vehicle-makes" element={<AddMakesModels />} />

          <Route path="agreement" element={<AdminAgreement />} />
        </Route>
      </Route>

      {/* ==================end of admin routes============= */}
      <Route path="/agreement" element={<FleetAgreement />} />
      <Route path="/view-app" element={<AppScreenShots />} />
    </Routes>
  );
};

export default routes;
