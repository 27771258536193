import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import { Icon } from "@iconify/react";
// import bxsTrashAlt from "@iconify/react/bx/bxs-trash-alt";
// import bxsEdit from "@iconify/react/bx/bxs-edit";
import { fetchAdmins, deleteAdmin } from "../../redux/actions/admin_actions";
import Loading from "../../components/Loading";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";

const AdminsTable = (props) => {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [toDeleteId, setToDeleteId] = useState(null);
  const { token, permission } = useSelector(
    (state) => state.auth_reducers.auth_credentials
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      let data = await dispatch(fetchAdmins(token));
      setAdmins(data);
      setLoading(false);
    };
    getData();
  }, [dispatch, token]);

  const handleDeleteAdminModal = (e, id) => {
    setShowDeleteModal(!showDeleteModal);
    setToDeleteId(id);
  };

  const handleDeleteAdmin = async () => {
    try {
      await dispatch(deleteAdmin(token, toDeleteId));
      let tempAdmins = admins.filter((admin) => admin._id !== toDeleteId);
      setAdmins(tempAdmins);
      setShowDeleteModal(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  const adminPermissions = ["Super Admin", "Admin", "Visitor"];

  if (loading) {
    return <Loading />;
  }

  return (
    <TableWrapper className="p-4">
      {admins && admins.length > 0 ? (
        <table className="table table-bordered table-striped table-hover faqs-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Email Address</th>
              <th>Role</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {admins &&
              admins.map((admin, idx) => {
                return (
                  <tr key={admin._id}>
                    <th>{idx + 1}</th>
                    <td>
                      <p className="m-0">{admin.email}</p>
                    </td>
                    <td>
                      <p>
                        {admin &&
                          adminPermissions &&
                          adminPermissions[admin && admin.permission]}
                      </p>
                    </td>

                    <td className="pr-0">
                      <div className="d-flex ">
                        <Link
                          to={{
                            pathname: `/admin/admins/edit/${admin._id}`,
                          }}
                          className="mr-3"
                        >
                          Edit
                          {/* <Icon icon={bxsEdit} className="icon edit-icon" /> */}
                        </Link>
                        <span
                          onClick={(e) => {
                            permission < admin.permission
                              ? handleDeleteAdminModal(e, admin._id)
                              : setShowConfirmDeleteModal(true);
                          }}
                          className="mr-3"
                        >
                          Delete
                          {/* <Icon
                            icon={bxsTrashAlt}
                            className="icon delete-icon cursor-pointer "
                          /> */}
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : (
        <p>No Admins</p>
      )}

      <Modal
        show={showDeleteModal}
        onHide={handleDeleteAdminModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h3 className="text-center">Delete this Admin?</h3>
        </Modal.Header>
        <Modal.Body>
          <div>
            Heads Up! You are about to delete an admin. Deleting the admin means
            all contents will get deleted and admin will no longer be able to
            access the account. Are you sure you want to delete?
          </div>
          <div className="d-flex align-items-center justify-content-md-end justify-content-center p-3 flex-wrap">
            <button
              onClick={handleDeleteAdminModal}
              className="btn btn-blue my-2 mx-2"
            >
              Cancel
            </button>
            <button
              onClick={handleDeleteAdmin}
              className="btn btn-danger my-2 w-sm-100 mx-2"
            >
              Yes, Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showConfirmDeleteModal}
        onHide={() => setShowConfirmDeleteModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h3 className="text-center">Ooops!</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="p-3">
            Oops! It seems you do not have permission to delete this admin
            account
          </div>
          <div className="d-flex align-items-center justify-content-md-end justify-content-center p-3 flex-wrap">
            <button
              className="btn btn-outline-blue"
              onClick={() => setShowConfirmDeleteModal(false)}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </TableWrapper>
  );
};

export default AdminsTable;

const TableWrapper = styled.div`
  background-color: #fff;
  border: 1px solid #d4d4d4;
`;
