import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function AdminSidebarItem({
  sidebarItemPath,
  sidebarItems,
  sidebarItemOpen,
  handleSidebarItemOpen,
  hasNoDropdown,
  closeAllDropdown,
  icon,
  title,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const activePath = sidebarItems?.find((item) => item.path === path);

  return (
    <div
      className="sidebar-item sidebar-accordion cursor-pointer"
      onClick={() => sidebarItemPath && navigate(sidebarItemPath)}
    >
      <span
        className={`sidebar-link d-flex justify-content-between align-items-center ${activePath?.path === path && 'active'
          } ${sidebarItemPath === path && 'active'}`}
        onClick={() => {
          handleSidebarItemOpen && handleSidebarItemOpen();
          closeAllDropdown && closeAllDropdown();
        }}
      >
        <span>
          <span className="icon">{icon}</span>
          <span className="sidebar-item-content">{title}</span>
        </span>
        {!hasNoDropdown && <span className="accordion-arrow"></span>}
      </span>
      <div
        className={`sidebar-accordion-body ${sidebarItemOpen && 'open'}`}
      >
        {sidebarItems?.map(
          (item, id) =>
            item.name !== '' && (
              <Link
              key={id}
                to={item.path}
                className={`sidebar-link  ${path === item.path ? "active" : ""
                  } `}
              >
                <span className="sidebar-item-content">{item.name}</span>
              </Link>
            ))}
      </div>
    </div>
  )
}
