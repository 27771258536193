import React, { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  persistProgressData,
  unsetApplicationData,
} from "../../redux/actions/application_actions";
import { getAllVehicles } from "../../redux/actions/vehicle_actions";
import { carMakes, carYears } from "./data";
import defaultCarImage from "../../dist/images/car.png";
import CustomStyledButton from "../common/CustomStyledButton";
import ScrollToTop from "../../Util/ScrollToTop";
import { TrackGoogleAnalyticsEvent } from "../../Util/google-analytics";
import RentVehicleCategory, {
  SelectedVehicleAction,
} from "../../Util/google-analytics/events/rentVehicle";

export const carAccepted = (data) => {
  if (carMakes[data.vehicleMake]) {
    for (let i = 0; i < carMakes[data.vehicleMake].length; i++) {
      if (carMakes[data.vehicleMake][i].modelName === data.vehicleModel) {
        return carMakes[data.vehicleMake][i].accepted;
      }
    }
  }
};

const CCs = [650, 660, 990, 1000, 1300];

const Step1 = ({ currentStep, setCurrentStepCanProgress, setCurrentStep }) => {
  const [makes, setMakes] = useState([
    "Toyota",
    "Honda",
    "Nissan",
    "Mazda",
    "Suzuki",
    "Mitsubishi",
    "Daihatsu",
  ]);
  const [make, setMake] = useState(null);
  const [model, setModel] = useState(null);
  const [vehicleCC, setVehicleCC] = useState(null);
  const [year, setYear] = useState(null);
  const [vehicleMinYear, setVehicleMinYear] = useState();
  const [models, setModels] = useState([]);
  const [carImage, setCarImage] = useState("");
  const dispatch = useDispatch();
  const [isCarAccepted, setIsCarAccepted] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await dispatch(getAllVehicles());
      setLoading(false);
    };
    getData();
  }, [dispatch]);

  const allVehicles = useSelector(
    (state) => state.vehicle_reducers.allVehicles
  );

  useEffect(() => {
    if (allVehicles) {
      let selectedMakes = [];
      allVehicles.map((vehicle) => {
        const makeInSelectedMakes = selectedMakes.find(
          (mk) => mk === vehicle.vehicleMake
        );
        if (!makeInSelectedMakes) {
          selectedMakes = [...selectedMakes, vehicle.vehicleMake];
        }
        return vehicle;
      });

      setMakes(selectedMakes);
    }
  }, [allVehicles]);

  useEffect(() => {
    if (make && allVehicles) {
      const foundModels = allVehicles.filter(
        (vehicle) => vehicle.vehicleMake === make
      );

      setModels(foundModels);
    }
  }, [allVehicles, make]);

  const applicationProgressData = useSelector(
    (state) => state.application_reducers.applicationProgress.step1
  );

  useEffect(() => {
    setMake(applicationProgressData.vehicleMake);
    setModel(applicationProgressData.vehicleModel);
    setYear(applicationProgressData.vehicleManufactureYear);
    setVehicleCC(applicationProgressData.vehicleCC);
  }, [applicationProgressData]);

  useEffect(() => {
    if (make && model) {
      const vehicleSelected = allVehicles.find((vehicle) => {
        return vehicle.vehicleMake === make && vehicle.vehicleModel === model;
      });

      if (vehicleSelected) {
        setVehicleMinYear(parseInt(vehicleSelected.yearOfManufacture, 10));
      }
    }
  }, [make, model, allVehicles]);

  useEffect(() => {
    if (allVehicles && make && model && vehicleMinYear) {
      const activeVehicle = allVehicles.find((vehicle) => {
        return (
          vehicle.vehicleMake?.toLowerCase().trim() ===
            make.toLowerCase().trim() &&
          vehicle.vehicleModel?.toLowerCase().trim() ===
            model.toLowerCase().trim() &&
          parseInt(vehicle.yearOfManufacture) >= vehicleMinYear
        );
      });

      if (activeVehicle) {
        setCarImage(activeVehicle.vehiclePhoto ?? defaultCarImage);

        if (activeVehicle.isAccepted) {
          setIsCarAccepted(true);
        } else {
          setIsCarAccepted(false);
        }
      }
    }
  }, [make, allVehicles, model, vehicleMinYear]);

  useEffect(() => {
    setCurrentStepCanProgress(isCarAccepted);
  }, [isCarAccepted, setCurrentStepCanProgress]);

  const handleMakeChange = (e) => {
    dispatch(unsetApplicationData(e.target.value));
  };

  const handleModelChange = (e) => {
    setModel(e.target.value);
    if (make) {
      dispatch(
        persistProgressData({
          step: "step1",
          payload: {
            vehicleMake: make,
            vehicleModel: e.target.value,
            vehicleManufactureYear: year,
            vehicleCC: vehicleCC,
          },
        })
      );
    }
  };

  const handleCCChange = (e) => {
    setVehicleCC(e.target.value);

    if (model) {
      dispatch(
        persistProgressData({
          step: "step1",
          payload: {
            vehicleMake: make,
            vehicleModel: model,
            vehicleManufactureYear: year,
            vehicleCC: e.target.value,
          },
        })
      );
    }
  };

  const handleYearChange = (e) => {
    setYear(e.target.value);
    // dispatch an action to persist car details
    dispatch(
      persistProgressData({
        step: "step1",
        payload: {
          vehicleMake: make,
          vehicleModel: model,
          vehicleManufactureYear: e.target.value,
          vehicleCC: vehicleCC,
        },
      })
    );
  };

  const allCarInfoAdded = () => {
    return make && model && year;
  };

  if (currentStep !== 1) {
    return null;
  }

  return (
    <StepFormContainer className="pt-lg-4 ">
      <ScrollToTop />
      <div className="text-dark-gray max-w-800 text-center font-17 my-3 mt-md-4 mb-md-5">
        Select the car <strong> you have or you intend to buy</strong> from the
        drop-down list of acceptable cars. If you plan to buy a car, we can
        assist you, as long you leave your contact details at the end of this
        3-step process.
      </div>
      <div className="p-2 max-w-900 view_car_wrapper text-center">
        <Form className="car_select_form my-sm-4 d-flex flex-wrap align-items-center justify-content-center ">
          <Form.Group controlId="exampleForm.ControlSelect1" className="mb-0">
            <Form.Control
              as="select"
              className="dashboard-select text-center mt-0"
              name="make"
              onChange={handleMakeChange}
              value={make}
            >
              <option value="">Make</option>
              {makes.map((make, index) => (
                <option value={make} key={index}>
                  {make}
                </option>
              ))}
            </Form.Control>
            <span className="select-info">Vehicle make</span>
          </Form.Group>
          <Form.Group
            controlId="exampleForm.ControlSelect2"
            className=" text-center mb-0"
          >
            <Form.Control
              as="select"
              className="dashboard-select text-center mt-0"
              name="model"
              onChange={handleModelChange}
              disabled={!make}
              value={model}
            >
              <option value="">Model</option>
              {models.map((model, idx) => (
                <option value={model.vehicleModel} key={model._id}>
                  {model.vehicleModel}
                </option>
              ))}
            </Form.Control>
            <span className="select-info">Vehicle model</span>
          </Form.Group>
          <Form.Group
            controlId="exampleForm.ControlSelect3"
            className=" text-center mb-0"
          >
            <Form.Control
              as="select"
              className="dashboard-select text-center mt-0"
              name="cc"
              onChange={handleCCChange}
              disabled={!model}
              value={vehicleCC}
            >
              <option value="">CC</option>
              {CCs.map((cc) => (
                <option value={`${cc}`}>{cc}</option>
              ))}
              {/* <option value="999">LTE 999 </option>
              <option value="1000">GTE 1000</option> */}
              {/* {models.map((model, idx) => (
                <option value={model.vehicleModel} key={model._id}>
                  {model.vehicleModel}
                </option>
              ))} */}
            </Form.Control>
            <span className="select-info">Vehicle CC</span>
          </Form.Group>
          <Form.Group
            controlId="exampleForm.ControlSelect4"
            className="text-center mb-0"
          >
            <Form.Control
              as="select"
              className="dashboard-select text-center mt-0"
              name="year"
              onChange={handleYearChange}
              disabled={!vehicleCC}
              value={year}
            >
              <option value="">Year</option>
              {carYears.map((year, idx) => (
                <option value={year} key={idx}>
                  {year}
                </option>
              ))}
            </Form.Control>
            <span className="select-info">Year of manufacture</span>
          </Form.Group>
        </Form>
        {loading ? (
          <div className="text-center my-md-5">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            {allCarInfoAdded() ? (
              <>
                <img src={carImage} alt="car" className="img-fluid car_image" />
                <CustomStyledButton
                  title={"A cool car! Let’s view earnings for this car"}
                  classNames={
                    "bg-steel-blue text-white font-20 mb-3 mb-md-4 mt-3"
                  }
                  onClick={() => {
                    TrackGoogleAnalyticsEvent(
                      RentVehicleCategory,
                      SelectedVehicleAction,
                      `${make}-${model}-${year}`
                    );
                    setCurrentStep(2);
                  }}
                  hasRightIcon
                />
              </>
            ) : (
              <img src={defaultCarImage} alt="car" className="img-fluid" />
            )}
          </>
        )}
      </div>
    </StepFormContainer>
  );
};

export default Step1;

const StepFormContainer = styled.div`
  .dashboard-select {
    background: transparent !important;
    border: 0 !important;
    max-width: 420px !important;
    align-self: center;
    font-size: 35px;
    color: #435cb5 !important;
    height: auto !important;
    font-weight: 700;
    appearance: auto !important;
    padding: 0 !important;

    @media screen and (max-width: 768px) {
      font-size: 25px;
      font-weight: 500;
    }

    @media screen and (max-width: 475px) {
      font-size: 18px;
      font-weight: 400;
    }
  }
  .dashboard-select > option {
    font-size: 15px !important;
  }

  select:hover,
  select:focus {
    color: #435cb5 !important;
    box-shadow: none !important;
  }

  .view_car_wrapper {
    background: #fffffe;
    border: 0.5px solid #998d8d;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    min-height: 500px;

    .car_select_form {
      row-gap: 0.5rem;
      column-gap: 1rem;

      @media screen and (max-width: 475px) {
        column-gap: 0.5rem;
        select {
          margin-bottom: 0;
        }
      }
    }
    .car_image {
      max-height: 400px;
    }
  }
  .select-info {
    background: #e4e1e1;
    border-radius: 3px;
    font-size: 15px;
    color: #5b5b5b;
    display: block;
    text-align: center;
    padding: 0.2rem 0.4rem;

    @media screen and (max-width: 475px) {
      font-size: 11px;
    }
    @media screen and (min-width: 577px) {
      min-width: 165px;
    }
  }
`;
