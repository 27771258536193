export const carMakes = {
  Toyota: [
    { modelName: "Passo", accepted: true },
    { modelName: "Vitz", accepted: true },
    { modelName: "Noah", accepted: false },
    { modelName: "Coaster", accepted: false },
    { modelName: "Alphard", accepted: false },
    { modelName: "Hiace Van", accepted: false },
  ],
  Honda: [
    { modelName: "Fit", accepted: true },
    { modelName: "Fit Hybrid", accepted: true },
    { modelName: "CR-V", accepted: false },
  ],
  Nissan: [
    { modelName: "March", accepted: true },
    { modelName: "Caravan Van", accepted: false },
  ],
  Mazda: [
    { modelName: "Demio", accepted: true },
    { modelName: "CX-5", accepted: false },
  ],
  Suzuki: [
    { modelName: "Alto", accepted: true },
    { modelName: "Carry Truck", accepted: false },
  ],
  Mitsubishi: [
    { modelName: "Colt", accepted: true },
    { modelName: "Canter", accepted: false },
    { modelName: "Outlander", accepted: false },
    { modelName: "Fighter", accepted: false },
  ],
  Daihatsu: [
    { modelName: "Mira", accepted: true },
    { modelName: "Boon", accepted: true },
    { modelName: "Hijet Truck", accepted: false },
  ],
};

export const carYears = [
  "2023",
  "2022",
  "2021",
  "2020",
  "2019",
  "2018",
  "2017",
  "2016",
  "2015",
  "2014"
];

export const workingDaysData = [
  { id: 1, label: "21 days", value: "21 days" },
  { id: 2, label: "20 days", value: "20 days" },
  { id: 3, label: "19 days", value: "19 days" },
  { id: 4, label: "18 days", value: "18 days" },
  { id: 5, label: "17 days", value: "17 days" },
  { id: 6, label: "16 days", value: "16 days" },
  { id: 7, label: "15 days", value: "15 days" },
  { id: 8, label: "14 days", value: "14 days" },
  { id: 9, label: "13 days", value: "13 days" },
  { id: 10, label: "12 days", value: "12 days" },
  { id: 11, label: "11 days", value: "11 days" },
  { id: 12, label: "10 days", value: "10 days" },
  { id: 13, label: "9 days", value: "9 days" },
  { id: 14, label: "8 days", value: "8 days" },
  { id: 15, label: "7 days", value: "7 days" },
  { id: 16, label: "6 days", value: "6 days" },
  { id: 17, label: "5 days", value: "5 days" },
  { id: 18, label: "4 days", value: "4 days" },
  { id: 19, label: "3 days", value: "3 days" },
  { id: 20, label: "2 days", value: "2 days" },
  { id: 21, label: "1 day", value: "1 day" },
];
