export const sidebarData = {
  applicationItems: [
    { id: 1, name: 'Single Applications', path: '/admin/applications' },
    { id: 2, name: '', path: '/admin/bulk/applications' },
    { id: 3, name: '', path: '/admin/applications/waiting-list' },
    //we can use remain name field empty (name:'') to active the sidebar item.
  ],
  cmsItems: [
    { id: 1, name: 'FAQs', path: '/admin/faqs' },
    { id: 2, name: 'Agreement', path: '/admin/agreement' },
    { id: 3, name: 'Vehicles', path: '/admin/vehicles' },
    { id: 4, name: 'Vehicle Makes', path: '/admin/vehicle-makes' },
  ],
}