import React from "react";
import styled from "styled-components";
import { Col, Nav, Row } from "react-bootstrap";
import { deleteAccountSteps } from "../data";
import ScrollspyNav from "react-scrollspy-nav";

const DeleteAccount = () => {
  const targetIds = deleteAccountSteps.map((step) => 'step_' + step.id);

  return (
    <Wrapper className="min-height-70 page-height pt-3 pt-lg-5 mx-auto">
      <Col xl={10} className='mx-auto px-3 px-lg-4 px-xl-0'>
        <div className="text-center">
          <p className="font-15 text-deep-blue text-uppercase mb-1 mb-lg-2 main_title">how to delete your account</p>
          <h2>Account Deletion</h2>
        </div>
        <Row className="mt-lg-5 pt-3 position-relative">
          <Col lg={8}>
            <div className="max-w-750 mx-auto mx-xl-0">
              {deleteAccountSteps.map((step) => {
                const { id, title, desc, image } = step;
                return (
                  <div key={id} className="mb-4 pb-2 mb-lg-5 pb-lg-5">
                    <h6 className="font-18 text-deep-blue text-uppercase">step {id}</h6>
                    <h3>{title}</h3>
                    <p className="text-gray max-w-500 my-md-4">{desc}</p>
                    <div id={`step_${id}`} className="step_image_wrapper br-12 px-3 py-3 py-md-4 py-lg-5 text-center">
                      <img src={image} alt={`step_${id}`} className="img-fluid" />
                    </div>
                  </div>
                )
              })}
            </div>
          </Col>
          <Col lg={4} className="delete_contents_sidebar d-none d-lg-block">
            <h5 className="font-weight-bold font-20 pl-3">Account Deletion Tab</h5>
            <ScrollspyNav
              scrollTargetIds={targetIds}
              activeNavClass="step_active"
              scrollDuration="100"
              className="spy-nav"
            >
              <>
                {deleteAccountSteps.map((step) => {
                  const { id, title } = step;
                  return (
                    <Nav.Link
                      key={id}
                      href={`#step_${id}`}
                      className="delete_step pl-3 py-3"
                    >
                      Step {id}: {title}
                    </Nav.Link>
                  );
                })}
              </>
            </ScrollspyNav>
          </Col>
        </Row>
      </Col>
    </Wrapper>
  );
};

export default DeleteAccount;

const Wrapper = styled.div`
max-width: 1800px;

h2,h3 {
 font-weight: 700;
 color: #2E2E2E;
}
@media (min-width: 768px) {
  h2 {
   font-size: 35px;
  }
  h3 {
   font-size: 25px;
  }
 }
 @media (min-width: 992px) {
  h2 {
   font-size: 50px;
  }
  h3 {
   font-size: 40px;
  }
  .main_title {
   font-size: 18px !important;
  }
 }
.step_image_wrapper {
  background:#CDEEFC;
}

.delete_contents_sidebar {
  position: fixed;
  right: 0;
  bottom:32%;
  .delete_step {
    border-left: 4px solid #F2F2F2;
    color:#939393;
  }
  .step_active {
    color:#2E2E2E;
    font-weight: bold;
    border-left: 4px solid var(--deep-blue);
  }
}
`;