import * as vehicle_types from "../../types/vehicle_types";

const initialState = {
  loading: false,
  error: "",
  success: "",
  vehicleMakes: [],
  vehicleMake: {},
  vehicleModesl: [],
  vehicleModel: {},
  allVehicles: [],
  vehicle: {},
};

export const vehicle_reducers = (state = initialState, action) => {
  switch (action.type) {
    case vehicle_types.CREATE_VEHICLE_MAKE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vehicle_types.CREATE_VEHICLE_MAKE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Vehicle make created successfully",
        error: "",
        vehicleMakes: [action.payload, ...state.vehicleMakes],
      };

    case vehicle_types.CREATE_VEHICLE_MAKE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };

    case vehicle_types.GET_VEHICLE_MAKES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vehicle_types.GET_VEHICLE_MAKES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Vehicle makes fetched successfully",
        error: "",
        vehicleMakes: action.payload,
      };

    case vehicle_types.GET_VEHICLE_MAKES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };

    case vehicle_types.DELETE_VEHICLE_MAKE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vehicle_types.DELETE_VEHICLE_MAKE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Vehicle make deleted successfully",
        error: "",
        vehicleMakes: state.vehicleMakes.filter(
          (vehicle) => vehicle._id !== action.payload
        ),
      };

    case vehicle_types.DELETE_VEHICLE_MAKE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };

    case vehicle_types.EDIT_VEHICLE_MAKE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vehicle_types.EDIT_VEHICLE_MAKE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Vehicle make updated successfully",
        error: "",
      };

    case vehicle_types.EDIT_VEHICLE_MAKE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };

    case vehicle_types.GET_ALL_VEHICLES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vehicle_types.GET_ALL_VEHICLES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "All vehicle fetched successfully",
        error: "",
        allVehicles: action.payload,
      };

    case vehicle_types.GET_ALL_VEHICLES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
      };

    case vehicle_types.GET_VEHICLE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vehicle_types.GET_VEHICLE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Get vehicle successfully",
        error: "",
        vehicle: action.payload,
      };

    case vehicle_types.GET_VEHICLE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: "",
        vehicle: {},
      };

    case vehicle_types.DELETE_VEHICLE_SUCCESS:
      return {
        ...state,
        error: "",
        allVehicles: action.data,
      };

    default:
      return {
        ...state,
      };
  }
};
