import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import FaqsCard from "../components/FaQs/FaqsCard";
import Loading from "../components/Loading";
import { fetchFaqs } from "../redux/actions/faq_actions/";

export default function Faqs() {
  const dispatch = useDispatch();
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      let data = await dispatch(fetchFaqs());

      setFaqs((faqs) => [...data]);
      setLoading(false);
    };
    getData();
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="faqs page-height">
      <div className="container pt-2">
        <h1 className="mt-5">FAQs</h1>
        <div className="faqs-container my-5">
          {faqs && faqs.length > 0 ? (
            <>
              {faqs &&
                faqs.map((data, idx) => <FaqsCard data={data} key={idx} />)}
            </>
          ) : (
            <p>No faqs</p>
          )}
        </div>
      </div>
    </div>
  );
}
