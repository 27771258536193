import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { MdAdminPanelSettings, MdDashboard, MdOutlineSettingsAccessibility } from "react-icons/md";
import { FaPlayCircle, FaUsers } from "react-icons/fa";
import { IoSettings } from "react-icons/io5";
import AdminSidebarItem from "./AdminSidebarItem";
import { sidebarData } from "./data";

const AdminSidebar = () => {
  const [isApplicationsOpen, setIsApplicationsOpen] = useState(false);
  const [isCMSOpen, setIsCMSOpen] = useState(false);
  const location = useLocation();

  const handleCMSOpen = () => {
    setIsCMSOpen(!isCMSOpen);
    setIsApplicationsOpen(false);
  };

  const handleApplicationsOpen = () => {
    setIsApplicationsOpen(!isApplicationsOpen);
    setIsCMSOpen(false);
  };

  const handleCloseAllDropdown = () => {
    setIsApplicationsOpen(false)
    setIsCMSOpen(false)
  };


  if (location.pathname.includes("/applications/media")) {
    return null;
  }

  return (
    <div className="admin-sidebar">
      <AdminSidebarItem
        title="Dashboard"
        icon={<MdDashboard className="icon-item" />}
        sidebarItemPath={`/admin`}
        closeAllDropdown={handleCloseAllDropdown}
        hasNoDropdown
      />
      <AdminSidebarItem
        title="Admins"
        icon={<MdAdminPanelSettings className="icon-item" />}
        sidebarItemPath={`/admin/admins`}
        closeAllDropdown={handleCloseAllDropdown}
        hasNoDropdown
      />
      <AdminSidebarItem
        title="Media"
        icon={<FaPlayCircle className="icon-item" />}
        sidebarItemPath={`/admin/media`}
        closeAllDropdown={handleCloseAllDropdown}
        hasNoDropdown
      />
      <AdminSidebarItem
        title="Applications"
        icon={<FaUsers className="icon-item" />}
        sidebarItems={sidebarData?.applicationItems}
        sidebarItemOpen={isApplicationsOpen}
        handleSidebarItemOpen={handleApplicationsOpen}
      />
      <AdminSidebarItem
        title="Inquiries"
        icon={<MdOutlineSettingsAccessibility className="icon-item" />}
        sidebarItemPath={`/admin/inquiries`}
        closeAllDropdown={handleCloseAllDropdown}
        hasNoDropdown
      />
      <AdminSidebarItem
        title="Manage CMS"
        icon={<IoSettings className="icon-item" />}
        sidebarItems={sidebarData?.cmsItems}
        sidebarItemOpen={isCMSOpen}
        handleSidebarItemOpen={handleCMSOpen}
      />
    </div>
  );
};

export default AdminSidebar;
