import React from "react";
import { Link } from "react-router-dom";

const HowItWorksBox = ({ data, id }) => {
  return (
    <div
      className={`how-it-works-box ${id === 3 && `how-it-works-box-borderless`
        } `}
    >
      <div className="count-box">{id + 1}</div>
      <div className="content">
        <h6 className="title mb-3">{data.title}</h6>
        <p className="paragraph font-15">{data.content}</p>
        <Link to={data.button.link} target="_blank" className="btn action-btn font-15">
          {data.button.text}
        </Link>
      </div>
    </div>
  );
};

export default HowItWorksBox;
