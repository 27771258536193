import React from "react";
import { Link, useLocation } from "react-router-dom";
// import { Icon } from "@iconify/react";
// import formNext from "@iconify/react/grommet-icons/form-next";
import youtubeIcon from "../../dist/icons/youtube.svg";
import twitterIcon from "../../dist/icons/twitter.svg";
import linkedInIcon from "../../dist/icons/linked-in.svg";
import instagramIcon from "../../dist/icons/instagram.svg";
import facebookIcon from "../../dist/icons/facebook.svg";

const Footer = () => {
  const location = useLocation();

  const urlPath = location.pathname.split("/")[1];

  if (urlPath === "admin") return null;

  return (
    <div className="row footer bg-light-black p-3 border-top-white">
      <div className="col-lg-12 footer-top">
        <div className="row">
          <div className="col-md-9 row">
            <div className="col-lg-4 footer-nav-links">
              <Link to="/rent-vehicle">Rent out your vehicle</Link>
              <Link to="/drivers-looking-for-vehicles">
                Drivers looking for vehicles
              </Link>
              <Link to="/vehicles-list">List of acceptable vehicles</Link>
              <Link to="/agreement">Fleetsimplify Management Agreement</Link>
            </div>
            <div className="col-lg-4 footer-nav-links">
              <Link to="/pricing?type=management-plan">
                Fleetsimplify Management Plan
              </Link>
              <Link to="/bulk-onboarding">Bulk-Onboarding & Partnership</Link>
              <Link to="/partner-app-screenshots">Partner App Screenshots</Link>
              <Link to="/driver-app-screenshots">Driver App Screenshots</Link>
            </div>
            <div className="col-lg-4 footer-nav-links">
              <Link to="/pricing">Pricing</Link>
              <Link to="/faqs">Faqs</Link>
              <Link to="/products">Products</Link>
              <Link to="/contact-us">Contact Us</Link>
            </div>
            <div className="col-sm-12 row d-flex align-items-center flex-wrap contact-icons mt-4">
              <div className="col-lg-6">
                <a
                  href=" https://www.youtube.com/channel/UCw21dQuxYjk8YfYY4DgI_oQ"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={youtubeIcon}
                    alt="youtube icon"
                    className="img-fluid"
                  />
                </a>
                <a
                  href="https://twitter.com/Fleetsimplify"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={twitterIcon}
                    alt="twitter icon"
                    className="img-fluid"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/Fleetsimplify/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={linkedInIcon}
                    alt="linkedIn icon"
                    className="img-fluid"
                  />
                </a>
                <a
                  href="https://www.facebook.com/Fleetsimplify"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={facebookIcon}
                    alt="facebook icon"
                    className="img-fluid"
                  />
                </a>
                <a
                  href="https://www.instagram.com/fleet.simplify/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={instagramIcon}
                    alt="instagram icon"
                    className="img-fluid"
                  />
                </a>
              </div>
              <div className="col-md-6">
                <p className="text-white inc-text">
                  &copy;&nbsp;Fleetsimplify,Inc
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 row">
            <div className="other-sites">
              <a
                href="https://instadriver.co/"
                target="_blank"
                rel="noreferrer"
                className="btn btn-warning br-5 ml-2 pr-2 footer-redirect-btn d-flex"
              >
                Go to Instadriver &nbsp;
                {/* <Icon width={30} icon={formNext} /> */}
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-lg-12 footer-bottom text-white text-center font-15 py-3 mt-3 border-top-white d-flex align-items-center justify-content-center">
        Copyright&copy;2021. Fleetsimplify.inc | All rights reserved
      </div> */}
    </div>
  );
};

export default Footer;
