import * as contact_types from "../../types/contact_types";
import { PostRoute } from "../../../api";

const { Post } = PostRoute();

export const createContactMessage = (data) => async (dispatch) => {
  try {
    dispatch({ type: contact_types.CREATE_CONTACT_MESSAGE_REQUEST });

    const response = await Post("/contact-us/create", data);

    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    dispatch({
      type: contact_types.CREATE_CONTACT_MESSAGE_SUCCESS,
      payload: "Message created successfully",
    });

    return response.data.response_data;
  } catch (err) {
    dispatch({
      type: contact_types.CREATE_CONTACT_MESSAGE_ERROR,
      payload: err.message,
    });
  }
};
