import React, { useState } from "react";
import styled from "styled-components";
// import { Icon } from "@iconify/react";
// import bxEditAlt from "@iconify/react/bx/bx-edit-alt";
// import delete48Filled from "@iconify/react/fluent/delete-48-filled";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { deleteVehicle } from "../../redux/actions/vehicle_actions";

const VehiclesComp = ({ active, vehicles }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentId, setCurrentId] = useState(-1);
  const dispatch = useDispatch();
  const token = useSelector(
    (state) => state.auth_reducers.auth_credentials.token
  );

  const handleDeleteVehicle = async () => {
    setShowModal(false);
    await dispatch(deleteVehicle(currentId, token));
  };

  const allVehicles = useSelector(
    (state) => state.vehicle_reducers.allVehicles
  );

  return (
    <Wrapper>
      {vehicles && vehicles.length > 0 ? (
        <>
          {vehicles &&
            vehicles.map((vehicle, index) => (
              <div
                className="bg-dark-white text-dark-gray min-h-40 text-center font-15 table-row border-3 d-flex justify-content-between align-items-center text-dark-gray mb-2"
                key={vehicle._id}
              >
                <span className="border-right-gray font-15 min-h-40 px-3 d-flex align-items-center font-weight-bold">
                  {index + 1}
                </span>
                <div className="w-50 d-flex justify-content-around">
                  <span className="font-15 d-flex align-items-center">
                    {vehicle && vehicle.vehicleMake}
                  </span>
                  <span className="font-15 d-flex align-items-center">
                    {vehicle && vehicle.vehicleModel}
                  </span>
                  <span className="font-15 d-flex align-items-center">
                    {vehicle && vehicle.yearOfManufacture}
                  </span>
                </div>
                <div className="w-25 d-flex d-none d-xl-block"></div>
                <div className="w-25 d-flex justify-content-around">
                  <Link
                    to={`/admin/edit-vehicle/${vehicle._id}`}
                    className="font-25 cursor-pointer text-dark-gray"
                  >
                    Edit Vehicle
                    {/* <Icon icon={bxEditAlt} /> */}
                  </Link>
                  {/* <span
                    className="font-25 cursor-pointer"
                    onClick={() => {
                      setShowModal(true);
                      setCurrentId(vehicle._id);
                    }}
                  >
                    <Icon icon={delete48Filled} />
                  </span> */}
                </div>
              </div>
            ))}
        </>
      ) : allVehicles && allVehicles.length > 0 ? (
        <div className="bg-dark-white text-dark-gray p-2 text-center font-15 table-row border-3 mb-2">
          No {active === 1 ? "Acceptable Vehicles" : "Non-Acceptable Vehicles"}
          {". "}
          Tap the button “Add Vehicle” to make your list
        </div>
      ) : (
        <div className="bg-dark-white text-dark-gray p-2 text-center font-15 table-row border-3 mb-2">
          No Vehicles Added. Tap the button “Add Vehicle” to make your list
        </div>
      )}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h3>Delete this vehicle?</h3>
        </Modal.Header>
        <Modal.Body>
          <div>
            Heads Up! You are about to delete a vehicle model. Deleting this
            model means that it will no longer appear among listed vehicles. Are
            you sure you want to delete?
          </div>

          <div className="d-flex align-items-center justify-content-md-end justify-content-center p-3 flex-wrap">
            <button
              onClick={() => setShowModal(false)}
              className="btn btn-blue my-2 mx-2"
            >
              Cancel
            </button>
            <button
              onClick={() => handleDeleteVehicle(currentId)}
              className="btn btn-danger my-2 w-sm-100 mx-2"
            >
              Yes, Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </Wrapper>
  );
};

export default VehiclesComp;

const Wrapper = styled.div`
  .content-box {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.23);
    border-radius: 3px;
  }
`;
