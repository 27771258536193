import React from "react";
import { Modal } from "react-bootstrap";
import { RiCloseCircleFill } from "react-icons/ri";

const DeleteMakeModal = ({ currentMakeId, handleDeleteMake, ...props }) => {
  return (
    <Modal
      {...props}
      size="lg"
      centered
    >
      <Modal.Header>
        <div className="d-flex w-100">
          <h3 className="w-100">Delete this Vehicle Make &amp; its Models?</h3>
          <div className="text-right">
            <RiCloseCircleFill
              onClick={() => props?.onHide()}
              className="icon-30 text-medium-dark"
            />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        {/* <h3 className="text-center">Heads up!</h3> */}
        <p className=" mt-4 mb-5">
          <strong>Heads Up!</strong> If you remove the vehicle make, the vehicle
          make and ALL its models will no longer appear on the list of vehicles
          in your vehicle calculator. Are you sure you want to remove this
          vehicle make and all its models?
        </p>

        <div className="modal-footer border-0 d-flex justify-content-between">
          <button
            // className="btn btn-lighter-secondary br-5"
            className="btn btn-danger"
            type="button"
            onClick={() => handleDeleteMake(currentMakeId)}
          >
            Yes, Remove Vehicle Make & Its Models
          </button>
          <button
            type="button"
            // className="btn btn-lighter-secondary-outline br-5"
            className="btn btn-blue"
            onClick={() => props.onHide()}
          >
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteMakeModal;
