import React from "react";
import AdminFAQ from "./AdminFAQ/AdminFAQ";

const AdminAddFAQ = () => {
  return (
    <div>
      <AdminFAQ faqType="add" />
    </div>
  );
};

export default AdminAddFAQ;
