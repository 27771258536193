import axios from "axios";
import { getAuthConfig } from "../Util/Util";
import { mediaType } from "../Util/types";

export const getAllMedia = async () => {
  try {
    const response = await axios.get("media");

    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAllMediaCategories = async (fileType, withMedia) => {
  const params = {};
  if (withMedia) {
    params.withMedia = true;
  }

  if (fileType) {
    params.file_type = fileType === mediaType.PHOTOS ? "IMAGE" : "VIDEO";
  }

  try {
    const response = await axios.get("media/categories", {
      params,
    });

    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getMediaByCategory = async (fileType, categoryId) => {
  try {
    const response = await axios.get(`media`, {
      params: categoryId
        ? {
            file_type: fileType === "photos" ? "IMAGE" : "VIDEO",
            id: categoryId,
          }
        : {
            file_type: fileType === "photos" ? "IMAGE" : "VIDEO",
          },
    });

    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const createMedia = async (payload, token) => {
  try {
    const config = getAuthConfig(token);
    const response = await axios.post("media", payload, config);

    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateMedia = async (payload, id, token) => {
  try {
    const config = getAuthConfig(token);
    const response = await axios.put(`media/${id}`, payload, config);

    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteMedia = async (id, token) => {
  try {
    const config = getAuthConfig(token);
    const response = await axios.delete(`media/${id}`, config);

    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getMedia = async (id) => {
  try {
    const response = await axios.get(`media/${id}`);

    return response.data;
  } catch (err) {
    console.log(err);
  }
};
