import React from "react";
import styled from "styled-components";
import { TrackGoogleAnalyticsEvent } from "../../Util/google-analytics";
import RentVehicleCategory, {
  ToggledUSD,
} from "../../Util/google-analytics/events/rentVehicle";

export default function CustomSelectFilter({ filterData, setSelectFilter }) {
  return (
    <Wrapper>
      <select
        onChange={(e) => {
          TrackGoogleAnalyticsEvent(
            RentVehicleCategory,
            ToggledUSD,
            window.location.pathname
          );
          setSelectFilter(e.target.value);
        }}
        className="form-select currency_select"
      >
        {filterData.map((item) => (
          <option key={item.id} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .currency_select {
    appearance: none !important;
    border-radius: 50px;
    padding: 0 2rem;
    width: 150px;
    height: 40px;
  }
`;
