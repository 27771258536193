import React from "react";
import { numbersData } from "../../data";
import NumberCard from "./NumberCard";

const Numbers = () => {
  return (
    <div
      id="our-numbers"
      className="row m-0 pt-lg-5 justify-content-center px-3"
      style={{ backgroundColor: "#F5F7F9" }}
    >
      <div className="col-lg-11 mx-auto d-flex align-items-center flex-column py-4">
        <div className="row font-weight-900 font-50 text-light-black mb-5">
          <h3 className="font-50 font-weight-900">Our Numbers</h3>
        </div>
        <div className="row w-100 m-0">
          {numbersData &&
            numbersData.map((value, idx) => (
              <NumberCard data={value} key={idx} id={idx} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Numbers;
