export const FAQ_FETCH_REQUEST = "FAQ_FETCH_REQUEST";
export const FAQ_FETCH_SUCCESS = "FAQ_FETCH_SUCCESS";
export const FAQ_FETCH_ERROR = "FAQ_FETCH_ERROR";

export const FAQS_FETCH_REQUEST = "FAQS_FETCH_REQUEST";
export const FAQS_FETCH_SUCCESS = "FAQS_FETCH_SUCCESS";
export const FAQS_FETCH_ERROR = "FAQS_FETCH_ERROR";

export const FAQ_CREATE_REQUEST = "FAQ_CREATE_REQUEST";
export const FAQ_CREATE_SUCCESS = "FAQ_CREATE_SUCCESS";
export const FAQ_CREATE_ERROR = "FAQ_CREATE_ERROR";

export const FAQ_EDIT_REQUEST = "FAQ_EDIT_REQUEST";
export const FAQ_EDIT_SUCCESS = "FAQ_EDIT_SUCCESS";
export const FAQ_EDIT_ERROR = "FAQ_EDIT_ERROR";

export const FAQ_DELETE_REQUEST = "FAQ_DELETE_REQUEST";
export const FAQ_DELETE_SUCCESS = "FAQ_DELETE_SUCCESS";
export const FAQ_DELETE_ERROR = "FAQ_DELETE_ERROR";
