import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import InquiryBox from "./InquiryBox";
import { useSelector } from "react-redux";

function InquiryBoxContainer(props) {
  let inquiries = null;
  const allInquiries = useSelector((state) => state.inquiry_reducers.inquiries);

  const filteredInquiries = useSelector(
    (state) => state.inquiry_reducers.filteredInquiries
  );

  if (filteredInquiries.length > 0) {
    inquiries = filteredInquiries;
  } else {
    inquiries = allInquiries;
  }

  if (inquiries && inquiries.length === 0) {
    return <div>No inquiries</div>;
  }

  return (
    <Wrapper>
      {inquiries && inquiries.map((d) => <InquiryBox key={d._id} data={d} />)}
    </Wrapper>
  );
}

export default InquiryBoxContainer;

const Wrapper = styled(Container)`
  border: 1px solid #d4d4d4;
  background-color: #fff;
  overflow-y: auto;
  max-height: calc(100vh - 110px);
  &::-webkit-scrollbar {
    width: 7px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d4d4d4;
    border-radius: 3px;
  }
`;
