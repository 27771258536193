import React from "react";
import capture1 from "../dist/images/capture1.png";
import capture2 from "../dist/images/capture2.png";
import capture3 from "../dist/images/capture3.png";
import capture4 from "../dist/images/capture4.png";
import trape1 from "../dist/images/trape1.png";
import trape2 from "../dist/images/trape2.png";

export default function AppScreenShots() {
  return (
    <div id="app-screenshots" className="">
      <section className="section-one row m-0 align-items-center mb-5 px-2 px-lg-5 flex-wrap-reverse odd-screenshots">
        <div className="col-lg-6 text-center">
          <img src={capture1} alt="capture1" />
        </div>
        <div className="col-lg-6 d-flex justify-content-center">
          <h4 className="mb-3 mb-lg-0 font-weight-400 font-45 font-weight-400 font-45">
            Get an overview of your vehicle performance
          </h4>
        </div>
      </section>

      <section className="section-one  even-screenshot-lg my-5">
        <img src={trape1} width="100%" height="500px" alt="trape1" />
        <div className="inner-screenshot-wrapper row m-0 align-items-center mb-5 px-2 px-lg-5">
          <div className="col-lg-6 d-flex justify-content-center">
            <h4 className="mb-3 mb-lg-0 font-weight-400 font-45 mt-5">
              See vehicle maintenance history
            </h4>
          </div>
          <div className="col-lg-6 text-center">
            <img src={capture2} alt="capture1" />
          </div>
        </div>
      </section>

      <section className="section-one row m-0 align-items-center mb-5 px-2 px-lg-5 even-screenshot">
        <div className="col-lg-6 d-flex justify-content-center">
          <h4 className="mb-3 mb-lg-0 font-weight-400 font-45">
            See vehicle maintenance history
          </h4>
        </div>
        <div className="col-lg-6 text-center">
          <img src={capture2} alt="capture1" />
        </div>
      </section>
      <section className="section-one row m-0 align-items-center mb-5 px-2 px-lg-5 flex-wrap-reverse odd-screenshots">
        <div className="col-lg-6 text-center">
          <img src={capture3} alt="capture1" />
        </div>
        <div className="col-lg-6 d-flex justify-content-center">
          <h4 className="mb-3 mb-lg-0 font-weight-400 font-45">
            See revenue and expense breakdown
          </h4>
        </div>
      </section>

      <section className="section-four  even-screenshot-lg">
        <img src={trape2} width="100%" height="500px" alt="trape1" />
        <div className="inner-screenshot-wrapper row m-0 align-items-center px-2 px-lg-5">
          <div className="col-lg-6 d-flex justify-content-center">
            <h4 className="mb-3 mb-lg-0 font-weight-400 font-45">
              And many more features
            </h4>
          </div>
          <div className="col-lg-6 text-center">
            <img src={capture4} alt="capture1" />
          </div>
        </div>
      </section>
      <section className="section-four row m-0 align-items-center px-2 px-lg-5 even-screenshot">
        <div className="col-lg-6 d-flex justify-content-center">
          <h4 className="mb-3 mb-lg-0 font-weight-400 font-45">
            And many more features
          </h4>
        </div>
        <div className="col-lg-6 text-center">
          <img src={capture4} alt="capture1" />
        </div>
      </section>
    </div>
  );
}
