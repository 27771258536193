import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBulkApplications } from "../../../redux/actions/application_actions";
import Loading from "../../../components/Loading";
import TobApplicationBar from "../../../components/Applications/TobApplicationBar";

const BulkApplications = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [activeId, setActiveId] = useState(-1);
  const [searchWord, setSearchWord] = useState("");
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const token = useSelector(
    (state) => state.auth_reducers.auth_credentials.token
  );

  useEffect(() => {
    const getApplications = async () => {
      try {
        setLoading(true);
        await dispatch(getBulkApplications(token));
        setLoading(false);
      } catch (err) {
        console.log(err.message);

        setLoading(false);
      }
    };

    getApplications();
  }, [token, dispatch]);

  const bulkApplications = useSelector(
    (state) => state.application_reducers.bulkApplications
  );

  useEffect(() => {
    if (bulkApplications) {
      setData(bulkApplications);
    }
  }, [bulkApplications]);

  useEffect(() => {
    if (bulkApplications && searchWord && searchWord.length > 0) {
      let tempData = [...bulkApplications];
      const word = searchWord.trim().toLowerCase().replace(/\s+/g, " ");

      if (word.length > 0) {
        // eslint-disable-next-line array-callback-return
        tempData = tempData.filter((item) => {
          if (
            item.fullname.trim().toLowerCase().includes(word) ||
            item.companyName.trim().toLowerCase().includes(word) ||
            item.mobilityPlayerType.trim().toLowerCase().includes(word)
          ) {
            return true;
          }
        });

        setData(tempData);
      }
    } else {
      setData(bulkApplications);
    }
  }, [searchWord, bulkApplications]);

  // if (loading) {
  //   return <Loading />;
  // }

  return (
    <div className="row applications p-3">
      <TobApplicationBar
        title="Bulk Onboarding"
        seachWord={searchWord}
        setSearchWord={setSearchWord}
      />
      {loading ? (
        <Loading />
      ) : (
        <div className="row">
          <div className="col-lg-12">
            <div className="table-responsive position-relative max-height-80">
              <table className="table table-hover" id="customers">
                <thead>
                  <tr>
                    <th className="bg-dark-white border-right-gray px-3"></th>
                    <th
                      colSpan="3"
                      className="bg-dark-white text-center border-right-gray px-3"
                    >
                      Prospect Profile
                    </th>
                    <th
                      colSpan="3"
                      className="bg-dark-white text-center border-right-gray px-3"
                    >
                      Contact Details
                    </th>
                    <th className="bg-dark-white border-right-gray text-center px-3">
                      View Message
                    </th>
                    <th className="bg-dark-white text-center px-3">
                      Date &amp; Time
                    </th>
                  </tr>
                  <tr>
                    <th
                      className="bg-dark-white min-width-35 d-flex align-items-center justify-content-center"
                      rowSpan="2"
                    >
                      #
                    </th>
                    <th className="application-top-blue ">
                      Type of Mobility Player
                    </th>
                    <th className="application-top-blue">Company Name</th>
                    <th className="application-top-blue">Position</th>
                    <th className="application-top-red">Name</th>
                    <th className="application-top-red">Email</th>
                    <th className="application-top-red">Mobile No</th>
                    <th className="application-top-blue">View Message</th>
                    <th className="application-top-red">Date &amp; Time</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0 ? (
                    data.map((application, index) => (
                      <tr key={index}>
                        <th className="applications-col-light-blue  min-width-35 text-center">
                          {index + 1}
                        </th>
                        <td className="applications-col-white">
                          {application && application.mobilityPlayerType
                            ? application.mobilityPlayerType
                            : "_"}
                        </td>
                        <td className="applications-col-white">
                          {application && application.companyName
                            ? application.companyName
                            : "_"}
                        </td>
                        <td className="applications-col-white">
                          {application && application.position
                            ? application.position
                            : "_"}
                        </td>
                        <td className="applications-col-light-blue">
                          {application && application.fullname}
                        </td>
                        <td className="applications-col-light-blue">
                          {application && application.email}
                        </td>
                        <td className="applications-col-light-blue">
                          {application && application.mobileNumber}
                        </td>

                        <td className="applications-col-white position-relative text-center text-blue cursor-pointer">
                          <span
                            className="text-center text-blue cursor-pointer view-more-info-table"
                            onClick={() => {
                              index !== activeId
                                ? setShow(true)
                                : setShow(!show);
                              setActiveId(index);
                            }}
                          >
                            View Message
                          </span>
                          <MessageContainer
                            show={show}
                            id={index}
                            activeId={activeId}
                            setShow={setShow}
                            message={application && application.customMessage}
                          />
                        </td>
                        <td className="applications-col-light-blue">
                          {application &&
                            application.createdAt &&
                            `${new Date(
                              application.createdAt
                            ).toDateString()} , ${new Date(
                              application.createdAt
                            ).toLocaleTimeString()}`}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9" className="text-center ">
                        <div className="min-height-20 d-flex align-items-center justify-content-center">
                          {" "}
                          No current applications
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BulkApplications;

const MessageContainer = ({ show, id, activeId, setShow, message }) => {
  return (
    <div
      className={`view-message-container  ${show && activeId === id && "show"}`}
    >
      <div className="position-relative">
        <span className="close-icon" onClick={() => setShow(false)}>
          X
        </span>
      </div>
      <div className="message">{message}</div>
    </div>
  );
};

//   return (
//     <div className="row bulk-applications-wrapper">
//       <div className="ml-3">
//         <div className="row pt-4 bulk-table-header mb-4 position-fixed bg-white align-items-center">
//           <div className="col-lg-5">
//             <h3>Applications{" > "}Bulk-Onboarding</h3>
//           </div>
//           <div className="col-lg-3">
//             <form>
//               <div className="input-group d-flex align-items-center m-0">
//                 <div className="input-group-prepend px-2">
//                   <span className="fa fa-search d-flex align-items-center justify-content-center fa-1x"></span>
//                 </div>
//                 <input
//                   type="text"
//                   className="form-control bulk-search-input p-0 m-0"
//                   placeholder="Search applicants"
//                 />
//               </div>
//             </form>
//           </div>
//           <div className="col-lg-4 text-right pr-5">
//             <Icon icon={filterCircleFill} className="mr-5" height="30px" />
//             <Icon icon={cloudDownloadFill} height="40px" />
//           </div>
//         </div>
//         <div className=" table-responsive px-3">
//           <table className="table table-bordered bulk-table">
//             <thead>
//               <tr>
//                 <th scope="col" rowSpan="2" className="bulk-hash">
//                   #
//                 </th>
//                 <th scope="col" colSpan="3" className="text-center">
//                   Prospect Profile
//                 </th>
//                 <th scope="col" colSpan="3" className="text-center">
//                   Contact Details
//                 </th>
//                 <th scope="col" className="text-center">
//                   Message
//                 </th>
//                 <th scope="col" className="text-center">
//                   Date & Time
//                 </th>
//               </tr>
//               <tr>
//                 <th scope="col" className="blue-header text-center">
//                   Type of Mobility Player
//                 </th>
//                 <th scope="col" className="blue-header text-center">
//                   Company Name
//                 </th>
//                 <th scope="col" className="blue-header text-center">
//                   Position
//                 </th>
//                 <th scope="col" className="red-header text-center">
//                   Name
//                 </th>
//                 <th scope="col" className="red-header text-center">
//                   Email
//                 </th>
//                 <th scope="col" className="red-header text-center">
//                   Mobile No
//                 </th>
//                 <th scope="col" className="blue-header text-center">
//                   View Message
//                 </th>
//                 <th scope="col" className="red-header text-center">
//                   Date & Time
//                 </th>
//               </tr>
//             </thead>
//             <tbody>
//               {data.map((application, idx) => (
//                 <tr>
//                   <th scope="row">{idx + 1}</th>
//                   <td className="text-center">
//                     {application && application.mobilityPlayerType
//                       ? application.mobilityPlayerType
//                       : "_"}
//                   </td>
//                   <td className="text-center">
//                     {application && application.companyName
//                       ? application.companyName
//                       : "_"}
//                   </td>
//                   <td className="text-center">
//                     {application && application.position
//                       ? application.position
//                       : "_"}
//                   </td>
//                   <td className="light-purple-column text-center">
//                     {application && application.fullname}
//                   </td>
//                   <td className="light-purple-column text-center">
//                     {application && application.email}
//                   </td>
//                   <td className="light-purple-column text-center">
//                     {application && application.mobileNumber}
//                   </td>
//                   <td className="text-center">
//                     <span className="message-col pt-2">
//                       <span className="w-100">View Message</span>
//                       <span className="message-col-tooltip font-weight-400 font-15 p-3 text-left">
//                         {application && application.customMessage}
//                       </span>
//                     </span>
//                   </td>
//                   <td className="light-purple-column text-center">
//                     {application &&
//                       application.createdAt &&
//                       `${new Date(
//                         application.createdAt
//                       ).toDateString()} , ${new Date(
//                         application.createdAt
//                       ).toLocaleTimeString()}`}
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// };
