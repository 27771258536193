import React, { useState } from "react";
import { rentalPlanType } from "../Util/types";
import RentalPlanTab from "../components/Agreement/RentalPlanTab";
import Agreements from "../components/Agreement";

export default function FleetAgreement() {
  const [activePlanTab, setActivePlanTab] = useState(rentalPlanType.LUXURY);
  const [activeProgressBarIndex, setActiveProgressBarIndex] = useState(0); // Index of the active progress bar

  return (
    <div className=" page-height">
      <div className="col-xl-11 mx-auto mt-5 pt-5">
        <RentalPlanTab
          activePlanTab={activePlanTab}
          setActivePlanTab={setActivePlanTab}
          setActiveProgressBarIndex={setActiveProgressBarIndex}
        />
        <Agreements
          activePlanTab={activePlanTab}
          activeProgressBarIndex={activeProgressBarIndex}
          setActiveProgressBarIndex={setActiveProgressBarIndex}
        />
      </div>
    </div>
  );
}
