import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MonthlyYearlyTab from "../common/MonthlyYearlyTab";
import { filterType } from "../../Util/types";
import CustomSelectFilter from "../common/CustomSelectFilter";
import { earningPlans } from "../../data";
import { convertUSDorKES, currentKESValueInUSD } from "../../Util/convertMoney";
import ButtonOutlineBlue from "../common/ButtonOutlineBlue";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useSelector } from "react-redux";

const filterData = [
  { id: 1, label: "Kshs", value: "Kshs" },
  { id: 2, label: "USD", value: "USD" },
];

export default function PricingEarning({ handleUpdateAgreementTimeRead }) {
  const [activeDateFilterTab, setActiveDateFilterTab] = useState(
    filterType.MONTHLY
  );
  const [currency, setCurrency] = useState(filterData[0].value);
  const data = useSelector(
    (state) => state.application_reducers.applicationProgress
  );
  const [isAbove1000, setIsAbove1000] = useState(false);

  useEffect(() => {
    if (data.step1?.vehicleCC) {
      const numVal = parseInt(data.step1?.vehicleCC, 10);
      if (numVal >= 1000) {
        setIsAbove1000(true);
      } else {
        setIsAbove1000(false);
      }
    }
  }, [data]);

  // FIXME: add this logic if user select 1000 or more CC vehicle
  const pricingPlans = isAbove1000
    ? earningPlans.plans.slice(1, 2)
    : earningPlans.plans.filter((plan) => plan.title !== "Commission Based");

  return (
    <Wrapper>
      <div className="filter_section d-flex gap-2 flex-wrap justify-content-center align-items-center">
        <MonthlyYearlyTab
          activeFilter={activeDateFilterTab}
          setActiveFilter={setActiveDateFilterTab}
        />
        <div className="mx-auto mx-sm-0">
          <CustomSelectFilter
            filterData={filterData}
            setSelectFilter={setCurrency}
          />
        </div>
      </div>
      {currency === "USD" && (
        <div className="text-success font-weight-bold mt-2">
          {" "}
          $1 = {currentKESValueInUSD} Kshs.(Approx.)
        </div>
      )}

      <div className="d-flex flex-wrap justify-content-center gap-3 gap-md-4 my-4 my-sm-5 px-lg-4 pt-lg-3">
        {pricingPlans.map((plan) => {
          return (
            <div
              key={plan.id}
              className={`plan_card ${
                plan.title === "Luxury" && "plan_linear_bg mt-4 mt-sm-0"
              } px-1 pe-2 py-3 position-relative`}
            >
              {plan.title === "Luxury" && (
                <div className="bg-blue br-15 px-3 py-1 text-white font-20 most_popular position-absolute w-100 d-inline-block">
                  Most popular
                </div>
              )}

              <div className="text-center pt-2">
                <img src={plan.planImage} alt={plan.title} />
                <h6 className="font-weight-bold text-lighter-black mt-2">
                  {plan.title === "Daily Earning"
                    ? "Daily Earning"
                    : plan.title === "Commission Based"
                    ? "Commission Based"
                    : `${plan.title} rental plan`}
                </h6>
                <div className="text-blue font-15 px-md-3">{plan.subtitle}</div>
              </div>
              <h2 className="font-weight-bold text-light-black font-30 my-3 my-md-4 pb-2">
                {activeDateFilterTab === filterType.MONTHLY
                  ? convertUSDorKES(
                      plan.price?.monthly,
                      currency,
                      currentKESValueInUSD
                    )
                  : convertUSDorKES(
                      plan.price?.yearly,
                      currency,
                      currentKESValueInUSD
                    )}
              </h2>
              <div className="plan-features">
                {plan.features.map((feature) => (
                  <div
                    key={feature.id}
                    className="d-flex mb-3 text-dark-gray font-15"
                  >
                    <div className="dot-circle rounded-circle mt-1 mx-2" />
                    <div className="text-start d-inline-block">
                      {feature.title}
                      {feature.tooltipText && (
                        <span>
                          <OverlayTrigger
                            overlay={
                              <Popover id="popover-basic">
                                <div
                                  className="p-3"
                                  style={{
                                    color: "#7A7A7A",
                                    background: "#F3F3F3",
                                  }}
                                >
                                  <h5 className="font-weight-bold">Note</h5>
                                  <div>{feature.tooltipText}</div>
                                </div>
                              </Popover>
                            }
                            placement="top"
                          >
                            <div className="learnMoreTooltip text-blue d-inline-flex align-items-center gap-2 ml-1 px-1 cursor-pointer">
                              <AiOutlineExclamationCircle className=" cursor-pointer " />
                              <div className="">Learn more</div>
                              <MdKeyboardArrowRight className=" cursor-pointer " />
                            </div>
                          </OverlayTrigger>
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
      <div className="my-3 my-md-4 max-w-950">
        <div className="text-dark-gray mb-3">
          You can view the full agreement you will be asked to sign for each
          plan you intend to pick.
        </div>
        <a
          href="/agreement"
          target="_blank"
          rel="noreferrer"
          className="text-decoration-none"
          onClick={handleUpdateAgreementTimeRead}
        >
          <ButtonOutlineBlue
            maxWidth={"450px"}
            buttonText={"View Terms & Agreement for the plans"}
            short={""}
          />
        </a>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .plan_card {
    border: 0.3px solid #0274b3;
    border-radius: 18px;
    flex-grow: 1;
    flex-basis: 250px;
    max-width: 300px;

    h2 {
      border-bottom: 3px solid #246eb9;
      display: inline-block;
    }
    .plan-features {
      .dot-circle {
        font-size: 15px;
        background: #bedbec;
        min-width: 10px;
        height: 10px;
      }
    }
    .most_popular {
      max-width: 175px;
      transform: translate(-50%, -75%);
      top: 0;
    }
  }
  .plan_linear_bg {
    background: linear-gradient(
      172.54deg,
      #fcd9fc 1.93%,
      #fcf5dc 50.95%,
      #fafbfc 94.21%
    );
  }

  .filter_section {
    .date_filter_tab_container {
      margin: 0;
    }
  }
  .learnMoreTooltip {
    font-size: 12px;
    background: #c8ddf3;
    border-radius: 10px;
  }
`;
