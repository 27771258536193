import React from "react";
import { Link } from "react-router-dom";
import homeImage from "../../dist/images/home.png";

const HomeMain = () => {
  return (
    <div id="home-main" className="home-main row justify-content-center px-3 bg-white">
      <div className="col-lg-11 d-flex align-items-center py-4">
        <div className="row m-0 flex-wrap-reverse">
          <div className="text-center col-lg-4 col-xl-5 col-xxl-6 p-0 d-flex align-items-center ">
            <div className="content">
              <h2 className="mb-4 mb-lg-5 mt-4 font-45 line-height-3-0 text-deep-blue font-weight-900">
                Rent out your vehicle to the gig economy 
              </h2>
              <p className="text-deep-blue font-25 font-weight-900">
                We manage it for you, you earn passive income, hassle-free.
              </p>
              <Link
                to="/rent-vehicle"
                className="btn font-30 font-weight-900 px-4 bg-deep-blue text-white"
              >
                Rent out your vehicle{" "}
              </Link>
            </div>
          </div>
          <div className="content-image col-lg-8 col-xl-7 col-xxl-6 d-flex align-items-center ">
            <img src={homeImage} alt="hero" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeMain;
