import React from "react";
import styled from "styled-components";

const NumberCard = ({ data, id }) => {
  return (
    <div className="col-md-6 p-0 my-3 number-box">
      <ImageContainer image={data.image} />
      {/* <img src={data.image} height={id === 0 && "120px"} alt="cars" /> */}
      <h6 className="font-35  font-weight-900 my-4">{data.title}</h6>
      <div className="number-count-box font-35 font-weight-900">
        {data.count}
      </div>
    </div>
  );
};

export default NumberCard;

const ImageContainer = styled.div`
  height: 120px;
  width: 230px;
  background: url(${(props) => props.image}) center/cover no-repeat;
  object-fit: cover;
  position: relative;
`;
