import React from "react";
import styled from "styled-components";
import CustomStyledButton from "../common/CustomStyledButton";
import { useNavigate } from "react-router-dom";

export default function GotoRentVehicle() {
  const navigate = useNavigate();

  return (
    <Wrapper className="mb-4 mb-md-5 px-3 py-4 py-md-5 d-flex flex-column justify-content-center align-items-center text-white">
      <div>
        <CustomStyledButton
          title="Go to the 3-step “Rent-out-your-vehicle” page to onboard car or sign up"
          classNames="bg-steel-blue text-white font-23 font-weight-bold"
          onClick={() => navigate("/rent-vehicle")}
          hasRightIcon
        />
        <p className="text-white text-center font-17 mt-3 mt-md-4 max-w-700">
          For efficiency and for our zeal to provide stellar services, we only
          accept a limited number of cars per month. Make sure, on the 3rd step
          of “Rent-out-your-vehicle” page, you provide your contact details and
          tap <strong>“Prioritize me”</strong> for us to prioritize you on our
          waitlist.
        </p>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: #2c2923;
  border: 0.5px solid #998d8d;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
`;
