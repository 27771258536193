import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../dist/images/logo.png";
import queryString from "query-string";
import { resetPassword } from "../../redux/actions/auth_actions";

const AdminResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [resetting, setResetting] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const parsedString = queryString.parse(location.search);

  const [data, setData] = useState({
    resetCode: "",
    password: "",
    confirmPassword: "",
    role: "ADMIN",
  });

  const token = useSelector(
    (state) => state.auth_reducers.auth_credentials.token
  );

  // const authError = useSelector((state) => state.auth_reducers.error);

  if (token) {
    navigate("/admin/dashboard");
  }

  useEffect(() => {
    if (parsedString.code) {
      setData({ ...data, resetCode: parsedString.code });
    }
  }, [data, parsedString.code]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleResetPassword = async (e) => {
    try {
      e.preventDefault();

      if (data && data.password && data.password.length < 8) {
        throw Error("Password must be atleast 8 characters");
      } else if (data && data.password !== data.confirmPassword) {
        throw Error("Passwords do not match");
      } else if (data) {
        setResetting(true);
        const response = await dispatch(resetPassword(data));

        if (response.response_code !== 200) {
          throw Error(response.response_data.split("_").join(" "));
        } else if (response) {
          setSuccess("Password reset successfully");

          setData({
            resetCode: "",
            password: "",
            confirmPassword: "",
            role: "ADMIN",
          });

          setTimeout(() => {
            navigate("/admin/dashboard");
          }, 7000);
        }
        setResetting(false);
      }

      setTimeout(() => {
        setError("");
        setSuccess("");
      }, 7000);
    } catch (err) {
      setError(err.message);

      setTimeout(() => {
        setError("");
        setSuccess("");
      }, 7000);
    }
  };

  return (
    <div className="row admin-reset-pasword admin-forgot-password min-height-100">
      <div className="col-md-6  admin-forgot-password-left"></div>
      <div className="col-md-6">
        <div className="admin-forgot-password-right bg-blue ">
          <form
            className="d-flex align-items-center justify-content-center flex-column"
            onSubmit={handleResetPassword}
          >
            <p>
              <img
                src={logo}
                alt="Fleetsimplify Logo"
                className="cursor-pointer logo"
                onClick={() => navigate("/")}
              />
            </p>
            <h2 className="title  my-3 text-uppercase text-white">
              Reset Password
            </h2>
            {error && (
              <p className="error-box my-3">
                {error && error[0].toUpperCase() + error.slice(1).toLowerCase()}
              </p>
            )}
            {success && (
              <p className="success-box my-3 text-capitalize">{success}</p>
            )}
            {/* <div className="form-group">
              <span className="fa fa-user"></span>
              <input
                type="email"
                name="email"
                id="email"
                className="form-control"
                autoComplete="off"
                placeholder="Email address"
              />
            </div> */}
            <div className="form-group">
              <span className="fa fa-unlock-alt"></span>
              <input
                type="password"
                name="password"
                id="password"
                className="form-control"
                autoComplete="off"
                placeholder="New Password"
                value={data && data.password}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <span className="fa fa-unlock-alt"></span>
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                className="form-control"
                autoComplete="off"
                placeholder="Confirm New Password"
                onChange={handleChange}
                value={data && data.confirmPassword}
              />
            </div>

            <button
              type="submit"
              className="btn btn-outline-blue my-3"
              onClick={handleResetPassword}
            >
              {resetting ? "Resetting..." : "Reset Password"}
            </button>
            <Link to="/admin/login" className="f-password text-white">
              <span className="fa fa-long-arrow-left"></span> Back to Login
            </Link>
          </form>
        </div>
      </div>
      {/* <AdminCoverSide /> */}
    </div>
  );
};

export default AdminResetPassword;
