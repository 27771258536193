import React from 'react'
import { MdAccessTime } from 'react-icons/md'
import styled from 'styled-components'
import ArticleCategoryBatch from './ArticleCategoryBatch'

export default function ArticleOverview({
  articleImage,
  isTopArticle,
  readingTime,
  title,
  desc,
  category
}) {
  return (
    <Wrapper
      imgUrl={articleImage}
      className="article_overview d-flex flex-column justify-content-end br-5 text-white px-3 px-md-4 py-4 cursor-pointer"
    >
      {title && (
        <div className="d-flex justify-content-between align-items-center gap-2">
          <h3 className={`${isTopArticle ? 'font-25' : 'font-18'} font-weight-normal mb-0`}>{title}</h3>
          {!isTopArticle && <ArticleCategoryBatch title={category} />}
        </div>
      )}
      {isTopArticle ?
        <p className="font-18">{desc}</p>
        :
        readingTime ?
          <div className="font-15"><MdAccessTime className='font-20 mb-1' /> {readingTime}min read</div>
          : null
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  min-height: 400px;
  max-height: 400px;
  background-image:
    linear-gradient(177deg, rgba(77, 77, 77, 0.00) 43.7%, #000 109.23%),
    url(${(props) => props.imgUrl});
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
`