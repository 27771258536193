export const pricingType = {
  MONTHLY_EARNING: 'monthly-earning',
  MANAGEMENT_PLAN: 'management-plan',
}

export const filterType = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
}
export const mediaType = {
  VIDEOS: 'videos',
  PHOTOS: 'photos',
}

export const rentalPlanType = {
  LUXURY: 'luxury',
  SECURE: 'secure',
  BASIC: 'basic',
}