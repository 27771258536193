import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppWrapper from "./AppWrapper";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import ScrollToTop from "./Util/ScrollToTop";
import { useState } from "react";
import InitializeGoogleAnalytics from "./Util/google-analytics";

const App = () => {
  useState(() => {
    InitializeGoogleAnalytics();
  });

  return (
    <Router>
      <ScrollToTop>
        <div id="main">
          <div id="content">
            <Header />
            <AppWrapper />
          </div>
          <Footer />
        </div>
      </ScrollToTop>
    </Router>
  );
};

export default App;
