import React from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import VehicleForm from "../../components/Admin/VehicleForm";
import { useNavigate } from "react-router-dom";

const AddVehicles = () => {
  const navigate = useNavigate();

  const handleRouteBack = () => {
    let route = "/admin/vehicles";
    navigate(route);
  };

  return (
    <Wrapper className="col-xl-11 mx-auto admin-faqs ">
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <Button
            variant="warning"
            className="my-auto mr-3"
            onClick={handleRouteBack}
          >
            <i className="fa fa-arrow-left mr-1"></i> Back
          </Button>
          <h2 className="font-weight-bold my-4">{`Vehicles > Add A Vehicle `}</h2>
        </div>
      </div>
      <div className="w-100 bg-white min-height-50 content-box py-4 px-5">
        <VehicleForm formType="add" />
      </div>
    </Wrapper>
  );
};

export default AddVehicles;

const Wrapper = styled.div`
  .content-box {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.23);
    border-radius: 3px;
  }
`;
