import * as vehicle_types from "../../types/vehicle_types";
import { PostRoute } from "../../../api";
import { getAuthConfig } from "../../../Util/Util";

const { Get, Post, Patch, Delete } = PostRoute();

export const createVehicleMake = (data, token) => async (dispatch) => {
  try {
    const config = getAuthConfig(token);

    dispatch({
      type: vehicle_types.CREATE_VEHICLE_MAKE_REQUEST,
    });

    const response = await Post("admin/vehicle/create-make", data, config);

    // if (response.data.response_code !== 200) {
    //   throw new Error(response.data.response_data.message);
    // }

    if (response.data.response_code === 200) {
      dispatch({
        type: vehicle_types.CREATE_VEHICLE_MAKE_SUCCESS,
        payload: response.data.response_data,
      });
    }

    return response.data;
  } catch (err) {
    dispatch({
      type: vehicle_types.CREATE_VEHICLE_MAKE_ERROR,
      payload: err.message,
    });
  }
};

export const getVehicleMakes = () => async (dispatch) => {
  try {
    dispatch({
      type: vehicle_types.GET_VEHICLE_MAKES_REQUEST,
    });

    const response = await Get("admin/vehicle-make");

    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    dispatch({
      type: vehicle_types.GET_VEHICLE_MAKES_SUCCESS,
      payload: response.data.response_data,
    });

    return response.data;
  } catch (err) {
    dispatch({
      type: vehicle_types.GET_VEHICLE_MAKES_ERROR,
      payload: err.message,
    });
  }
};

export const deleteVehicleMake = (vehicleMakeId, token) => async (dispatch) => {
  try {
    const config = getAuthConfig(token);

    dispatch({
      type: vehicle_types.DELETE_VEHICLE_MAKE_REQUEST,
    });

    const response = await Delete(
      `admin/vehicle-make/${vehicleMakeId}`,
      config
    );

    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    dispatch({
      type: vehicle_types.DELETE_VEHICLE_MAKE_SUCCESS,
      payload: vehicleMakeId,
    });

    return response.data;
  } catch (err) {
    dispatch({
      type: vehicle_types.DELETE_VEHICLE_MAKE_ERROR,
      payload: err.message,
    });
  }
};

export const editVehicleMake = (vehicleId, data, token) => async (dispatch) => {
  try {
    const config = getAuthConfig(token);

    dispatch({
      type: vehicle_types.EDIT_VEHICLE_MAKE_REQUEST,
    });

    const response = await Patch(
      `admin/vehicle-make/${vehicleId}`,
      data,
      config
    );

    // if (response.data.response_code !== 200) {
    //   throw new Error(response.data.response_data.message);
    // }

    if (response.data.response_code === 200) {
      dispatch({
        type: vehicle_types.EDIT_VEHICLE_MAKE_SUCCESS,
        payload: response.data.response_data,
      });
    }

    return response.data;
  } catch (err) {
    dispatch({
      type: vehicle_types.EDIT_VEHICLE_MAKE_ERROR,
      payload: err.message,
    });
  }
};

export const getAllVehicles = () => async (dispatch) => {
  try {
    dispatch({
      type: vehicle_types.GET_ALL_VEHICLES_REQUEST,
    });

    const response = await Get("admin/vehicle");

    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    dispatch({
      type: vehicle_types.GET_ALL_VEHICLES_SUCCESS,
      payload: response.data.response_data,
    });

    return response.data.response_data;
  } catch (err) {
    dispatch({
      type: vehicle_types.GET_ALL_VEHICLES_ERROR,
      payload: err.message,
    });
  }
};

export const getSingleVehicle = (vehicleId) => async (dispatch) => {
  try {
    dispatch({
      type: vehicle_types.GET_VEHICLE_REQUEST,
    });

    const response = await Get(`admin/vehicle/view/${vehicleId}`);

    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    dispatch({
      type: vehicle_types.GET_VEHICLE_SUCCESS,
      payload: response.data.response_data,
    });

    return response.data.response_data;
  } catch (err) {
    dispatch({
      type: vehicle_types.GET_VEHICLE_ERROR,
      payload: err.message,
    });
  }
};

export const deleteVehicle = (vehicleId, token) => async (dispatch) => {
  try {
    const config = getAuthConfig(token);

    dispatch({
      type: vehicle_types.DELETE_VEHICLE_REQUEST,
    });

    const response = await Delete(`admin/vehicle/${vehicleId}`, config);

    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    const res = await Get("/admin/vehicle");

    dispatch({
      type: vehicle_types.DELETE_VEHICLE_SUCCESS,
      data: res.data.response_data,
    });
  } catch (err) {
    dispatch({
      type: vehicle_types.DELETE_VEHICLE_ERROR,
      data: err.message,
    });
  }
};
