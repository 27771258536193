import React from "react";
import HomeMain from "../components/Home/HomeMain";
import HowItWorks from "../components/Home/HowItWorks";
import Benefits from "../components/Home/Benefits";
import Numbers from "../components/Home/Numbers";
import PlatformAgnostic from "../components/Home/PlatformAgnostic";
import Technologies from "../components/Home/Technologies";
import AccoladesBackers from "../components/Home/AccoladesBackers";

const Home = () => {
  return (
    <div className="home page-height">
      <div className="section">
        <HomeMain />
      </div>
      <div className="section">
        <HowItWorks />
      </div>
      <div className="section">
        <Benefits />
      </div>
      <div className="section">
        <Technologies />
      </div>
      <div className="section">
        <Numbers />
      </div>
      <div className="section">
        <PlatformAgnostic />
      </div>
      <div className="section">
        <AccoladesBackers />
      </div>
    </div>
  );
};

export default Home;
