import * as faq_types from "../../types/faq_types";
import { PostRoute } from "../../../api";
import { getAuthConfig } from "../../../Util/Util";

const { Get, Post, Patch, Delete } = PostRoute();

export const fetchFaq = (id) => async (dispatch) => {
  try {
    let response = await Get(`faq/${id}`);
    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    return response.data.response_data;
  } catch (error) {
    console.log(error);
    dispatch({ type: faq_types.FAQ_FETCH_ERROR, data: error });
  }
};

export const fetchFaqs = () => async (dispatch) => {
  try {
    let response = await Get(`faq`);
    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    return response.data.response_data;
  } catch (error) {
    console.log(error);
    dispatch({ type: faq_types.FAQS_FETCH_ERROR, data: error });
  }
};

export const createFaq = (token, data) => async (dispatch) => {
  try {
    const config = getAuthConfig(token);
    let response = await Post(`admin/faq/create`, data, config);
    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    return response.data.response_data;
  } catch (error) {
    console.log(error);
    dispatch({ type: faq_types.FAQ_CREATE_ERROR, data: error });
  }
};

export const editFaq = (token, id, data) => async (dispatch) => {
  try {
    const config = getAuthConfig(token);
    let response = await Patch(`admin/faq/${id}`, data, config);
    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    return response.data.response_data;
  } catch (error) {
    console.log(error);
    dispatch({ type: faq_types.FAQ_EDIT_ERROR, data: error });
  }
};

export const deleteFaq = (token, id) => async (dispatch) => {
  try {
    const config = getAuthConfig(token);
    let response = await Delete(`admin/faq/${id}`, config);
    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    return response.data.response_data;
  } catch (error) {
    console.log(error);
    dispatch({ type: faq_types.FAQ_DELETE_ERROR, data: error });
  }
};
