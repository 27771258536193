import React from "react";
import { useParams } from "react-router-dom";
import AdminForm from "./AdminForm/AdminForm";

const EditAdminDetails = () => {
  const params = useParams();

  const adminId = params.adminId;
  return (
    <div className="edit-admin-details">
      <AdminForm formType="edit" adminId={adminId} />
    </div>
  );
};

export default EditAdminDetails;
