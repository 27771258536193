import * as application_types from "../../types/application_types";

const initialState = {
  loading: false,
  error: "",
  success: "",
  bulkApplication: {},
  bulkApplications: [],
  singleApplication: {},
  singleApplications: [],
  waitingListApplication: {},
  waitingListApplications: [],
  applicationProgress: {
    step1: {
      vehicleMake: "",
      vehicleModel: "",
      vehicleManufactureYear: "",
      vehicleCC: "",
    },
    step2: {
      agreementTimeRead: "",
      earningPlans: {
        doHaveCar: "",
        rentalPeriod: "",
        rentalPlan: "",
      },
    },
    step3: {
      fullname: "",
      email: "",
      mobileNumber: "",
      priority: "",
      clickedAppLink: false,
    },
    currency: 1,
  },
  uploading: false,
  progress: 0,
};

export const application_reducers = (state = initialState, action) => {
  switch (action.type) {
    //POST REQUESTS
    case application_types.CREATE_SINGLE_APPLICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case application_types.CREATE_SINGLE_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        singleApplication: action.payload,
        singleApplications: [action.payload, ...state.singleApplications],
      };

    case application_types.CREATE_SINGLE_APPLICATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case application_types.CREATE_BULK_APPLICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case application_types.CREATE_BULK_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        bulkApplication: action.payload,
        bulkApplications: [action.payload, ...state.bulkApplications],
      };

    case application_types.CREATE_BULK_APPLICATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case application_types.CREATE_WAITINGLIST_APPLICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case application_types.CREATE_WAITINGLIST_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        waitingListApplication: action.payload,
        waitingListApplications: [
          action.payload,
          ...state.waitingListApplications,
        ],
      };

    case application_types.CREATE_WAITINGLIST_APPLICATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    //  GET REQUESTS
    case application_types.GET_SINGLE_APPLICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case application_types.GET_SINGLE_APPLICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        singleApplications: action.payload,
      };

    case application_types.GET_SINGLE_APPLICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case application_types.GET_BULK_APPLICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case application_types.GET_BULK_APPLICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        bulkApplications: action.payload,
      };

    case application_types.GET_BULK_APPLICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case application_types.GET_WAITINGLIST_APPLICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case application_types.GET_WAITINGLIST_APPLICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        waitingListApplications: action.payload,
      };

    case application_types.GET_WAITINGLIST_APPLICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case application_types.PERSIST_PROGRESS_DATA:
      return {
        ...state,
        applicationProgress: {
          ...state.applicationProgress,
          [action.data.step]: action.data.payload,
        },
      };

    case application_types.UNPERSIST_PROGRESS_DATA:
      return {
        ...state,
        applicationProgress: {
          step1: {
            vehicleMake: "",
            vehicleModel: "",
            vehicleManufactureYear: "",
          },
          step2: {
            agreementTimeRead: "",
            earningPlans: {
              doHaveCar: "",
              rentalPeriod: "",
              rentalPlan: "",
            },
          },
          step3: {
            fullname: "",
            email: "",
            mobileNumber: "",
            priority: "",
            clickedAppLink: false,
          },
        },
      };

    case application_types.UNSET_APPLICATION_DATA:
      return {
        state,
        applicationProgress: {
          ...state.applicationProgress,
          step1: {
            ...state.applicationProgress.step1,
            vehicleMake: action.data,
            vehicleModel: "",
            vehicleCC: "",
            vehicleManufactureYear: "",
          },
        },
      };

    case application_types.PERSIST_CURRENCY:
      return {
        state,
        applicationProgress: {
          ...state.applicationProgress,
          currency: action.data,
        },
      };

    case application_types.PERSIST_UPLOADS_PROGRESS_DATA:
      return {
        state,
        applicationProgress: {
          ...state.applicationProgress,
          ...action.data,
        },
      };

    case application_types.SET_UPLOADING:
      return {
        state,
        uploading: action.data,
      };

    case application_types.SET_UPLOAD_PROGRESS:
      return {
        state,
        progress: action.data,
      };

    default:
      return {
        ...state,
      };
  }
};
