import React from "react";
import styled, { css } from "styled-components";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  setActiveInquiry,
  setInquiryRead,
  checkInquiry,
  unCheckInquiry,
} from "../../../redux/actions/inquiry_actions";

import { dateUtil } from "../../../Util/DateUtil";

function InquiryBox(props) {
  const activeInquiry = useSelector((state) => state.inquiry_reducers.inquiry);
  const dispatch = useDispatch();
  const token = useSelector(
    (state) => state.auth_reducers.auth_credentials.token
  );

  const handleBoxClick = (e, data) => {
    dispatch(setActiveInquiry(data));
    if (!data.read) {
      dispatch(setInquiryRead(token, data));
    }
  };

  const handleCheck = (e, data) => {
    dispatch(checkInquiry(data));
  };

  const handleUnCheck = (e, data) => {
    dispatch(unCheckInquiry(data));
  };

  return (
    <Wrapper
      className="py-3 px-2 my-3"
      data={props.data}
      activeInquiry={activeInquiry}
    >
      <Row className="align-items-center">
        <Col xs={2}>
          {props.data && props.data.selected ? (
            <span
              className="text-center"
              onClick={(e) => handleUnCheck(e, props.data)}
            >
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="19" height="19" rx="3" fill="#595959" />
                <path
                  d="M4 9.5L8.5 14L16 5"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          ) : (
            <span
              className="text-center"
              onClick={(e) => handleCheck(e, props.data)}
            >
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="19" height="19" rx="3" fill="#C4C4C4" />
              </svg>
            </span>
          )}
        </Col>
        <Col xs={6} onClick={(e) => handleBoxClick(e, props.data)}>
          <NameWrapper className="m-0" read={props.data && props.data.read}>
            {props.data && props.data.fullname}
          </NameWrapper>
        </Col>
        <Col xs={4} onClick={(e) => handleBoxClick(e, props.data)}>
          <ParagraphWrapper
            className="m-0"
            read={props.data && props.data.read}
          >
            {props.data &&
              dateUtil(new Date(props.data.createdAt).toLocaleString())}
          </ParagraphWrapper>
        </Col>
      </Row>
    </Wrapper>
  );
}

export default InquiryBox;

const Wrapper = styled.div`
  border:${(props) =>
    props.data._id === props.activeInquiry._id
      ? "0.5px solid #FFBB00;"
      : "0.5px solid #d4d4d4;"}
  border-radius: 10px;
  background-color: ${(props) => (props.data.read ? "#F9F8F8" : "#E8F5F9")};
  color: #969393;
  cursor:pointer;
  ${(props) =>
    props.data.selected &&
    css`
      border: 1px solid #000 !important;
    `}
`;

const NameWrapper = styled.h6`
  color: ${(props) => (props.read ? "#969393" : "#000")};
  ${(props) =>
    !props.read &&
    css`
      font-weight: bold;
    `}
`;

const ParagraphWrapper = styled.p`
  color: ${(props) => (props.read ? "#969393" : "#279F2C")};
  font-weight: light;
`;
