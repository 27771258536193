import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TobApplicationBar from "../../../components/Applications/TobApplicationBar";
import Loading from "../../../components/Loading";
import { getSingleApplications } from "../../../redux/actions/application_actions";
import { ExportToExcel, FlattenArray } from "../../../Util/helpers";

const Applications = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchWord, setSearchWord] = useState("");

  const dispatch = useDispatch();

  const token = useSelector(
    (state) => state.auth_reducers.auth_credentials.token
  );

  useEffect(() => {
    const getApplications = async () => {
      try {
        setLoading(true);
        await dispatch(getSingleApplications(token));

        setLoading(false);
      } catch (err) {
        console.log(err.message);
        setLoading(false);
      }
    };

    getApplications();
  }, [token, dispatch]);

  const singleApplications = useSelector(
    (state) => state.application_reducers.singleApplications
  );

  useEffect(() => {
    if (singleApplications) {
      setData(singleApplications);
    }
  }, [singleApplications]);

  useEffect(() => {
    if (singleApplications && searchWord && searchWord.length > 0) {
      let tempData = [...singleApplications];
      const word = searchWord.trim().toLowerCase().replace(/\s+/g, " ");

      if (word.length > 0) {
        // eslint-disable-next-line array-callback-return
        tempData = tempData.filter((item) => {
          if (
            item.fullname.trim().toLowerCase().includes(word) ||
            item.vehicleMake.trim().toLowerCase().includes(word) ||
            item.vehicleModel.trim().toLowerCase().includes(word)
          ) {
            return true;
          }
        });

        setData(tempData);
      }
    } else {
      setData(singleApplications);
    }
  }, [searchWord, singleApplications]);

  const handleDownloadClick = () => {
    const flattenedData = FlattenArray(data);
    const timestamp = new Date();
    if (flattenedData) {
      ExportToExcel(
        flattenedData,
        `Rent_Vehicle_Applications_${timestamp.toISOString()}`
      );
    }
    return;
  };

  return (
    <div className="applications p-3">
      <TobApplicationBar
        title="Single Applications"
        seachWord={searchWord}
        setSearchWord={setSearchWord}
        handleDownloadClick={handleDownloadClick}
      />

      {loading ? (
        <Loading />
      ) : (
        <div className="row ">
          <div className="col-lg-12">
            <div className="table-responsive position-relative max-height-80">
              <table className="table table-hover" id="customers">
                <thead>
                  <tr>
                    <th className="bg-dark-white border-right-gray px-3"></th>
                    <th
                      colSpan="3"
                      className="bg-dark-white text-center border-right-gray px-3"
                    >
                      Partner Details
                    </th>
                    <th
                      colSpan="3"
                      className="bg-dark-white text-center border-right-gray px-3"
                    >
                      Vehicle Details
                    </th>
                    <th
                      colSpan="4"
                      className="bg-dark-white text-center border-right-gray px-3"
                    >
                      3 Questions
                    </th>
                    <th className="bg-dark-white border-right-gray text-center px-3">
                      Time of submission
                    </th>
                    <th className="bg-dark-white border-right-gray text-center px-3">
                      Action
                    </th>
                  </tr>
                  <tr>
                    <th
                      className="bg-dark-white min-width-35 d-flex align-items-center justify-content-center"
                      rowSpan="2"
                    >
                      #
                    </th>
                    <th className="application-top bg-blue ">Name</th>
                    <th className="application-top bg-blue">Email</th>
                    <th className="application-top bg-blue">Mobile No</th>
                    <th className="application-top bg-red">Make</th>
                    <th className="application-top bg-red">Model</th>
                    <th className="application-top bg-red">
                      Year Of Manufacture
                    </th>
                    <th className="application-top bg-dark">Car: Own or Buy</th>
                    <th className="application-top bg-dark">Rental duration</th>
                    <th className="application-top bg-dark">
                      Rental plan desired
                    </th>
                    <th className="application-top bg-dark">Priority</th>
                    <th className="application-top bg-success">Timestamp</th>
                    <th className="application-top bg-pink">
                      Did they click app link?
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0 ? (
                    <>
                      {data &&
                        data.map((application, index) => (
                          <tr key={application._id}>
                            <th className="applications-col col-light-blue min-width-35 text-center">
                              {index + 1}
                            </th>
                            <td className="applications-col col-white">
                              {application.fullname}
                            </td>
                            <td className="applications-col col-white">
                              {application.email}
                            </td>
                            <td className="applications-col col-white">
                              {application.mobileNumber}
                            </td>
                            <td className="applications-col col-light-blue">
                              {application.vehicleMake}
                            </td>
                            <td className="applications-col col-light-blue">
                              {application.vehicleModel}
                            </td>
                            <td className="applications-col col-light-blue">
                              {application.vehicleManufactureYear}
                            </td>
                            <td className="applications-col col-medium-blue">
                              {application?.earningPlans?.doHaveCar === "Buy"
                                ? "I intend to buy"
                                : "I own car"}
                            </td>
                            <td className="applications-col col-medium-blue">
                              {application?.earningPlans?.rentalPeriod}
                            </td>
                            <td className="applications-col col-medium-blue">
                              {application?.earningPlans?.rentalPlan} rental
                              plan
                            </td>
                            <td className="applications-col col-medium-blue">
                              {application?.priority}
                            </td>
                            <td className="applications-col col-white  text-blue">
                              {new Date(application.createdAt).toLocaleString()}
                            </td>
                            <td className="applications-col col-light-blue text-blue px-5">
                              {application?.clickedAppLink ?? "No"}
                            </td>
                          </tr>
                        ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="11" className="text-center ">
                        <div className="min-height-20 d-flex align-items-center justify-content-center">
                          {" "}
                          No current applications
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Applications;

// const OtherInfoTable = ({ show, id, activeId, setShow }) => {
//   return (
//     <div
//       className={`table-responsive more-info-table ${show && activeId === id && "show"
//         }`}
//     >
//       <div className="position-relative">
//         <h3 className="text-center">Earnings Breakdown</h3>
//         <span className="close-icon" onClick={() => setShow(false)}>
//           X
//         </span>
//       </div>

//       <table className="table table-hover" id="customers">
//         <thead>
//           <tr>
//             <th className="application-top bg-blue ">Target</th>
//             <th className="application-top bg-blue ">Work days</th>
//             <th className="application-top bg-blue ">Revenue/M</th>
//             <th className="application-top bg-blue ">Vehicle Expenses</th>
//             <th className="application-top bg-blue ">H Commission</th>
//             <th className="application-top bg-blue ">Net Earnings </th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td className="applications-col col-white">Kshs. 1500</td>
//             <td className="applications-col col-white">26</td>
//             <td className="applications-col col-white">Kshs. 39,000</td>
//             <td className="applications-col col-white">Kshs. 3000</td>
//             <td className="applications-col col-white">Kshs. 3,000</td>
//             <td className="applications-col col-white">Kshs. 32,000</td>
//           </tr>
//         </tbody>
//       </table>
//     </div>
//   );
// };
