import React, { useEffect } from "react";
import { mediaType } from "../../Util/types";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "../../Util/useQuery";
import styled from "styled-components";
import { TbPhoto } from "react-icons/tb";
import { IoIosVideocam } from "react-icons/io";

export default function MediaTab({ activeMedia, setActiveMedia, callback }) {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();

  const addQuery = (key, value) => {
    let pathname = location.pathname;
    let searchParams = new URLSearchParams(location.search);
    searchParams.set(key, value);
    navigate({
      pathname: pathname,
      search: searchParams.toString(),
    });
  };

  useEffect(() => {
    const media = query.get("media");
    setActiveMedia(media ?? mediaType.VIDEOS);
  }, [query, setActiveMedia]);

  return (
    <TabWrapper maxWidth="300px" className="media_tab_container">
      <div className="media_tab d-flex gap-3">
        <button
          type="button"
          className={`tab border-0 px-4 py-1 text-center ${
            activeMedia === mediaType.VIDEOS && "tab_active"
          }`}
          onClick={() => {
            setActiveMedia(mediaType.VIDEOS);
            callback();
            addQuery("media", mediaType.VIDEOS);
          }}
        >
          <IoIosVideocam className="icon-30 mb-1" /> Videos
        </button>
        <button
          type="button"
          className={`tab border-0 px-4 py-1 text-center ${
            activeMedia === mediaType.PHOTOS && "tab_active"
          }`}
          onClick={() => {
            setActiveMedia(mediaType.PHOTOS);
            callback();
            addQuery("media", mediaType.PHOTOS);
          }}
        >
          <TbPhoto className="icon-30 mb-1" /> Photos
        </button>
      </div>
    </TabWrapper>
  );
}

export const TabWrapper = styled.div`
  max-width: ${(props) => props.maxWidth && props.maxWidth};
  margin: 0 auto;

  .media_tab {
    min-height: 46px;
    .tab {
      border-radius: 20px;
      background: #e0e0e0;
      color: #5c5858;
      font-weight: bold;
    }
    .tab_active {
      background: var(--blue) !important;
      color: #ffffff;
    }
  }
`;
