import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import InquiryBoxContainer from "../../components/Admin/Inquiries/InquiryBoxContainer";
import MessageBox from "../../components/Admin/Inquiries/MessageBox";
import DeleteModal from "../../components/Admin/Inquiries/DeleteModal";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllInquiries,
  applyFilters,
  removeFilters,
} from "../../redux/actions/inquiry_actions";
import Loading from "../../components/Loading";

function Inquiries(props) {
  const [deleteShow, setDeleteShow] = useState(false);
  const [showFilter, setShowFilter] = useState();
  const [filter1, setFilter1] = useState(null);
  const [filter2, setFilter2] = useState(null);
  const activeInquiry = useSelector((state) => state.inquiry_reducers.inquiry);
  const toDelete = useSelector((state) => state.inquiry_reducers.toDelete);
  const dispatch = useDispatch();

  const token = useSelector(
    (state) => state.auth_reducers.auth_credentials.token
  );

  const fetchingInquiries = useSelector(
    (state) => state.inquiry_reducers.fetching
  );

  let inquiries = useSelector((state) => state.inquiry_reducers.inquiries);

  const filters = useSelector((state) => state.inquiry_reducers.filters);

  useEffect(() => {
    const getData = async () => {
      await dispatch(getAllInquiries(token));
    };

    getData();
  }, [dispatch, token]);

  const handleFilter1Change = (e) => {
    setFilter1(e.target.value);
  };

  const handleFilter2Change = (e) => {
    setFilter2(e.target.value);
  };

  const handleApplyFilters = (e) => {
    e.preventDefault();
    const filters = [];
    if (filter1) {
      filters.push(filter1);
    }

    if (filter2) {
      filters.push(filter2);
    }

    if (filters.length > 0) {
      dispatch(applyFilters(filters));
      setFilter1(null);
      setFilter2(null);
    } else {
      return;
    }
  };

  const handleRemoveFilters = async (e) => {
    e.preventDefault();
    dispatch(removeFilters());
    // await dispatch(getAllInquiries(token));
  };

  useEffect(() => {}, [toDelete]);

  const handleFilterClick = (e) => {
    setShowFilter(!showFilter);
  };

  if (fetchingInquiries) {
    return <Loading />;
  }

  // if (filters.length > 0) {
  //   inquiries = filteredInquiries;
  // } else {
  //   inquiries = inquiries;
  // }
  if (inquiries && inquiries.length === 0) {
    return (
      <div className="page-height d-flex align-items-center justify-content-center">
        No inquiries
      </div>
    );
  }

  return (
    <Wrapper>
      <DeleteModal show={deleteShow} action={setDeleteShow} />
      <Row>
        <LeftSection lg={5} className="pt-3">
          <HeaderLeftSection>
            <h3 className="text">Inquiries</h3>
            <div className="actions">
              <div>
                {toDelete.length > 0 ? (
                  <span
                    className="delete m-0 p-0"
                    onClick={() => {
                      setDeleteShow(!deleteShow);
                    }}
                  >
                    <svg
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.48 3.42H14.44V1.52C14.44 0.681625 13.7584 0 12.92 0H5.32C4.48163 0 3.8 0.681625 3.8 1.52V3.42H0.76C0.339625 3.42 0 3.75962 0 4.18V4.94C0 5.0445 0.0855 5.13 0.19 5.13H1.6245L2.21113 17.5513C2.24913 18.3611 2.91887 19 3.72875 19H14.5112C15.3235 19 15.9909 18.3635 16.0289 17.5513L16.6155 5.13H18.05C18.1545 5.13 18.24 5.0445 18.24 4.94V4.18C18.24 3.75962 17.9004 3.42 17.48 3.42ZM12.73 3.42H5.51V1.71H12.73V3.42Z"
                        fill="#787878"
                      />
                    </svg>
                  </span>
                ) : null}
              </div>
              <span className="filter">
                <span onClick={handleFilterClick}>
                  <svg
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13.5 27C17.0804 27 20.5142 25.5777 23.0459 23.0459C25.5777 20.5142 27 17.0804 27 13.5C27 9.91958 25.5777 6.4858 23.0459 3.95406C20.5142 1.42232 17.0804 0 13.5 0C9.91958 0 6.4858 1.42232 3.95406 3.95406C1.42232 6.4858 0 9.91958 0 13.5C0 17.0804 1.42232 20.5142 3.95406 23.0459C6.4858 25.5777 9.91958 27 13.5 27ZM5.90625 8.4375C5.68247 8.4375 5.46786 8.52639 5.30963 8.68463C5.15139 8.84286 5.0625 9.05747 5.0625 9.28125C5.0625 9.50503 5.15139 9.71964 5.30963 9.87787C5.46786 10.0361 5.68247 10.125 5.90625 10.125H21.0938C21.3175 10.125 21.5321 10.0361 21.6904 9.87787C21.8486 9.71964 21.9375 9.50503 21.9375 9.28125C21.9375 9.05747 21.8486 8.84286 21.6904 8.68463C21.5321 8.52639 21.3175 8.4375 21.0938 8.4375H5.90625ZM8.4375 14.3438C8.4375 14.12 8.52639 13.9054 8.68463 13.7471C8.84286 13.5889 9.05747 13.5 9.28125 13.5H17.7188C17.9425 13.5 18.1571 13.5889 18.3154 13.7471C18.4736 13.9054 18.5625 14.12 18.5625 14.3438C18.5625 14.5675 18.4736 14.7821 18.3154 14.9404C18.1571 15.0986 17.9425 15.1875 17.7188 15.1875H9.28125C9.05747 15.1875 8.84286 15.0986 8.68463 14.9404C8.52639 14.7821 8.4375 14.5675 8.4375 14.3438ZM11.8125 19.4062C11.8125 19.1825 11.9014 18.9679 12.0596 18.8096C12.2179 18.6514 12.4325 18.5625 12.6562 18.5625H14.3438C14.5675 18.5625 14.7821 18.6514 14.9404 18.8096C15.0986 18.9679 15.1875 19.1825 15.1875 19.4062C15.1875 19.63 15.0986 19.8446 14.9404 20.0029C14.7821 20.1611 14.5675 20.25 14.3438 20.25H12.6562C12.4325 20.25 12.2179 20.1611 12.0596 20.0029C11.9014 19.8446 11.8125 19.63 11.8125 19.4062Z"
                      fill="#3B3B3B"
                    />
                  </svg>
                </span>
                {showFilter ? (
                  <div className="filter-wrapper">
                    <div className="filter-header d-flex align-items-center">
                      <h6 className="pl-4 mt-2" style={{ fontWeight: "bold" }}>
                        Filters
                      </h6>
                    </div>
                    <div className="p-4">
                      <div>
                        <select onChange={handleFilter1Change}>
                          <option value="">Message statuses</option>
                          <option value="read">Read messages</option>
                          <option value="unread"> Unread messages</option>
                        </select>
                      </div>
                      <div>
                        <select onChange={handleFilter2Change}>
                          <option value="">Time of messages</option>
                          <option value="last-hour">Last hour</option>
                          <option value="12hrs">12 hours ago</option>{" "}
                          <option value="today">Today</option>{" "}
                          <option value="week">This week</option>{" "}
                          <option value="month">This Month</option>
                          <option value="year">This year</option>
                        </select>
                      </div>

                      <button
                        className="btn btn-md apply-filters"
                        onClick={handleApplyFilters}
                      >
                        Apply Filters
                      </button>
                      {filters.length > 0 && (
                        <span
                          className="ml-2"
                          style={{ cursor: "pointer" }}
                          onClick={handleRemoveFilters}
                        >
                          Clear Filters
                        </span>
                      )}
                    </div>
                  </div>
                ) : null}
              </span>
            </div>
          </HeaderLeftSection>
          <InquiryBoxContainer />
        </LeftSection>
        <RightSection lg={7} className="pt-3">
          <div style={{ height: "50px" }} />
          <RightSectionInnerWrapper>
            <HeaderRightSection>
              <Row className="align-items-center h-100">
                <Col md={10}>
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-evenly" }}
                  >
                    <span className="contact-wrapper py-2 px-4">
                      <p className="m-0">
                        {activeInquiry && activeInquiry.email}
                      </p>
                    </span>
                    <span className="contact-wrapper">
                      {activeInquiry && activeInquiry.phoneNumber && (
                        <p className="m-0  py-2 px-4">
                          {activeInquiry.phoneNumber}
                        </p>
                      )}
                    </span>
                  </div>
                </Col>
                <Col md={2} className="text-right">
                  <span className="pr-2" onClick={setDeleteShow}>
                    <svg
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.48 3.42H14.44V1.52C14.44 0.681625 13.7584 0 12.92 0H5.32C4.48163 0 3.8 0.681625 3.8 1.52V3.42H0.76C0.339625 3.42 0 3.75962 0 4.18V4.94C0 5.0445 0.0855 5.13 0.19 5.13H1.6245L2.21113 17.5513C2.24913 18.3611 2.91887 19 3.72875 19H14.5112C15.3235 19 15.9909 18.3635 16.0289 17.5513L16.6155 5.13H18.05C18.1545 5.13 18.24 5.0445 18.24 4.94V4.18C18.24 3.75962 17.9004 3.42 17.48 3.42ZM12.73 3.42H5.51V1.71H12.73V3.42Z"
                        fill="#787878"
                      />
                    </svg>
                  </span>
                </Col>
              </Row>
            </HeaderRightSection>
            <MessagesWrapper className="py-4">
              <MessageBox />
            </MessagesWrapper>
          </RightSectionInnerWrapper>
        </RightSection>
      </Row>
    </Wrapper>
  );
}

export default Inquiries;

// custom styled components

const Wrapper = styled(Container)``;

const LeftSection = styled(Col)`
  height: calc(100vh - 60px);
`;

const RightSection = styled(Col)`
  height: calc(100vh - 60px);
`;

const RightSectionInnerWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid #d4d4d4;
  overflow: hidden;
`;

const HeaderLeftSection = styled.div`
  display: flex;
  height: 50px;
  justify-content: space-between;
  .text {
    font-weight: bold;
    flex: 7;
  }

  .actions {
    flex: 2;
    display: flex;
    justify-content: space-between;
  }

  .filter {
    position: relative;

    > .filter-wrapper {
      position: absolute;
      top: 0px;
      right: 40px;
      width: 280px;
      height: 300px;
      border: 1px solid #d4d4d4;
      background: #fff;
      z-index: 20;
      border-radius: 5px;

      .filter-header {
        height: 40px;
        color: #fff;
        background: #6b6565;
      }

      select {
        height: 40px;
        width: 186px;
        border-radius: 12px;
        background: #fff;
        margin-bottom: 30px;

        &:focus {
          box-shadow: none;
          outline: none;
        }
      }

      .apply-filters {
        background: #ffbb00;
        color: #fff;
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
`;

const HeaderRightSection = styled.div`
  background: #e8f5f9;
  border-bottom: 1px solid #d4d4d4;
  // display: flex;
  height: 68.1px;
  .text {
    font-weight: bold;
  }

  .contact-wrapper {
    background: rgba(118, 107, 245, 0.16);
    font-size: 20px;
    border-radius: 5px;
  }
`;

const MessagesWrapper = styled.div`
  background-color: #fff;
`;
