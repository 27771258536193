import * as admin_types from "../../types/admin_types";
import { PostRoute } from "../../../api";
import { getAuthConfig } from "../../../Util/Util";

const { Get, Delete } = PostRoute();

export const fetchAdmin = (id, token) => async (dispatch) => {
  try {
    const config = getAuthConfig(token);
    let response = await Get(`/admin/view/${id}`, config);
    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }
    return response.data.response_data;
  } catch (error) {
    console.log(error);
    dispatch({ type: admin_types.FETCH_ADMIN_ERROR, data: error });
  }
};

export const fetchAdmins = (token) => async (dispatch) => {
  try {
    const config = getAuthConfig(token);

    let response = await Get("/admin", config);

    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    return response.data.response_data;
  } catch (error) {
    console.log(error);
    dispatch({ type: admin_types.FETCH_ADMINS_ERROR, data: error });
  }
};

export const deleteAdmin = (token, id) => async (dispatch) => {
  try {
    const config = getAuthConfig(token);
    let response = await Delete(`/admin/delete/${id}`, config);
    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    return response.data.response_data;
  } catch (error) {
    console.log(error);
    dispatch({ type: admin_types.DELETE_ADMIN_ERROR, data: error });
  }
};

export const fetchAllApplicationsAndInquiries = (token) => async (dispatch) => {
  try {
    const config = getAuthConfig(token);

    let response = await Get("/admin/all-applications-and-inquiries", config);

    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    return response.data.response_data;
  } catch (error) {
    console.log(error);
    dispatch({ type: admin_types.FETCH_ADMINS_ERROR, data: error });
  }
};
