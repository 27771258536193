import React, { useState, useEffect } from "react";
import { Dropdown, Form, Modal } from "react-bootstrap";
import { RiCloseCircleFill } from "react-icons/ri";
import styled from "styled-components";
import upload from "../../dist/icons/upload.svg";
import { MdKeyboardArrowDown } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import {
  createMedia,
  getAllMediaCategories,
  updateMedia,
} from "../../services/media";
import { useDispatch } from "react-redux";
import { uploadFile } from "../../redux/actions/application_actions";
import { useSelector } from "react-redux";
import CustomProgressBar from "../ProgressBar/ProgressBar";

const MediaUploadModal = ({
  show,
  onHide,
  updateMedia: updateMedia_,
  isEdit,
  mediaData,
}) => {
  const [active, setActive] = useState(1);
  const [mediaCategories, setMediaCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    title: "",
    url: "",
    description: "",
    fileType: "IMAGE",
    categories: [],
    // isYoutubeVideo: false,
    // thumbnail: "",
  });
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const uploading = useSelector(
    (state) => state.application_reducers.uploading
  );
  const auth_credentials = useSelector(
    (state) => state.auth_reducers.auth_credentials
  );

  useEffect(() => {
    if (isEdit && mediaData?._id) {
      // Map values here
      setValues({
        title: mediaData.title,
        description: mediaData.description,
        url: mediaData.url,
        categories: mediaData.categories
          ? mediaData.categories.map((cId) =>
              mediaCategories.find((c) => c?._id === cId)
            )
          : [],
      });
    }
  }, [mediaData, isEdit, mediaCategories]);

  useEffect(() => {
    const getData = async () => {
      const response = await getAllMediaCategories();

      setMediaCategories(response ?? []);
      setLoading(false);
    };

    getData();
  }, []);

  const handleChange = (e) => {
    if (
      e.target.name === "title" ||
      e.target.name === "url" ||
      e.target.name === "description"
    ) {
      setValues((values) => ({ ...values, [e.target.name]: e.target.value }));
    } else if (e.target.name === "category") {
      const isSelected = values.categories.find(
        (c) => c._id === e.target.value
      );

      if (isSelected) {
        const tempCategories = values.categories.filter(
          (c) => c._id !== e.target.value
        );
        setValues((prevVals) => ({
          ...prevVals,
          categories: [...tempCategories],
        }));
      } else {
        const categoryToAdd = mediaCategories.find(
          (c) => c._id === e.target.value
        );
        setValues((prevVals) => ({
          ...prevVals,
          categories: [...prevVals.categories, categoryToAdd],
        }));
      }
    }
  };

  const handleUpload = async (e) => {
    try {
      let file = e.target.files[0];
      let data = new FormData();
      data.append("file", file);

      let fileUrl = await dispatch(uploadFile(data));

      setValues((vals) => ({ ...vals, url: fileUrl }));
    } catch (error) {
      console.log(error);
    } finally {
      e.target.value = "";
    }
  };

  const handleDeleteCategory = (e, id) => {
    const tempCategories = values.categories.filter((c) => c._id !== id);
    setValues((prevVals) => ({
      ...prevVals,
      categories: [...tempCategories],
    }));
  };

  const handleDeleteFile = (e) => {
    setValues((vals) => ({ ...(vals ?? {}), url: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!values.categories.length) {
        return;
      }

      if (!values.url) {
        return;
      }

      setSaving(true);

      const payload = {
        title: values.title,
        url: values.url,
        description: values.description,
        fileType: values.fileType,
        categoryIds: values.categories.map((c) => c._id),
      };

      if (isEdit) {
        const response = await updateMedia(
          payload,
          mediaData._id,
          auth_credentials.token
        );
        updateMedia_("edit", response, mediaData._id);
      } else {
        const response = await createMedia(payload, auth_credentials.token);
        updateMedia_("add", response);
      }

      onHide();
    } catch (error) {
      console.log(error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <ModalWrapper show={show} size="lg" centered>
      <div className="d-flex w-100 px-2 py-2">
        <h3 className="font-25 text-light-black mb-0 flex-grow-1 fw-bold pl-3 mt-3">
          Upload Media
        </h3>
        <div className="text-right">
          <RiCloseCircleFill
            onClick={() => onHide()}
            className="icon-30 text-medium-dark"
          />
        </div>
      </div>
      <Modal.Body>
        <div className="mb-md-3 d-flex mx-auto align-items-center vehicle-change br-10 p-0 bg-gray">
          <span
            onClick={(e) => {
              setActive(1);
              setValues((values) => ({ ...values, fileType: "IMAGE" }));
            }}
            className={`${
              active === 1
                ? `vehicle-active br-10`
                : "text-light-black fw-normal"
            }`}
          >
            Image
          </span>
          <span
            onClick={(e) => {
              setActive(2);
              setValues((values) => ({ ...values, fileType: "VIDEO" }));
            }}
            className={`${
              active !== 1
                ? `vehicle-active br-10`
                : "text-light-black fw-normal"
            }`}
          >
            Video
          </span>
        </div>
        <Form className="px-3" onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Media Title</Form.Label>
            <Form.Control
              type="text"
              onChange={handleChange}
              value={values.title}
              className=""
              placeholder="Type media title"
              name="title"
              required
            />
          </Form.Group>
          {active === 1 ? (
            <Form.Group className="mb-3 mb-md-4 upload_media br-5 p-2 p-md-3 text-center mt-md-5">
              <div className="mb-3">
                <img src={upload} alt="upload" height="50px" />
                {/* <h5 className="fw-bold font-18 mb-0 text-light-black">
                  Drop files here
                </h5> */}
                <div className="d-flex gap-1 justify-content-center">
                  {/* <div className="text-gray font-16">or</div> */}
                  <div className="d-flex align-items-center h-100">
                    <label
                      htmlFor="actual-btn"
                      className="w-100 cursor-pointer "
                    >
                      <div className="font-16">Browse Files</div>
                    </label>
                    <input
                      type="file"
                      id="actual-btn"
                      hidden
                      accept={active === 1 ? "image/*" : "video/*"}
                      onChange={handleUpload}
                      className="w-100"
                    />
                  </div>
                  <div className="text-gray font-16"> from your computer</div>
                </div>
                {/* <div className="mt-2 text-gray font-15">
                  Video must be less than 30 MB and photo must be less than 2 MB
                  in size
                </div> */}
                <div className="mt-2 text-gray font-15">
                  Photo must be less than 2 MB in size
                </div>

                {values.url ? (
                  <p
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    className="text-primary"
                  >
                    Photo url{" "}
                    <RxCross2
                      className=" text-dark ml-1 cursor-pointer"
                      onClick={(e) => handleDeleteFile(e)}
                    />
                  </p>
                ) : null}
              </div>
            </Form.Group>
          ) : (
            <Form.Group className="mb-3">
              <Form.Label>Youtube Link</Form.Label>
              <Form.Control
                type="text"
                value={values?.url}
                className=""
                placeholder="URL"
                name="url"
                onChange={handleChange}
                required
              />
            </Form.Group>
          )}

          {uploading ? (
            <div>
              <CustomProgressBar />
            </div>
          ) : null}

          <Form.Group className="mb-3 mb-md-5">
            <Form.Label>Categories</Form.Label>
            <Dropdown className="category_dropdown">
              <Dropdown.Toggle
                id="dropdown-button-dark-example1"
                variant="white"
              >
                <div className="w-100 text-white d-flex justify-content-between align-items-center">
                  <div className="text-dark">
                    {/* Select category */}
                    <div className="d-flex flex-wrap gap-1">
                      {values.categories?.map((c) => (
                        <div className=" br-5 btn-dark px-2 py-1 font-15">
                          {c?.name}
                          <RxCross2
                            className=" text-white ml-1 cursor-pointer"
                            onClick={(e) => handleDeleteCategory(e, c._id)}
                          />
                        </div>
                      ))}

                      {/* <div className=" br-5 btn-dark px-2 py-1 font-15">
                        Ads
                        <RxCross2 className=" text-white ml-1 cursor-pointer" />
                      </div> */}
                    </div>
                  </div>
                  <MdKeyboardArrowDown className="icon-30 text-dark" />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="p-2 p-lg-3 pl-3">
                  {mediaCategories?.map((item, idx) => {
                    const isChecked = values.categories.find(
                      (c) => c?._id === item?._id
                    );

                    return (
                      <Form.Check
                        label={item?.name}
                        name="category"
                        type="checkbox"
                        id={`category-${idx}`}
                        value={item?._id}
                        onChange={handleChange}
                        key={idx}
                        checked={isChecked}
                      />
                    );
                  })}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <div className="mb-3">
            <Form.Group className="mb-1">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                onChange={handleChange}
                value={values.description}
                rows={3}
                required
                maxLength={550}
              />
            </Form.Group>
            <div className="d-flex justify-content-end">
              <small>{`${values.description?.length ?? 0}/550`}</small>
            </div>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="btn br-10 btn-dark font-18"
              disabled={saving}
            >
              {saving ? "Uploading..." : "Upload"}
            </button>
          </div>
        </Form>
      </Modal.Body>
    </ModalWrapper>
  );
};

export default MediaUploadModal;

const ModalWrapper = styled(Modal)`
  .modal-dialog {
    /* max-width: 500px; */
    margin: 1.75rem auto;

    .vehicle-change {
      width: 225px;
      background: #e0e0e0;
      border: 1px solid #c4c4c4;
      height: 40px;
    }
    .upload_media {
      border: 1px dashed #c4c4c4;
    }

    .category_dropdown {
      button.btn.dropdown-toggle {
        width: 100%;
        min-height: 35px;
        border-radius: 5px;
        border: 1px solid #dee2e6 !important;
        padding: 0 0.5rem;
        font-weight: normal;
        font-size: 16px;
        gap: 1rem;

        &::after {
          display: none;
        }
        &:focus {
          box-shadow: none;
        }
      }
      .dropdown-menu.show {
        width: 100%;
      }
      .form-check-input:checked {
        background-color: var(--black);
        border-color: var(--black);
      }
    }
  }
`;
