import React from 'react'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import ArticleOverview from './ArticleOverview'

export default function LatestArticles() {
  const articleImage = 'https://i.ibb.co/RBLLSq5/food.jpg'
  return (
    <Wrapper>
      <Row>
        <Col lg={8}>
          <ArticleOverview
            isTopArticle
            articleImage={articleImage}
            title="The Science of Sleep: Unlocking the Secrets to a Restful Night"
            desc="Sleep is a fundamental aspect of our lives, yet it often remains a mystery to many. Have you ever wondered why some nights you wake..."
          />
        </Col>
        <Col lg={4}>
          <h3 className="font-25 text-dark-gray mt-3 mt-lg-0">Latest</h3>
          <div className="d-flex flex-column gap-3">
            {[1, 2, 3, 4].map(item => (
              <div key={item} className="article_heading bg-white p-3 br-10 cursor-pointer">
                <div className="font-15 text-gray font-weight-bold">HEADING</div>
                <h5 className="text-light-black font-18 mb-0 mt-2">Behind the Scenes: The Art and Craft of Game Development</h5>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div`
.article_heading {
  &:hover {
    outline: 1px solid var(--gray);
    box-shadow: 2px 2px 4px #000;
  }
}
`