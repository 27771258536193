import React from "react";
import styled from "styled-components";

const CustomMessageInput = ({ changeHandler, message }) => {
  return (
    <Wrapper>
      <h5 className="display-12 text-dark-gray font-weight-500 font-16">
        Type your custom message here!
      </h5>
      <div className="cus-space" />
      <div className="form-group" id="drivercat2">
        <textarea
          name="customMessage"
          rows={5}
          cols={70}
          minLength={100}
          maxLength={200}
          className="form-control bg-dark-white font-15 border"
          onkeyup="countChars(this);"
          placeholder="(Minimum of 100 characters & maximum of 200 characters)"
          data-mirror
          required
          value={message}
          onChange={changeHandler}
        />
        <div className="text-right pr-1 text-dark-gray ">
          <p>{message.length}/200</p>
        </div>
      </div>
    </Wrapper>
  );
};

export default CustomMessageInput;

const Wrapper = styled.div`
  .template-message {
    font-size: 17px;
    font-weight: 500;
    margin-left: 5px;
    color: #575353;
  }
`;
