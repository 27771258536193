import React from "react";

const CompanyInfo = ({ changeHandler }) => {
  return (
    <>
      <div className="mb-5 form-group">
        <label
          for="formGroupExampleInput"
          className="font-16 mb-2 text-dark-gray font-weight-500"
        >
          {" "}
          Company Name
        </label>
        <input
          type="text"
          className="form-control font-15 mobility-select text-dark-gray"
          id="companyName"
          name="companyName"
          placeholder="Your company"
          onChange={changeHandler}
        />
      </div>

      <div className="mb-5 form-group">
        <label
          for="formGroupExampleInput"
          className="font-16 mb-2 text-dark-gray font-weight-500"
        >
          {" "}
          Your Position
        </label>
        <input
          type="text"
          className="form-control font-15 mobility-select text-dark-gray"
          id="position"
          name="position"
          placeholder="Your position at the company"
          onChange={changeHandler}
        />
      </div>
    </>
  );
};

export default CompanyInfo;
