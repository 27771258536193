import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { FiUpload } from "react-icons/fi";
import styled from "styled-components";
import MediaCard from "../../components/Admin/MediaCard";
import MediaUploadModal from "../../components/Admin/MediaUploadModal";
import { getAllMedia } from "../../services/media";

export default function Media() {
  // const navigate = useNavigate();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [media, setMedia] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const response = await getAllMedia();
      setMedia(response ?? []);
      setLoading(false);
    };

    getData();
  }, []);

  const updateMedia = (action, media, id) => {
    if (action === "edit") {
      setMedia((prevValues) => [
        ...prevValues.map((m) => (m._id === id ? media : m)),
      ]);
    } else if (action === "del") {
      setMedia((prevValues) => [...prevValues.filter((m) => m._id !== id)]);
    } else {
      setMedia((prevValues) => [media, ...prevValues]);
    }
  };

  return (
    <Wrapper className="px-3 py-3 py-md-4">
      <div className="d-flex justify-content-between align-items-center pb-3">
        <h3 className="font-25 text-light-black mb-0 flex-grow-1 fw-bold">
          Media
        </h3>
        <button
          className="btn br-10 btn-dark font-18"
          onClick={() => setShowUploadModal(true)}
        >
          <FiUpload className="icon-25 mr-1 mb-1" />
          Upload Media
        </button>
      </div>

      {loading ? (
        <div>Loading...</div>
      ) : (
        <Row xs={1} sm={2} md={3} xl={4} className="g-4">
          {media?.length ? (
            media.map((media, idx) => (
              <MediaCard key={idx} media={media} updateMedia={updateMedia} />
            ))
          ) : (
            <div>No media yet!</div>
          )}
        </Row>
      )}

      {showUploadModal && (
        <MediaUploadModal
          show={showUploadModal}
          onHide={() => setShowUploadModal(false)}
          updateMedia={updateMedia}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 1450px;
  margin: auto;

  .media {
    &:hover {
      .card-img-top {
        transition: all 0.5s ease;
        transform: scale(1.05);
      }
    }
  }
  .media_contents {
    min-height: 7rem;
  }
  .btn.nav-active {
    min-width: 140px;
  }
`;
