import React from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import styled from "styled-components";

const Step = (props) => {
  return (
    <StepWrapper>
      <CircleWrapper
        className="d-flex align-items-center justify-content-center"
        isActive={props.isActive}
      >
        {props.val}
      </CircleWrapper>
      <small className="step-text text-center">{props.text}</small>
    </StepWrapper>
  );
};

const StepProgressBar = ({
  currentStep,
  steps,
  setCurrentStep,
  stepProgressTitle,
}) => {
  let stepPercentage = 0;

  let stepsArr = [];

  for (let i = 0; i < steps; i++) {
    stepsArr.push(i + 1); // mutation is OK
  }

  const updatePercentage = () => {
    if (currentStep <= 1) {
      stepPercentage = 0;
    } else {
      let factor = 1 / (steps - 1);

      for (let i = 1; i < steps + 1; i++) {
        if (currentStep - 1 === i) {
          stepPercentage = (currentStep - 1) * factor * 100;
          break;
        }
      }
    }
  };

  updatePercentage();

  return (
    <Wrapper className="d-flex align-items-center justify-content-center flex-column w-100">
      <div className="w-100 d-flex gap-2 gap-sm-5 align-items-center">
        {currentStep > 1 && (
          <button
            className="pr-3 pl-2 d-flex align-items-center font-15"
            onClick={(e) => {
              e.preventDefault();
              if (currentStep <= 1) {
                return;
              }
              setCurrentStep((n) => n - 1);
            }}
          >
            <MdKeyboardArrowLeft className="font-25 mr-1" /> Prev
          </button>
        )}

        <div
          id="circle-wrapper"
          className="d-flex w-100 justify-content-between"
        >
          <ProgressFull
            className="progress-line progress-full"
            percentage={stepPercentage}
          />
          <div className="progress-line progress-empty" />
          {stepsArr.map((_, idx) => {
            return (
              <Step
                key={idx}
                isActive={idx + 1 <= currentStep}
                val={idx + 1}
                text={stepProgressTitle[idx]}
              />
            );
          })}
        </div>
      </div>
      <p className="text-center md-text mt-3">
        {stepProgressTitle[currentStep - 1]}
      </p>
    </Wrapper>
  );
};

export default StepProgressBar;

const Wrapper = styled.div`
  #circle-wrapper {
    position: relative;
  }

  .progress-line {
    position: absolute;
    z-index: 1;
    top: calc(50% - 2px);
    height: 4px;
    left: 0;
  }

  .progress-full {
    background-color: green;
    z-index: 2;
  }

  .progress-empty {
    background-color: #ccc;
    width: 100%;
  }

  button {
    background: #e5e5e5;
    border: 0.3px solid #c4c4c4;
    border-radius: 3px;
    height: 35px;

    &:focus {
      outline: none;
    }
  }

  .md-text {
    display: none;
  }

  @media screen and (max-width: 992px) {
    .md-text {
      display: block;
    }
  }
`;

const StepWrapper = styled.div`
  position: relative;

  .step-text {
    position: absolute;
    width: 180px;
    left: -60px;
    top: 120%;
  }

  @media screen and (max-width: 992px) {
    .step-text {
      display: none;
    }
  }
`;

const CircleWrapper = styled.div`
  ${(props) =>
    props.isActive &&
    `border: 4px solid green;
background-color: green; color:#fff;`}

  ${(props) =>
    !props.isActive &&
    `border: 4px solid #ccc;
background-color: #fff;`}

  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: relative;
  z-index: 3;

  @media screen and (max-width: 500px) {
    height: 35px;
    width: 35px;
  }
`;

const ProgressFull = styled.div`
  width: ${(props) => props.percentage}%;
  transition: 0.4s ease;
`;
