import React from "react";
import AdminHeader from "../../components/Admin/AdminHeader";
import AdminSidebar from "../../components/Admin/AdminSidebar";
import { Outlet } from "react-router-dom";

const AdminDashboard = () => {
  return (
    <div className="admin-dashboard min-height-100 position-relative">
      <AdminHeader />
      <div className="admin-sections position-relative">
        <AdminSidebar />
        <div className="main-content-wrapper position-relative">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
