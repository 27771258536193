import React from "react";

const BenefitsCard = ({ data, id }) => {
  return (
    <div
      className={`row benefit-card m-0 pt-5 ${id !== 1 && `pb-5`} ${id % 2 !== 0 && `benefit-card-blue`
        } `}
    >
      <div className="col-lg-2 d-flex justify-content-center">
        <div className="benefits-count-box mb-3 mb-lg-0 shadow font-40">
          {id + 1}
        </div>{" "}
      </div>
      <div className="col-lg-4">
        <p className="font-25 font-weight-500">{data.title}</p>
        <p className="font-18">{data.content}</p>
      </div>
      <div className="col-lg-6 d-flex justify-content-center">
        {id === 3 ? (
          <span className="bg-white p-3 motors-wrapper">
            <img src={data.image} alt="benefit" />
          </span>
        ) : (
          <img src={data.image} alt="benefit" />
        )}
      </div>
    </div>
  );
};

export default BenefitsCard;
