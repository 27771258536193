import React, { useState } from "react";
import { useDispatch } from "react-redux";
import contactImage from "../dist/images/contact.png";
import { createContactMessage } from "../redux/actions/contact_actions";

const ContactUs = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    fullname: "",
    email: "",
    message: "",
    phoneNumber: "",
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      if (data) {
        if (data.fullname === "") {
          throw Error("Fullname field cannot be blank");
        } else if (data.email === "") {
          throw Error("Email field cannot be blank");
        } else if (data.message === "") {
          throw Error("Email field cannot be blank");
        } else {
          const response = await dispatch(createContactMessage(data));

          if (response) {
            setSuccess("Message sent successfully!");
          }

          console.log(response);

          setData({
            fullname: "",
            email: "",
            message: "",
            phoneNumber: "",
          });

          setTimeout(() => {
            setError("");
            setSuccess("");
          }, 7000);
        }
      }
    } catch (err) {
      setError(err.message);

      setTimeout(() => {
        setError("");
        setSuccess("");
      }, 7000);
      // console.log(err.message);
    }
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <div className="row min-height-50  px-3 py-5   contact-section page-height">
      <div className="col-lg-12">
        <div className="row m-0">
          <div className="col-lg-12">
            <h1 className="text-white px-3 py-1 pl-5 title ">Reach Out!</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 p-0">
            <div className="">
              <p className="contact-email bg-light-blue pr-2 py-2 pl-5 br-5 font-20">
                hi5@fleetsimplify.com
              </p>
              <img
                src={contactImage}
                alt="customer care user"
                className="img-fluid d-block mt-5"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="contact-form bg-light-blue p-2 br-5 px-lg-5 py-4">
              <form onSubmit={handleSubmit}>
                <h1>Contact Us</h1>
                {error && (
                  <p className="error-box my-3">
                    {error &&
                      error[0].toUpperCase() + error.slice(1).toLowerCase()}
                  </p>
                )}
                {success && <p className="success-box my-3">{success}</p>}
                <div className="form-group pb-1">
                  <input
                    type="text"
                    name="fullname"
                    id="fullname"
                    className="form-control"
                    placeholder="Your Name"
                    value={data && data.fullname}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group pb-1">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    placeholder="Your Email Address"
                    value={data && data.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group pb-1">
                  <input
                    type="tel"
                    name="phoneNumber"
                    id="phoneNumber"
                    className="form-control"
                    placeholder="Your Phone Number(Optional)"
                    value={data && data.phoneNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group pb-1">
                  <textarea
                    name="message"
                    id="message"
                    className="forn-control"
                    cols="30"
                    rows="10"
                    placeholder="Your Message"
                    value={data && data.message}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-blue br-10"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
