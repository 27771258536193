import React from 'react'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import ArticleOverview from './ArticleOverview'
import { MdAccessTime } from 'react-icons/md'
import { IoMdListBox } from 'react-icons/io'
import ArticleCategoryBatch from './ArticleCategoryBatch'
import ArticleLists from './ArticleLists'

const articleContents = [
  { id: 1, content: 'The Need for Speed', description: "First and foremost, 5G is all about speed. It's the highway that enables data to travel at breakneck speeds, and it's redefining our expectations of what's possible. With 5G, you can download a high-definition movie in seconds, not minutes. This lightning-fast speed opens up a world of opportunities for industries and consumers alike." },
  { id: 2, content: 'A World of Connectivity', description: "5G is not just about faster downloads on your smartphone. It's about connecting everything around us. The Internet of Things (IoT) is becoming a reality as 5G enables billions of devices to communicate seamlessly. Your smart home appliances, autonomous vehicles, and even city infrastructure will all be part of a connected ecosystem, sharing data and insights in real-time." },
  { id: 3, content: 'Challenges and Considerations', description: "While 5G technology holds immense promise, it also comes with its set of challenges. The deployment of 5G networks requires substantial infrastructure upgrades, including the installation of small cell towers and fiber optic cables. Additionally, concerns related to data privacy and security must be addressed as more devices and systems become interconnected." },
  { id: 4, content: 'The Future is 5G', description: "In conclusion, 5G technology is not just a step forward; it's a leap into the future. It's revolutionizing the way we connect, communicate, and do business. As 5G networks continue to roll out across the globe, we can expect to see an explosion of innovation in industries and experiences we can't even imagine today. The world is changing, and it's all happening at 5G speed. Embrace the revolution; the future is here." },
]

export default function ArticleDetails() {
  const articleImage = 'https://i.ibb.co/RBLLSq5/food.jpg'
  return (
    <Wrapper>
      <Row className="mb-4 mb-lg-5 align-items-center flex-column-reverse flex-lg-row">
        <Col lg={7}>
          <ArticleOverview
            articleImage={articleImage}
          />
        </Col>
        <Col lg={5} className="mb-3 d-flex flex-column gap-2 gap-lg-4">
          <div>
            <ArticleCategoryBatch title={'Tech'} />
          </div>
          <h2 className="font-35 text-dark-gray m-0">How 5G Technology is Revolutionizing Connectivity and Beyond</h2>
          <div className="font-16"><MdAccessTime className='font-23 mb-1' /> 5min read</div>
        </Col>
      </Row>
      <Row className="mb-3 mb-lg-4">
        <Col lg={9}>
          <div className="d-flex flex-column gap-3 gap-lg-4 mb-3 mb-lg-4">
            <div className="">
              In the ever-evolving landscape of technology, there's a new buzzword on the block that's changing the way we connect, communicate, and conduct business. It's 5G, the fifth generation of wireless technology, and it's not just an incremental improvement; it's a game-changer. In this blog post, we'll dive into how 5G technology is revolutionizing connectivity and ushering us into a new era of possibilities.
            </div>
            {articleContents.map(item => (
              <div key={item} id={item.id}>
                <h2 className="font-30 text-dark-gray">{item.content}</h2>
                <p className="">{item.description}</p>
              </div>
            ))}
          </div>
        </Col>
        <Col lg={3} className="d-none d-lg-block">
          <h3 className="font-20 text-dark-gray mt-3 mt-lg-0">Contents</h3>
          <div className="d-flex flex-column gap-3">
            {articleContents.map(item => (
              <a href={`#${item.id}`} key={item} className="article_heading bg-white p-2 br-10 cursor-pointer text-decoration-none">
                <h5 className="text-light-black font-17 mb-0">{`> ${item.content}`}</h5>
              </a>
            ))}
          </div>
        </Col>
      </Row>
      <div className="mt-4 mt-lg-0">
        <h3 className="font-25 text-dark-gray mb-3 flex-grow-1">
          <IoMdListBox className='icon-40 text-blue' /> Recent Articles
        </h3>
        <ArticleLists isRecentArticle />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
`