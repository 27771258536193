import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdminsTable from "./AdminsTable";

const Admins = () => {
  const navigate = useNavigate();

  const handleGoToAddNewAdminPage = () => {
    const route = `/admin/admins/new`;

    navigate(route);
  };

  const credentials = useSelector(
    (state) => state.auth_reducers.auth_credentials
  );

  return (
    <div className="admins-page">
      <div className="row">
        <div className="col-lg-12 d-flex align-items-center justify-content-between">
          <h2>Admins</h2>

          {credentials && credentials.permission === 0 && (
            <button
              className="btn btn-blue font-18"
              onClick={handleGoToAddNewAdminPage}
            >
              + Add New Admin
            </button>
          )}
        </div>
      </div>
      <div className="mt-4">
        <AdminsTable />
      </div>
    </div>
  );
};

export default Admins;
