import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
// import { Icon } from "@iconify/react";
// import crossCircle from "@iconify/react/gridicons/cross-circle";
import DriverItemModal from "./DriverItemModal";
import {
  createVehicleMake,
  editVehicleMake,
  getVehicleMakes,
} from "../../../redux/actions/vehicle_actions";

const AddMakes = ({ vehicle, ...props }) => {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentModelId, setCurrentModelId] = useState(-1);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [vehicleModel, setVehicleModel] = useState({ model: "", _id: "" });
  const [vehicleMake, setVehicleMake] = useState("");
  const [makeError, setMakeError] = useState("");
  const [modelError, setModelError] = useState("");
  const [saved, setSaved] = useState(false);

  const credentials = useSelector(
    (state) => state.auth_reducers.auth_credentials
  );

  useEffect(() => {
    if (vehicle) {
      setVehicleModels(vehicle.vehicleModels);
      setVehicleMake(vehicle.vehicleMake);
    }
  }, [vehicle]);

  const vehicleMakes = useSelector(
    (state) => state.vehicle_reducers.vehicleMakes
  );

  const handleChangeMake = (e) => {
    setMakeError("");
    setVehicleMake(e.target.value);
  };

  const handleDeleteModel = (makeId) => {
    setVehicleModels(vehicleModels.filter((model) => model._id !== makeId));
    setShowDeleteModal(false);
    // to be addded api to delete single model if its an edit
  };

  const handleChangeModel = (e) => {
    setModelError("");
    setVehicleModel({
      _id: vehicleModels.length,
      model: e.target.value,
    });
  };

  const handleAddNewModel = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const foundModel = vehicleModels.find(
      (vehicle) =>
        vehicleModel &&
        vehicle.model &&
        vehicle.model.toLowerCase().trim() ===
          vehicleModel.model.toLowerCase().trim()
    );

    if (foundModel) {
      setModelError("This model already exists. Try another one");
    } else {
      if (vehicleModel && vehicleModel.model !== "" && vehicleMake !== "") {
        setVehicleModels([vehicleModel, ...vehicleModels]);
        setVehicleModel({ _id: "", model: "" });
      }
    }
  };

  const handleCreateNewMake = async (e) => {
    e.preventDefault();
    try {
      if (credentials) {
        if (vehicle && vehicle._id && vehicle._id.toString().length > 6) {
          //edit the vehicle details

          const editData = {
            vehicleMake,
            vehicleModels: vehicleModels.map((make) => {
              return {
                model: make.model,
                isAccepted: make.isAccepted ? make.isAccepted : false,
              };
            }),
          };

          setSaved(true);

          await dispatch(
            editVehicleMake(vehicle && vehicle._id, editData, credentials.token)
          );

          await dispatch(getVehicleMakes());

          setTimeout(() => {
            setSaved(false);
          }, 10000);
        } else {
          //create a new vehicle make
          const data = {
            vehicleMake,
            vehicleModels: vehicleModels.map((make) => {
              return {
                model: make.model,
                isAccepted: false,
              };
            }),
          };

          setSaved(true);

          const response = await dispatch(
            createVehicleMake(data, credentials.token)
          );

          if (response.response_code !== 200) {
            throw Error(response.response_data.message.split("_").join(" "));
          }

          setTimeout(() => {
            setSaved(false);
          }, 20000);
        }
      }
    } catch (err) {
      setSaved(false);
      setTimeout(() => {}, 7000);
      console.log(err.message);
    }
  };

  const handleMakeBlur = (e) => {
    //check if the vehicle model exists
    if (vehicleMakes) {
      if (vehicle && vehicle._id && vehicle._id.toString().length <= 6) {
        const foundVehicle = vehicleMakes.find(
          (vehicle) =>
            vehicle &&
            vehicle.vehicleMake &&
            vehicle.vehicleMake.toLowerCase().trim() ===
              vehicleMake.toLowerCase().trim()
        );

        if (foundVehicle) {
          setMakeError("This make already exists. Try another one");
        }
      }
    }
  };

  const isModelSubmittable =
    !modelError &&
    vehicleModel &&
    vehicleModel.model !== "" &&
    vehicleMake !== "";

  const isMakeSubmittable =
    !makeError &&
    vehicleModels &&
    vehicleModels.length > 0 &&
    vehicleMake !== "";

  return (
    <Wrapper className="my-5 w-100 bg-white px-4 py-2 position-relative">
      {/* <Icon
        icon={crossCircle}
        className="text-dark-gray icon-position icon-40"
        onClick={() => {
          props.setCurrentMakeId(vehicle && vehicle._id);
          props.setShowDeleteModal(true);
        }}
      /> */}
      <form className="row py-4">
        <div className="col-lg-12 mb-3 d-flex align-items-center flex-wrap">
          <div className="row">
            <div className="col-lg-3 d-flex align-item-center">
              <div
                className={`form-group ${makeError && "input-error"}   my-1`}
              >
                <input
                  type="text"
                  id="vehicleMake"
                  name="vehicleMake"
                  className="form-control font-15 text-dark-gray"
                  placeholder="Type Vehicle Make"
                  value={vehicleMake}
                  onChange={handleChangeMake}
                  onBlur={handleMakeBlur}
                  disabled={
                    vehicle && vehicle._id && vehicle._id.toString().length > 6
                  }
                />
                {makeError && <p className="input-error-box">{makeError}</p>}
              </div>
            </div>

            <div className="col-lg-9 d-flex align-items-center">
              <div
                className={`form-group ${
                  modelError && "input-error"
                } d-flex align-items-center  mr-3  my-1`}
              >
                <input
                  type="text"
                  id="vehicleModel"
                  name="vehicleModel"
                  className="form-control  font-15 text-dark-gray"
                  placeholder="Type Vehicle Model"
                  value={vehicleModel && vehicleModel.model}
                  onChange={handleChangeModel}
                  disabled={makeError}
                />
                {modelError && <p className="input-error-box">{modelError}</p>}
              </div>

              <div className="form-group my-1">
                <button
                  className="btn btn-lighter-secondary br-5 height-45"
                  type="submit"
                  onClick={handleAddNewModel}
                  disabled={!isModelSubmittable}
                >
                  + Add
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-9  min-height-20 mb-5">
              {/* <div className="row"> */}
              <div className=" d-flex justify-content-start flex-wrap ">
                {vehicleModels &&
                  vehicleModels.map((model) => (
                    <div
                      key={model._id}
                      style={{ maxWidth: "200px" }}
                      className="model-box my-1 d-flex justify-content-between align-items-center min-width-150 mr-2"
                    >
                      <span className="font-15 pl-3">{model.model}</span>
                      {/* <Icon
                        icon={crossCircle}
                        className="text-dark-gray icon-40"
                        style={{ color: "#969393" }}
                        onClick={() => {
                          setCurrentModelId(model._id);
                          setShowDeleteModal(true);
                        }}
                      /> */}
                    </div>
                  ))}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-12">
          <div className="row">
            <div className="col-xl-2 col-lg-3"></div>
            <div className="col-xl-10 col-lg-9 d-flex flex-wrap">
              {vehicleModels &&
                vehicleModels.map((model) => (
                  <div
                    key={model._id}
                    style={{ maxWidth: "200px" }}
                    className="model-box d-flex justify-content-between align-items-center min-width-150 mx-2 mb-3"
                  >
                    <span className="font-15 pl-3">{model.model}</span>
                    <Icon
                      icon={crossCircle}
                      className="text-dark-gray icon-40"
                      style={{ color: "#969393" }}
                      onClick={() => {
                        setCurrentModelId(model._id);
                        setShowDeleteModal(true);
                      }}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div> */}

        <div className="col-12 text-right d-flex align-items-center justify-content-end">
          {saved === true && (
            <span type="button" className="saved">
              Saved
              <span className="fa fa-check"></span>
            </span>
          )}
          <button
            className="btn btn-lighter-secondary br-5 height-45"
            type="submit"
            disabled={!isMakeSubmittable}
            onClick={handleCreateNewMake}
          >
            Save
          </button>
        </div>
      </form>

      {showDeleteModal && (
        <DriverItemModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          handleDelete={handleDeleteModel}
          currentModelId={currentModelId}
        />
      )}
    </Wrapper>
  );
};

export default AddMakes;

const Wrapper = styled.div`
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.23);
  border-radius: 3px;

  .icon-position {
    position: absolute;
    top: -15px;
    right: -12px;
  }

  .model-box {
    background: #e9e8e8;
    border: 0.5px solid #bdbcbc;
    border-radius: 44px;
  }
`;
