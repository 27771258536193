import React from "react";

export default function TechnologyCard({ data }) {
  return (
    <div className="tech-card px-3">
      <span className="tech-icon">
        <img src={data.icon} alt="tech-icon" height="50px" />
      </span>
      <h5 className="font-20 font-weight-700 mb-3">{data.title}</h5>
      <p className="text-left">{data.content}</p>
    </div>
  );
}
