import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { fetchAllApplicationsAndInquiries } from "../../redux/actions/admin_actions";

const MainDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  let credentials = useSelector(
    (state) => state.auth_reducers.auth_credentials
  );

  useEffect(() => {
    const getData = async () => {
      if (credentials) {
        setLoading(true);
        const data = await dispatch(
          fetchAllApplicationsAndInquiries(credentials.token)
        );
        setData(data);
        setLoading(false);
      }
    };

    getData();
  }, [credentials, dispatch]);

  if (loading) return <Loading />;

  return (
    <div className="main-dashboard admins-page">
      <div className="row mt-5">
        <div className="col-xl-9 col-lg-11 ml-4">
          <div className="row ">
            <div className="col-lg-6 my-3">
              <div
                className="dashboard-component applications"
                onClick={() => navigate("/admin/applications")}
              >
                <h2>Applications</h2>
                <h1>{data && data.applications}</h1>
              </div>
            </div>
            <div className="col-lg-6 my-3">
              <div
                className="dashboard-component inquiries"
                onClick={() => navigate("/admin/inquiries")}
              >
                <h2>Inquiries</h2>
                <h1>{data && data.inquiries}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainDashboard;
