import * as auth_types from "../../types/auth_types";
import { PostRoute } from "../../../api";
import { getAuthConfig } from "../../../Util/Util";

const { Post } = PostRoute();

export const login = (data) => async (dispatch) => {
  try {
    let response = await Post("/admin/login", data);
    // if (response.data.response_code !== 200) {
    //   throw new Error(response.data.response_data.message);
    // }

    dispatch({
      type: auth_types.LOGIN_SUCCESS,
      data: response.data.response_data,
    });

    return response.data;
  } catch (error) {
    dispatch({ type: auth_types.LOGIN_ERROR, data: error });
  }
};

export const resetPassword = (data) => async (dispatch) => {
  try {
    dispatch({
      type: auth_types.RESET_PASSWORD_REQUEST,
    });

    let response = await Post("admin/reset-password", data);

    console.log(response);

    dispatch({
      type: auth_types.RESET_PASSWORD_SUCCESS,
      data: response.data.response_data,
    });

    return response.data;
  } catch (error) {
    dispatch({
      type: auth_types.RESET_PASSWORD_ERROR,
      data: error.message.split("_").join(" "),
    });
  }
};

export const forgotPassword = (data) => async (dispatch) => {
  try {
    dispatch({
      type: auth_types.FORGOT_PASSWORD_REQUEST,
    });

    let response = await Post("/admin/forgot-password", data);
    // if (response.data.response_code !== 200) {
    //   throw new Error(response.data.response_data.message);
    // }

    dispatch({
      type: auth_types.FORGOT_PASSWORD_SUCCESS,
      data: response.data.response_data,
    });

    return response.data;
  } catch (error) {
    dispatch({ type: auth_types.FORGOT_PASSWORD_ERROR, data: error });
  }
};

export const logout = () => {
  return { type: auth_types.LOGOUT };
};

export const createAdmin = (data, token) => async (dispatch) => {
  try {
    const config = getAuthConfig(token);

    dispatch({
      type: auth_types.CREATE_ADMIN_REQUEST,
    });

    let response = await Post("/admin/register", data, config);

    dispatch({
      type: auth_types.CREATE_ADMIN_SUCCESS,
    });

    return response.data;
  } catch (error) {
    dispatch({ type: auth_types.CREATE_ADMIN_ERROR, data: error });
  }
};

export const editAdmin = (data, token) => async (dispatch) => {
  try {
    dispatch({
      type: auth_types.EDIT_ADMIN_REQUEST,
    });

    const config = getAuthConfig(token);

    let response = await Post("/admin/edit", data, config);

    dispatch({
      type: auth_types.EDIT_ADMIN_SUCCESS,
    });

    return response.data;
  } catch (error) {
    dispatch({ type: auth_types.EDIT_ADMIN_ERROR, data: error });
  }
};
