import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import AdminCoverSide from "../../components/Admin/AdminCoverSide";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../redux/actions/auth_actions";

const AdminLogin = () => {
  const [data, setData] = useState({ email: "", password: "", role: "ADMIN" });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const response = await dispatch(login(data));
      if (response.response_code !== 200) {
        if (response.response_code === 500) {
          throw Error(
            response.response_data.message &&
              response.response_data.message.split("_").join(" ")
          );
        } else if (response.response_code === 404) {
          throw Error(
            response.response_data &&
              response.response_data.split("_").join(" ")
          );
        } else {
          throw Error("Something went wrong. Please try again");
        }
      } else {
        setSuccess("Login successfully!");
        setError("");
      }

      setLoading(false);

      setTimeout(() => {
        setError("");
        setSuccess("");
      }, 7000);
    } catch (err) {
      setTimeout(() => {
        setError("");
        setSuccess("");
      }, 7000);
      setError(err.message);
      setLoading(false);
    }
  };

  const token = useSelector(
    (state) => state.auth_reducers.auth_credentials.token
  );

  if (token) {
    return <Navigate to="/admin" />;
  }

  return (
    <div className="row admin-login min-height-100">
      <AdminCoverSide />
      <div className="col-md-6">
        <div className="admin-login-right ">
          <form className="d-flex align-items-center justify-content-center flex-column">
            <h2 className="title  my-3 text-uppercase">Admin Login</h2>
            {error && (
              <p className="error-box my-3 w-100">
                {error && error[0].toUpperCase() + error.slice(1).toLowerCase()}
              </p>
            )}
            {success && <p className="success-box my-3  w-100">{success}</p>}
            <div className="form-group">
              <span className="fa fa-user"></span>
              <input
                type="email"
                name="email"
                id="email"
                className="form-control"
                autoComplete="off"
                placeholder="Email address"
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <span className="fa fa-lock"></span>
              <input
                type="password"
                name="password"
                id="password"
                className="form-control"
                autoComplete="off"
                placeholder="Password"
                onChange={handleChange}
              />
            </div>
            <button
              type="submit"
              className="btn btn-blue my-3"
              onClick={handleLogin}
              disabled={loading}
            >
              {loading ? "Logging in..." : "Login"}
            </button>
            <Link to="/admin/forgot-password" className="f-password">
              Forgot your Password?
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
