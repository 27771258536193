import React from "react";
import { useParams } from "react-router-dom";
import AdminFAQ from "./AdminFAQ/AdminFAQ";

const AdminEditFAQ = () => {
  const params = useParams();

  const faqId = params.faqId;

  return (
    <div>
      <AdminFAQ faqType="edit" faqId={faqId} />
    </div>
  );
};

export default AdminEditFAQ;
