import React from 'react';
import BulkOnboardingComp from '../components/BulkOnboarding'
const BulkOnboarding = () => {
  return (
    <div>
      <BulkOnboardingComp />
    </div>
  );
};

export default BulkOnboarding;