import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { MdAccessTime } from "react-icons/md";
import styled from "styled-components";
import ArticleCategoryBatch from "./ArticleCategoryBatch";
import { useNavigate } from "react-router-dom";

export default function ArticleLists({ isRecentArticle }) {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <Row xs={1} sm={2} md={3} lg={4} className="g-4">
        {[
          "Travel",
          "Finance",
          "Health",
          "Fitness",
          "Travel",
          "Finance",
          "Health",
          "Fitness",
        ].map((category, idx) => (
          <Col
            key={idx}
            className="cursor-pointer article"
            onClick={() => {
              navigate("/blog/1");
            }}
          >
            <Card>
              {!isRecentArticle && (
                <Card.Img
                  variant="top"
                  src="https://i.ibb.co/RBLLSq5/food.jpg"
                />
              )}
              <Card.Body>
                {!isRecentArticle && (
                  <div className="article_category mb-3">
                    <ArticleCategoryBatch title={category} />
                  </div>
                )}
                <div className="mt-2 article_contents d-flex flex-column justify-content-between">
                  <Card.Title className="font-16">
                    Hidden Gems: Exploring Off-the-Beaten-Path Destinations
                  </Card.Title>
                  <Card.Text>
                    <div className="font-15 text-gray">
                      <MdAccessTime className="font-20 mb-1" /> 5min read
                    </div>
                  </Card.Text>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      {!isRecentArticle && (
        <div className="text-center mt-4 mt-md-5">
          <button className="btn nav-active">Load More</button>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .article {
    &:hover {
      .card-title {
        text-decoration: underline;
      }
      .article_category .batch {
        outline: 1px solid var(--gray);
      }
    }
  }
  .article_contents {
    min-height: 7rem;
  }
  .article_category {
    margin-top: -2.2rem;
  }
  .btn.nav-active {
    min-width: 140px;
  }
`;
