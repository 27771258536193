export const CREATE_BULK_APPLICATION_REQUEST =
  "CREATE_BULK_APPLICATION_REQUEST";
export const CREATE_BULK_APPLICATION_SUCCESS =
  "CREATE_BULK_APPLICATION_SUCCESS";
export const CREATE_BULK_APPLICATION_ERROR = "CREATE_BULK_APPLICATION_ERROR";

export const CREATE_SINGLE_APPLICATION_REQUEST =
  "CREATE_SINGLE_APPLICATION_REQUEST";
export const CREATE_SINGLE_APPLICATION_SUCCESS =
  "CREATE_SINGLE_APPLICATION_SUCCESS";
export const CREATE_SINGLE_APPLICATION_ERROR =
  "CREATE_SINGLE_APPLICATION_ERROR";

export const CREATE_WAITINGLIST_APPLICATION_REQUEST =
  "CREATE_WAITINGLIST_APPLICATION_REQUEST";
export const CREATE_WAITINGLIST_APPLICATION_SUCCESS =
  "CREATE_WAITINGLIST_APPLICATION_SUCCESS";
export const CREATE_WAITINGLIST_APPLICATION_ERROR =
  "CREATE_WAITINGLIST_APPLICATION_ERROR";

//GET REQUESTS
export const GET_BULK_APPLICATIONS_REQUEST = "GET_BULK_APPLICATIONS_REQUEST";
export const GET_BULK_APPLICATIONS_SUCCESS = "GET_BULK_APPLICATIONS_SUCCESS";
export const GET_BULK_APPLICATIONS_ERROR = "GET_BULK_APPLICATIONS_ERROR";

export const GET_SINGLE_APPLICATIONS_REQUEST =
  "GET_SINGLE_APPLICATIONS_REQUEST";
export const GET_SINGLE_APPLICATIONS_SUCCESS =
  "GET_SINGLE_APPLICATIONS_SUCCESS";
export const GET_SINGLE_APPLICATIONS_ERROR = "GET_SINGLE_APPLICATIONS_ERROR";

export const GET_SINGLE_APPLICATION_REQUEST = "GET_SINGLE_APPLICATION_REQUEST";
export const GET_SINGLE_APPLICATION_SUCCESS = "GET_SINGLE_APPLICATION_SUCCESS";
export const GET_SINGLE_APPLICATION_ERROR = "GET_SINGLE_APPLICATION_ERROR";

export const GET_WAITINGLIST_APPLICATIONS_REQUEST =
  "GET_WAITINGLIST_APPLICATIONS_REQUEST";
export const GET_WAITINGLIST_APPLICATIONS_SUCCESS =
  "GET_WAITINGLIST_APPLICATIONS_SUCCESS";
export const GET_WAITINGLIST_APPLICATIONS_ERROR =
  "GET_WAITINGLIST_APPLICATIONS_ERROR";

export const PERSIST_PROGRESS_DATA = "PERSIST_PROGRESS_DATA";
export const UNPERSIST_PROGRESS_DATA = "UNPERSIST_PROGRESS_DATA";
export const PERSIST_UPLOADS_PROGRESS_DATA = "PERSIST_UPLOADS_PROGRESS_DATA";
export const UNSET_APPLICATION_DATA = "UNSET_APPLICATION_DATA";
export const PERSIST_CURRENCY = "PERSIST_CURRENCY";
export const SET_UPLOADING = "SET_UPLOADING";
export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";

export const GET_VEHICLE_REQUEST = "GET_VEHICLE_REQUEST";
export const GET_VEHICLE_SUCCESS = "GET_VEHICLE_SUCCESS";
export const GET_VEHICLE_ERROR = "GET_VEHICLE_ERROR";

export const GET_VEHICLES_REQUEST = "GET_VEHICLES_REQUEST";
export const GET_VEHICLES_SUCCESS = "GET_VEHICLES_SUCCESS";
export const GET_VEHICLES_ERROR = "GET_VEHICLES_ERROR";

export const ADD_VEHICLE_REQUEST = "ADD_VEHICLE_REQUEST";
export const ADD_VEHICLE_SUCCESS = "ADD_VEHICLE_SUCCESS";
export const ADD_VEHICLE_ERROR = "ADD_VEHICLE_ERROR";

export const EDIT_VEHICLE_REQUEST = "EDIT_VEHICLE_REQUEST";
export const EDIT_VEHICLE_SUCCESS = "EDIT_VEHICLE_SUCCESS";
export const EDIT_VEHICLE_ERROR = "EDIT_VEHICLE_ERROR";
