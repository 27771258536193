import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Loading from "../Loading";
import missingCarImg from "../../dist/images/carImgMissing.jpg";
import { getAllAcceptableVehicles } from "../../redux/actions/application_actions";

const VehicleLists = () => {
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      let response = await dispatch(getAllAcceptableVehicles());
      setVehicles(response);
      setLoading(false);
    };
    getData();
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }
  return (
    <Wrapper className="driver-vehicles min-height-70 page-height padding-65 mb-5 pb-5">
      <div className="row justify-content-center">
        <div className="col-lg-10 px-4 px-lg-0">
          <div className="row px-3 px-2 mb-5">
            <h1 className="font-50 font-weight-900 text-light-black">
              List Of Acceptable Vehicles
            </h1>
            <p className="text-dark-gray font-17">
              Below are the list of acceptable vehicles. Fleetsimplify will
              accept any of the vehicles listed below. Also the acceptable
              vehicles and their specifications are derived from what the
              ride-sharing apps take. The list is subject to changes based on
              what the ride-sharing apps update.
            </p>
          </div>
          <div className="vehicle-container">
            {vehicles &&
              vehicles.map((vehicle, i) => (
                <div key={i} className="mb-5">
                  <div className="mb-3">
                    <ImageWrapper
                      image={
                        vehicle && vehicle.vehiclePhoto
                          ? vehicle.vehiclePhoto
                          : missingCarImg
                      }
                    />
                  </div>
                  <div className=" d-flex flex-wrap justify-content-between">
                    <div className="d-flex flex-column info-box bg-dark-white">
                      <span className="text-white bg-blue px-2 py-1 font-15 text-center">
                        Vehicle Make
                      </span>
                      <span className="text-dark-white bg-dark-white px-2 py-1 font-15 text-center">
                        {vehicle && vehicle.vehicleMake}
                      </span>
                    </div>
                    <div className="d-flex flex-column info-box bg-dark-white">
                      <span className="text-white bg-blue px-2 py-1 font-15 text-center">
                        Vehicle Model
                      </span>
                      <span className="text-dark-white bg-dark-white px-2 py-1 font-15 text-center">
                        {vehicle && vehicle.vehicleModel}
                      </span>
                    </div>
                    <div className="d-flex flex-column info-box bg-dark-white">
                      <span className="text-white bg-blue px-2 py-1 font-15 text-center">
                        Min. Year of Man.
                      </span>
                      <span className="text-dark-white bg-dark-white px-2 py-1 font-15 text-center">
                        {vehicle && vehicle.yearOfManufacture}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default VehicleLists;
const Wrapper = styled.div`
  .info-box {
    flex-basis: 30%;
    /* flex-wrap: wrap; */
    border: 1px solid #246eb9;
    border-radius: 3px;
    overflow: hidden;
  }

  .mobility-select {
    height: 40px;
    border-radius: 4px;
    background-color: #f5f5f5;
    appearance: auto;
  }

  .vehicle-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-row-gap: 15px;
    grid-column-gap: 60px;
  }

  @media screen and (max-width: 992px) {
    .vehicle-container {
      gap: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .vehicle-container {
      grid-template-columns: auto;
      gap: 20px;
      grid-row-gap: 0;
    }
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 345px;
  background: url(${(props) => props.image}) center/cover no-repeat;
  object-fit: cover;
  position: relative;
  border-radius: 5px;
`;
