import React from "react";
import styled from "styled-components";
import partnerLogo from "../../dist/images/partner_logo.svg";
import playStore from "../../dist/images/playStore.svg";
import appStore from "../../dist/images/appStore.svg";
import UserContactInfo from "./UserContactInfo";
import ScrollToTop from "../../Util/ScrollToTop";
import { TrackGoogleAnalyticsEvent } from "../../Util/google-analytics";
import RentVehicleCategory, {
  ClickedDownloadApp,
} from "../../Util/google-analytics/events/rentVehicle";

export default function Step3({ currentStep }) {
  if (currentStep !== 3) {
    return null;
  }

  return (
    <StepFormContainer className="pt-lg-4">
      <ScrollToTop />
      <div className="text-dark-gray max-w-800 text-center font-16 my-3 mt-md-4 mb-md-5">
        Car owners are obligated to onboard their cars on the
        Fleetsimplify-created app called <strong>"Instadriver Partner"</strong>.
        Make sure you drop us your contact for us to prioritize your car in the
        next car intake.
      </div>
      <div className="px-3 py-4 py-md-5 max-w-1000 section_wrapper">
        <div className="mb-md-3 max-w-750">
          <div className="text-center">
            <h2 className="font-weight-bold text-dark-gray font-30">
              Let’s get your contact for support & for prioritizing you!
            </h2>
            <p className="text-medium-gray font-17 mt-3 mt-md-4 px-md-4 px-lg-5">
              Share your phone and email. This will help our team assist you as
              you onboard your car to Instadriver Partner app. Or for any
              relevant support that can help you in your car monetization
              journey. Also to help us prioritize you on our waitlist. We only
              take a limited number of cars per month.
            </p>
          </div>
          <div className="">
            <UserContactInfo />
          </div>
        </div>
        <div className="max-w-700">
          <div className="text-center">
            <h2 className="font-weight-bold text-dark-gray font-30">
              Download our app and start the onboarding process
            </h2>
            <h6 className="text-maroon font-weight-bold font-20">
              (Download app if you already have the car)
            </h6>
            <p className="text-medium-gray font-17 mt-3">
              Tap the respective buttons, to be redirected to Playstore or
              Appstore to download Instadriver Partner app. Once you sign up on
              the app, you will access in-app video guides to help you onboard
              your car swiftly. Also,{" "}
              <span className="font-weight-bold text-dark-gray">
                do not forget to leave your contact details above.
              </span>
            </p>
          </div>
          <div className="d-flex flex-wrap justify-content-center gap-3 gap-xl-5 py-3 py-md-4">
            <div className="app_card d-flex flex-column justify-content-between align-items-center">
              <div className="app_card_header px-3 py-4 text-center">
                <h2>Mobile App</h2>
                <img src={partnerLogo} alt="instadriver" />
                <p className="m-0 mt-2">Instadriver partner</p>
              </div>
              <div className="w-100 app_card_footer d-flex p-2 justify-content-between">
                <img src={appStore} alt="app_store" />
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.instadriver.partner.app"
                  onClick={TrackGoogleAnalyticsEvent(
                    RentVehicleCategory,
                    ClickedDownloadApp,
                    window.location.pathname
                  )}
                >
                  <img src={playStore} alt="play_store" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StepFormContainer>
  );
}
const StepFormContainer = styled.div`
  .section_wrapper {
    background: #fffffe;
    border: 0.5px solid #998d8d;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    min-height: 500px;
    .app_card {
      width: 375px;
      background: #f8f8f8;
      border: 1px solid #c4c4c4;

      &_header {
        h2 {
          font-size: 24px;
          font-weight: 900;
          color: #666666;
        }
      }
      &_footer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 69px;
        background: #b56f68;
      }
    }
  }
`;
