import * as t from "../../types/inquiry_types";
import { PostRoute } from "../../../api/index";
import { getAuthConfig } from "../../../Util/Util";
const { Get, Delete, Patch } = PostRoute();

export const setActiveInquiry = (inquiry) => {
  return {
    type: t.SET_ACTIVE_INQUIRY,
    data: inquiry,
  };
};

export const setInquiries = (inquiries) => {
  return {
    type: t.SET_INQUIRIES,
    data: inquiries,
  };
};

export const setInquiryRead = (token, inquiry) => async (dispatch) => {
  try {
    const config = getAuthConfig(token);
    dispatch({ type: t.SET_INQUIRY_READ, data: inquiry });

    await Patch(`admin/contact-us/update/${inquiry._id}`, {}, config);
  } catch (err) {
    console.log("error ", err);
  }
};

export const deleteInquiry = (token, inquiry, handler) => async (dispatch) => {
  try {
    const config = getAuthConfig(token);

    dispatch({ type: t.DELETE_START });

    // dispatch({ type: t.DELETE_INQUIRY, data: inquiry });

    await Delete(`admin/contact-us/delete/${inquiry._id}`, config);
    const response = await Get("admin/contact-us", config);
    let data = response.data.response_data;

    for (var i = 0; i < data.length; i++) {
      data[i].selected = false;
    }
    dispatch({ type: t.DELETE_SUCCESS });
    handler();
    dispatch(setInquiries(data));
  } catch (err) {
    dispatch({ type: t.DELETE_ERROR });
  }
};

export const checkInquiry = (inquiry) => {
  return {
    type: t.CHECK_INQUIRY,
    data: inquiry,
  };
};

export const unCheckInquiry = (inquiry) => {
  return {
    type: t.UNCHECK_INQUIRY,
    data: inquiry,
  };
};

export const deleteInquiries =
  (token, inquiries, handler) => async (dispatch) => {
    const ids = inquiries.map((inq) => {
      return inq._id;
    });

    try {
      const config = getAuthConfig(token);

      dispatch({ type: t.DELETE_START });

      await Delete(`admin/contact-us/bulk-delete`, { data: ids, ...config });
      const response = await Get("admin/contact-us", config);
      let data = response.data.response_data;

      for (var i = 0; i < data.length; i++) {
        data[i].selected = false;
      }
      dispatch({ type: t.DELETE_SUCCESS });
      handler();
      dispatch(setInquiries(data));
    } catch (err) {
      dispatch({ type: t.DELETE_ERROR });
      console.log("error ", err.response);
    }
  };

export const getAllInquiries = (token) => async (dispatch) => {
  try {
    const config = getAuthConfig(token);

    dispatch({ type: t.FETCH_INQUIRIES_START });

    const response = await Get("admin/contact-us", config);

    if (
      response.data.response_data.status &&
      (response.data.response_data.status !== 200 ||
        response.data.response_data.status !== 201)
    ) {
      throw Error(response.data.response_data.message);
    }

    dispatch({
      type: t.FETCH_INQUIRIES_SUCCESS,
    });

    let data = response.data.response_data;

    for (var i = 0; i < data.length; i++) {
      data[i].selected = false;
    }

    dispatch(setInquiries(data));
    // dispatch(setInquiries([]));
    // return data;
  } catch (err) {
    dispatch({
      type: t.FETCH_INQUIRIES_ERROR,
      payload: err.message,
    });
  }
};

export const applyFilters = (filters) => (dispatch) => {
  dispatch({ type: t.APPLY_FILTERS, data: filters });
};

export const removeFilters = () => (dispatch) => {
  dispatch({ type: t.REMOVE_FILTERS });
};
