import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import VehiclesComp from "../../components/Admin/VehiclesComp";
import Loading from "../../components/Loading";
import { getAllVehicles } from "../../redux/actions/vehicle_actions";
import { useNavigate } from "react-router-dom";

const Vehicles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [active, setActive] = useState(1);
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await dispatch(getAllVehicles());

      setLoading(false);
    };
    getData();
  }, [dispatch]);

  const allVehicles = useSelector(
    (state) => state.vehicle_reducers.allVehicles
  );

  useEffect(() => {
    if (active === 1) {
      setVehicles(() => allVehicles.filter((vehicle) => vehicle.isAccepted));
    } else {
      setVehicles(() => allVehicles.filter((vehicle) => !vehicle.isAccepted));
    }
  }, [allVehicles, active]);

  const handleRoute = () => {
    let route = "/admin/add-vehicle";

    navigate(route);
  };

  return (
    <Wrapper className="col-xl-11 mx-auto admin-faqs ">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="font-weight-bold my-4">Vehicles</h2>
        <div className="d-flex align-items-center vehicle-change">
          <span
            onClick={(e) => {
              setActive(1);
              setVehicles(allVehicles.filter((vehicle) => vehicle.isAccepted));
            }}
            className={`${active === 1 && `vehicle-active`}`}
          >
            Acceptable
          </span>
          <span
            onClick={(e) => {
              setActive(2);
              setVehicles(allVehicles.filter((vehicle) => !vehicle.isAccepted));
            }}
            className={`${active !== 1 && `vehicle-active`}`}
          >
            Not Acceptable
          </span>
        </div>
        <button className="btn btn-blue font-17 br-3" onClick={handleRoute}>
          + Add Vehicle
        </button>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="w-100 bg-white min-height-50 content-box p-4">
          <VehiclesComp active={active} vehicles={vehicles} />
        </div>
      )}
    </Wrapper>
  );
};

export default Vehicles;

const Wrapper = styled.div`
  .content-box {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.23);
    border-radius: 3px;
  }
`;
