import React from "react";
import styled from "styled-components";

const MessageTemplate = ({ changeHandler }) => {
  const handleSelectTemplate = (val) => {
    changeHandler(val);
  };
  return (
    <>
      <h5 className="display-12 text-dark-gray font-weight-500 font-16 mb-5">
        Pick template message or create yours
      </h5>
      <Wrapper
        id="carouselExampleControls"
        className="carousel slide"
        data-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="card bg-light-purple">
              <div className="card-body row justify-content-center flex-column">
                <p className="card-text carousel-card-text mx-auto font-15">
                  We have more than 25 vehicles, and would like to discuss how
                  we can monetize them with Fleetsimplify.
                </p>
                <br />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleSelectTemplate(
                      "We have more than 25 vehicles, and would like to discuss how we can monetize them with Fleetsimplify. "
                    );
                  }}
                  className="btn shadow-sm template-button mb-4"
                >
                  Use this template
                </button>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="card bg-light-purple">
              <div className="card-body row justify-content-center flex-column">
                <p className="card-text carousel-card-text mx-auto font-15">
                  We intend to procure vehicles in bulk, something like 25 or
                  more and would like to discuss how we can monetize them with
                  Fleetsimplify.
                </p>
                <br />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleSelectTemplate(
                      "We intend to procure vehicles in bulk, something like 25 or more and would like to discuss how we can monetize them with Fleetsimplify. "
                    );
                  }}
                  className="btn shadow-sm template-button mb-4"
                >
                  Use this template
                </button>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="card bg-light-purple">
              <div className="card-body row justify-content-center flex-column">
                <p className="card-text carousel-card-text mx-auto font-15">
                  We would like to partner with Fleetsimplify in many aspects
                  including providing them with our many vehicles.
                </p>
                <br />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleSelectTemplate(
                      "We would like to partner with Fleetsimplify in many aspects including providing them with our many vehicles. "
                    );
                  }}
                  className="btn shadow-sm template-button mb-4"
                >
                  Use this template
                </button>
              </div>
            </div>
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleControls"
          role="button"
          data-slide="prev"
        >
          <i
            className="fa fa-angle-left"
            style={{
              fontSize: 25,
              color: "#FFBB00",
              marginRight: "11px",
            }}
          />
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleControls"
          role="button"
          data-slide="next"
        >
          <i
            className="fa fa-angle-right"
            style={{
              fontSize: 25,
              color: "#FFBB00",
              marginLeft: "15px",
            }}
          />
          <span className="sr-only">Next</span>
        </a>
        <ol className="carousel-indicators">
          <li
            data-target="#carouselExampleControls"
            data-slide-to={0}
            className="active"
          />
          <li
            data-target="#carouselExampleControls"
            data-slide-to={1}
            className
          />
          <li
            data-target="#carouselExampleControls"
            data-slide-to={2}
            className
          />
        </ol>
      </Wrapper>
    </>
  );
};

export default MessageTemplate;
const Wrapper = styled.div`
  .template-button {
    background-color: white;
    color: var(--light-orange);
    margin: 0 auto;
    border-radius: 10px !important;
    width: 187px;
    height: 40px;
    border: none;
    font-size: 16px !important;
  }

  .carousel-control-prev {
    margin-left: -55px;
  }

  .carousel-control-next {
    margin-right: -53px;
  }

  @media (max-width: 650px) {
    .carousel-control-prev {
      margin-left: -7vw;
    }

    .carousel-control-next {
      margin-right: -7vw;
    }
  }

  .carousel-card-text {
    width: 90%;
    display: inline-block;
    margin-top: 15px !important;
    color: var(--light-black);
    text-align: justify;
  }

  .carousel-indicators li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 10px;
    text-indent: 0;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    background-color: grey;
    position: relative;
    bottom: -50px;
  }
  .carousel-indicators .active {
    width: 10px;
    height: 10px;
    margin: 10px;
    background-color: #914a9c;
  }
`;
