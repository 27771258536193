import React from "react";
import AdminForm from "./AdminForm/AdminForm";

const AddNewAdmin = () => {
  return (
    <div className="add-new-admin">
      <AdminForm formType="add" />
    </div>
  );
};

export default AddNewAdmin;
