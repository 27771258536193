export const getAuthConfig = (token) => {
  return {
    headers: { Authorization: `bearer ${token}` },
  };
};

export function validatePhoneNumber(number) {
  const phoneNumberRegex = /^[+]?[\d\s()-]{6,20}$/;
  return phoneNumberRegex.test(number)
}

export function validateEmail(email) {
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  return emailRegex.test(email)
}