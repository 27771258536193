import * as auth_types from "../../types/auth_types";

const initialState = {
  auth_credentials: {},
  loading: false,
  error: "",
  success: "",
  email_exists: false,
};

export const auth_reducers = (state = initialState, action) => {
  switch (action.type) {
    case auth_types.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case auth_types.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Login successfull",
        error: "",
        auth_credentials: action.data,
      };

    case auth_types.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case auth_types.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case auth_types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Password reset successful",
        error: "",
        auth_credentials: action.data,
      };

    case auth_types.RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case auth_types.LOGOUT:
      return {
        ...state,
        loading: false,
        success: "Logout successful",
        error: "",
        auth_credentials: {},
      };

    case auth_types.SET_EMAIL_EXISTS:
      return { ...state, email_exists: action.data };

    case auth_types.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case auth_types.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Password reset link sent. Please check your email",
        error: "",
      };

    case auth_types.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case auth_types.CREATE_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case auth_types.CREATE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Admin account created successfully",
        error: "",
      };

    case auth_types.CREATE_ADMIN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case auth_types.EDIT_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case auth_types.EDIT_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Admin account updated successfully",
        error: "",
      };

    case auth_types.EDIT_ADMIN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    default:
      return state;
  }
};
