import React, { useEffect } from "react";
import { filterType } from "../../Util/types";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "../../Util/useQuery";
import styled from "styled-components";
import { TrackGoogleAnalyticsEvent } from "../../Util/google-analytics";
import RentVehicleCategory, {
  ToggledToYearly,
} from "../../Util/google-analytics/events/rentVehicle";

export default function MonthlyYearlyTab({ activeFilter, setActiveFilter }) {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();

  const addQuery = (key, value) => {
    let pathname = location.pathname;
    let searchParams = new URLSearchParams(location.search);
    searchParams.set(key, value);
    navigate({
      pathname: pathname,
      search: searchParams.toString(),
    });
  };

  useEffect(() => {
    const filter = query.get("filter");
    setActiveFilter(filter ?? filterType.MONTHLY);
  }, [query, setActiveFilter]);

  return (
    <TabWrapper maxWidth="225px" className="date_filter_tab_container">
      <div className="date_filter_tab d-flex p-1">
        <button
          type="button"
          className={`bg-transparent border-0 px-3 py-1 text-decoration-none flex-grow-1 text-center ${
            activeFilter === filterType.MONTHLY && "tab_active"
          }`}
          onClick={() => {
            setActiveFilter(filterType.MONTHLY);
            addQuery("filter", filterType.MONTHLY);
          }}
        >
          Monthly
        </button>
        <button
          type="button"
          className={`bg-transparent border-0 px-3 py-1 text-decoration-none flex-grow-1 text-center ${
            activeFilter === filterType.YEARLY && "tab_active"
          }`}
          onClick={() => {
            TrackGoogleAnalyticsEvent(
              RentVehicleCategory,
              ToggledToYearly,
              window.location.pathname
            );
            setActiveFilter(filterType.YEARLY);
            addQuery("filter", filterType.YEARLY);
          }}
        >
          Yearly
        </button>
      </div>
    </TabWrapper>
  );
}

export const TabWrapper = styled.div`
  max-width: ${(props) => props.maxWidth && props.maxWidth};
  margin: 0 auto;

  .date_filter_tab {
    background: #ffffff;
    border: 0.5px solid #998d8d;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    a {
      color: #646161;
      font-size: 19px;
    }
    .tab_active {
      background: #5b5b5b !important;
      color: #ffffff;
      border-radius: 30px;
    }
  }
`;
