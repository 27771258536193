import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

export default function CustomSlider(props) {
  const wrapperRef = useRef(null);
  const slidesWrapperRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slides, setSlides] = useState();
  const [hasMoreToShow, setHasMoreToShow] = useState(false);
  const instanceId = useRef(props.instanceId ?? uuidv4());
  const prevBtnRef = useRef(null);
  const nextBtnRef = useRef(null);
  const [settingUp, setSettingUp] = useState(true);

  useEffect(() => {
    const slides_ = document.querySelectorAll(`.__slide-${instanceId.current}`);
    setHasMoreToShow((slides_?.length ?? 0) > 1);

    slides_.forEach((slide, index) => {
      const tempSlide = slide;
      tempSlide.style.transform = `translateX(${index * 100}%)`;
      // Fix stacked slides flicker
      if (index === 0) tempSlide.style.visibility = `visible`;

      const tempSlidesToShow = props.slidesToShow ? props.slidesToShow : 1;

      const slideWidth = 100 / tempSlidesToShow;

      if (tempSlidesToShow > 1) {
        setHasMoreToShow((slides_?.length ?? 0) > tempSlidesToShow);
        tempSlide.style.width = `${slideWidth}%`;
        if (index <= tempSlidesToShow - 1) {
          tempSlide.style.visibility = `visible`;
        }
      } else {
        tempSlide.style.width = `100%`;
      }
    });
    setSlides(slides_);

    setSettingUp(false);
  }, [props.slidesToShow, props.slides]);

  useEffect(() => {
    let intervalId = null;
    if (slides?.length && props.autoSlide) {
      intervalId = setInterval(() => {
        if (nextBtnRef.current) {
          nextBtnRef.current.click();
        }
      }, props.autoSlideDuration ?? 5000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [props.autoSlide, props.autoSlideDuration, slides]);

  useEffect(() => {
    if (
      props.stopOnHover &&
      props.autoSlide &&
      slidesWrapperRef.current &&
      nextBtnRef.current &&
      prevBtnRef.current
    ) {
      // OnhoverStop logic here
      // slidesWrapperRef.current.addEventListener('mouseenter', () => {
      //   if(slidesWrapperRef.current){
      //     slidesWrapperRef.current.classList.add('stop');
      //   }
      // });
      // slidesWrapperRef.current.addEventListener('mouseleave', () => {
      //   if(slidesWrapperRef.current){
      //     slidesWrapperRef.current.classList.remove('stop');
      //   }
      // });
    }
  }, [props.autoSlide, props.stopOnHover]);

  const handlePrevClick = (e) => {
    e.preventDefault();

    let toViewSlide = currentSlide - 1;

    if (props.infinite) {
      if (currentSlide === 0) {
        toViewSlide = props.slides.length - 1;
      } else {
        toViewSlide = currentSlide - 1;
      }
    } else {
      if (currentSlide === 0) {
        return;
      } else {
        toViewSlide = currentSlide - 1;
      }
    }

    setCurrentSlide(toViewSlide);

    slides?.forEach((slide, index) => {
      const tempSlide = slide;
      tempSlide.style.visibility = `visible`;
      tempSlide.style.transform = `translateX(${(index - toViewSlide) * 100}%)`;
    });
    // handleSlide('left');
  };

  const handleNextClick = (e) => {
    e.preventDefault();

    let toViewSlide = currentSlide + 1;

    if (props.infinite) {
      if (currentSlide === props.slides.length - 1) {
        toViewSlide = 0;
      } else {
        toViewSlide = currentSlide + 1;
      }
    } else {
      if (currentSlide === props.slides.length - 1) {
        return;
      } else {
        toViewSlide = currentSlide + 1;
      }
    }

    setCurrentSlide(toViewSlide);

    slides?.forEach((slide, index) => {
      const tempSlide = slide;
      tempSlide.style.visibility = `visible`;
      tempSlide.style.transform = `translateX(${(index - toViewSlide) * 100}%)`;
    });

    // handleSlide('right');
  };

  return (
    <Wrapper
      ref={wrapperRef}
      height={props.height}
      width={props.width}
      settingup={`${settingUp}`}
      className="__slider"
    >
      <ArrowWrapper>
        {props.showArrows && hasMoreToShow && (
          <SlideArrow
            onClick={handlePrevClick}
            ref={prevBtnRef}
            className="p-0 prev__arrow"
            role="button"
          >
            {props.customPrevArrow ? (
              props.customPrevArrow
            ) : (
              <IoIosArrowBack className="icon-30" />
            )}
          </SlideArrow>
        )}
      </ArrowWrapper>
      <SlidesWrapper ref={slidesWrapperRef} instanceid={instanceId.current}>
        {props.slides?.map((slide, index) => (
          <div
            key={index}
            className={`__slide-${instanceId.current}`}
            style={{
              paddingRight: props.spaceBetweenSlides
                ? props.spaceBetweenSlides
                : "0",
              paddingLeft: props.spaceBetweenSlides
                ? props.spaceBetweenSlides
                : "0",
            }}
          >
            <div className="__slide h-100">{slide}</div>
          </div>
        ))}
      </SlidesWrapper>
      <ArrowWrapper>
        {props.showArrows && hasMoreToShow && (
          <SlideArrow
            onClick={handleNextClick}
            ref={nextBtnRef}
            className="p-0 next__arrow"
            role="button"
          >
            {props.customNextArrow ? (
              props.customNextArrow
            ) : (
              <IoIosArrowForward className="icon-30" />
            )}
          </SlideArrow>
        )}
      </ArrowWrapper>
    </Wrapper>
  );
}

// visibility: ${(props) => (props.settingup ? "hidden" : "visible")}; should be in Wrapper below

const Wrapper = styled.div`
  width: ${(props) => props.width ?? "100%"};
  height: ${(props) => props.height ?? "100%"};
  display: flex;
  align-items: center;
  position: relative;

  .prev__arrow,
  .next__arrow {
    z-index: 1;
    position: absolute;
    margin: 0;
    padding: 0;
  }

  .prev__arrow {
    left: 0;
  }

  .next__arrow {
    right: 0;
  }
`;

const SlidesWrapper = styled.div`
  width: calc(100% - 60px);
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;

  .__slide-${(props) => props.instanceid} {
    height: 100%;
    position: absolute;
    transition: all 0.5s;
    display: inline-block;
  }
`;
const SlideArrow = styled.span`
  :focus {
    outline: none;
    box-shadow: none;
  }
`;

const ArrowWrapper = styled.div`
  width: 30px;
  display: flex;
  align-items: center;
`;
