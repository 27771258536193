import React from "react";
import parse from "html-react-parser";

export default function FaqsCard({ data }) {
  return (
    <div className="faq-card shadow">
      <div className="faq-header p-3 px-lg-5">
        <p className="m-0">{data && data.question}</p>
      </div>
      <div className="faq-content p-3 p-lg-5 pt-3 pt-lg-3">
        <p className="m-0">{parse(data && data.answer)}</p>
      </div>
    </div>
  );
}
