export const FETCH_ADMIN_REQUEST = "FETCH_ADMIN_REQUEST";
export const FETCH_ADMIN_SUCCESS = "FETCH_ADMIN_SUCCESS";
export const FETCH_ADMIN_ERROR = "FETCH_ADMIN_ERROR";

export const FETCH_ADMINS_REQUEST = "FETCH_ADMINS_REQUEST";
export const FETCH_ADMINS_SUCCESS = "FETCH_ADMINS_SUCCESS";
export const FETCH_ADMINS_ERROR = "FETCH_ADMINS_ERROR";

export const DELETE_ADMIN_REQUEST = "DELETE_ADMIN_REQUEST";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_ERROR = "DELETE_ADMIN_ERROR";
