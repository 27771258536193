import * as application_types from "../../types/application_types";
import { PostRoute } from "../../../api";
import { getAuthConfig } from "../../../Util/Util";
import axios from "axios";

const INSTADRIVER_API_URL = "https://instadriver.co/api/v2";

const { Get, Post, Patch } = PostRoute();

export const createBulkApplication = (data) => async (dispatch) => {
  try {
    dispatch({
      type: application_types.CREATE_BULK_APPLICATION_REQUEST,
    });

    const response = await Post("/application/bulk/create", data);

    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    dispatch({
      type: application_types.CREATE_BULK_APPLICATION_SUCCESS,
      payload: response.data.response_data,
    });
    return response;
  } catch (err) {
    dispatch({
      type: application_types.CREATE_BULK_APPLICATION_ERROR,
      payload: err.message,
    });
  }
};

export const createSingleApplication = (data) => async (dispatch) => {
  try {
    dispatch({
      type: application_types.CREATE_SINGLE_APPLICATION_REQUEST,
    });

    const response = await Post("/application/single/create", data);

    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    dispatch({
      type: application_types.CREATE_SINGLE_APPLICATION_SUCCESS,
      payload: response.data.response_data,
    });
    console.log(`response`, response);
    return response;
  } catch (err) {
    dispatch({
      type: application_types.CREATE_SINGLE_APPLICATION_ERROR,
      payload: err.message,
    });
  }
};

export const createWaitingApplication = (data) => async (dispatch) => {
  try {
    dispatch({
      type: application_types.CREATE_WAITINGLIST_APPLICATION_REQUEST,
    });

    const response = await Post("/application/other/create", data);

    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    dispatch({
      type: application_types.CREATE_WAITINGLIST_APPLICATION_SUCCESS,
      payload: response.data.response_data,
    });
  } catch (err) {
    dispatch({
      type: application_types.CREATE_WAITINGLIST_APPLICATION_ERROR,
      payload: err.message,
    });
  }
};

export const getBulkApplications = (token) => async (dispatch) => {
  try {
    const config = await getAuthConfig(token);

    dispatch({
      type: application_types.GET_BULK_APPLICATIONS_REQUEST,
    });

    const response = await Get("/admin/applications/bulk", config);

    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    dispatch({
      type: application_types.GET_BULK_APPLICATIONS_SUCCESS,
      payload: response.data.response_data,
    });
    return response;
  } catch (err) {
    dispatch({
      type: application_types.GET_BULK_APPLICATIONS_ERROR,
      payload: err.message,
    });
  }
};

export const getSingleApplications = (token) => async (dispatch) => {
  try {
    const config = await getAuthConfig(token);

    dispatch({
      type: application_types.GET_SINGLE_APPLICATIONS_REQUEST,
    });

    const response = await Get("/admin/applications/other", config);

    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    dispatch({
      type: application_types.GET_SINGLE_APPLICATIONS_SUCCESS,
      payload: response.data.response_data,
    });
  } catch (err) {
    dispatch({
      type: application_types.GET_SINGLE_APPLICATIONS_ERROR,
      payload: err.message,
    });
  }
};

export const getWaitingListApplications = (token) => async (dispatch) => {
  try {
    const config = await getAuthConfig(token);

    dispatch({
      type: application_types.GET_WAITINGLIST_APPLICATIONS_REQUEST,
    });

    const response = await Get("/admin/applications/waiting-list", config);

    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    dispatch({
      type: application_types.GET_WAITINGLIST_APPLICATIONS_SUCCESS,
      payload: response.data.response_data,
    });
  } catch (err) {
    dispatch({
      type: application_types.GET_WAITINGLIST_APPLICATIONS_ERROR,
      payload: err.message,
    });
  }
};

export const getSingleApplication = (id, token) => async (dispatch) => {
  try {
    const config = await getAuthConfig(token);
    dispatch({
      type: application_types.GET_SINGLE_APPLICATION_REQUEST,
    });

    const response = await Get(`admin/applications/media/${id}`, config);

    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    dispatch({
      type: application_types.GET_SINGLE_APPLICATION_SUCCESS,
      payload: response.data.response_data,
    });

    return response.data.response_data;
  } catch (err) {
    dispatch({
      type: application_types.GET_SINGLE_APPLICATION_ERROR,
      payload: err.message,
    });
  }
};

export const persistProgressData = (data) => {
  return {
    type: application_types.PERSIST_PROGRESS_DATA,
    data: data,
  };
};

export const unPersistProgressData = () => {
  return {
    type: application_types.UNPERSIST_PROGRESS_DATA,
  };
};

// UNPERSIST_PROGRESS_DATA

export const persistUploadsProgressData = (data) => {
  return {
    type: application_types.PERSIST_UPLOADS_PROGRESS_DATA,
    data: data,
  };
};

export const unsetApplicationData = (make) => {
  return {
    type: application_types.UNSET_APPLICATION_DATA,
    data: make,
  };
};

export const persistCurrency = (curr) => {
  return {
    type: application_types.PERSIST_CURRENCY,
    data: curr,
  };
};

export const uploadFile = (data) => async (dispatch) => {
  try {
    dispatch({
      type: application_types.SET_UPLOADING,
      data: true,
    });
    const res = await axios.post(
      `${INSTADRIVER_API_URL}/uploads/upload/public`,
      data,
      {
        onUploadProgress: (event) => {
          const progress = Math.round(
            Math.round(100 * event.loaded) / event.total
          );
          dispatch({
            type: application_types.SET_UPLOAD_PROGRESS,
            data: progress,
          });
        },
      }
    );
    return res?.data?.url;
  } catch (err) {
    console.log(err);
    dispatch({
      type: application_types.SET_UPLOADING,
      data: false,
    });
    dispatch({ type: application_types.SET_UPLOAD_PROGRESS, data: 0 });
  }
};

export const getVehicle = (id, token) => async (dispatch) => {
  try {
    const config = await getAuthConfig(token);

    const response = await Get("/admin/applications/waiting-list", config);

    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    return response.data.response_data;
  } catch (err) {
    dispatch({
      type: application_types.GET_VEHICLE_ERROR,
      payload: err.message,
    });
  }
};

export const getAllVehicles = (token) => async (dispatch) => {
  try {
    const config = await getAuthConfig(token);

    const response = await Get("/admin/applications/waiting-list", config);

    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    return response.data.response_data;
  } catch (err) {
    dispatch({
      type: application_types.GET_VEHICLES_ERROR,
      payload: err.message,
    });
  }
};

export const getAllAcceptableVehicles = () => async (dispatch) => {
  try {
    const response = await Get("admin/vehicle/acceptable");

    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    return response.data.response_data;
  } catch (err) {
    dispatch({
      type: application_types.GET_VEHICLES_ERROR,
      payload: err.message,
    });
  }
};

export const addVehicle = (token, data) => async (dispatch) => {
  try {
    const config = await getAuthConfig(token);

    const response = await Post("admin/vehicle/create", data, config);

    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    // return response.data.response_data;
  } catch (err) {
    dispatch({
      type: application_types.ADD_VEHICLE_ERROR,
      payload: err.message,
    });
  }
};

export const editVehicle = (token, data) => async (dispatch) => {
  try {
    const config = await getAuthConfig(token);

    const response = await Patch("admin/vehicle/update", data, config);

    if (response.data.response_code !== 200) {
      throw new Error(response.data.response_data.message);
    }

    // return response.data.response_data;
  } catch (err) {
    dispatch({
      type: application_types.EDIT_VEHICLE_ERROR,
      payload: err.message,
    });
  }
};
