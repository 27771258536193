import React from "react";
import { Modal } from "react-bootstrap";
import { RiCloseCircleFill } from "react-icons/ri";

const DriverItemModal = ({
  setShowArchiveModal,
  currentModelId,
  handleDelete,
  ...props
}) => {
  return (
    <Modal
      {...props}
      size="lg"
      centered
    >
      <Modal.Header>
        <div className="d-flex w-100">
          <h3 className="w-100">Delete this Vehicle Model?</h3>
          <div className="text-right">
            <RiCloseCircleFill
              onClick={() => props?.onHide()}
              className="icon-30 text-medium-dark"
            />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <h3 className="text-center">Heads up!</h3> 
        <p className=" mt-4 mb-5">
          If you remove this vehicle model, it will
          no longer appear on the list of vehicles in your vehicle calculater.
          Are you sure you want to remove this model?
        </p>
        <div className="modal-footer border-0 d-flex justify-content-between">
          <button
            // className="btn btn-lighter-secondary br-5"
            className="btn btn-danger"
            type="button"
            onClick={() => handleDelete(currentModelId)}
          >
            Yes, Remove Vehicle Model
          </button>
          <button
            type="button"
            // className="btn btn-lighter-secondary-outline br-5"
            className="btn btn-blue"
            onClick={() => props.onHide()}
          >
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DriverItemModal;
