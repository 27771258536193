import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { earningPlans } from "../../data";
import ButtonOutlineBlue from "../common/ButtonOutlineBlue";
import CustomStyledButton from "../common/CustomStyledButton";
import AnsQuestionWarningModal from "./AnsQuestionWarningModal";
import ScrollToTop from "../../Util/ScrollToTop";
import PricingEarning from "../pricingAndEarning";
import { useDispatch, useSelector } from "react-redux";
import { persistProgressData } from "../../redux/actions/application_actions";
import { TrackGoogleAnalyticsEvent } from "../../Util/google-analytics";
import RentVehicleCategory, {
  AnsweredQuestion,
  GoToLastStep,
  ViewedTerms,
} from "../../Util/google-analytics/events/rentVehicle";

const Step2 = ({ currentStep, setCurrentStep }) => {
  const [warningModal, setWarningModal] = useState(false);
  const [ans1, setAns1] = useState("");
  const [ans2, setAns2] = useState("");
  const [ans3, setAns3] = useState("");
  const [agreementTimeRead, setAgreementTimeRead] = useState("");
  const [emptyQuesNo, setEmptyQuesNo] = useState(1);
  const [allQuesAnswered, setAllQuesAnswered] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector(
    (state) => state.application_reducers.applicationProgress
  );
  const [isAbove1000, setIsAbove1000] = useState(false);

  useEffect(() => {
    if (data.step1?.vehicleCC) {
      const numVal = parseInt(data.step1?.vehicleCC, 10);
      if (numVal >= 1000) {
        setIsAbove1000(true);
      } else {
        setIsAbove1000(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (ans1) {
      if (ans2) {
        setEmptyQuesNo(3);
      } else {
        setEmptyQuesNo(2);
      }
    }

    if (ans1 && ans2 && ans3) {
      setAllQuesAnswered(true);
    } else {
      setAllQuesAnswered(false);
    }
  }, [ans1, ans2, ans3]);

  useEffect(() => {
    if (isAbove1000) {
      setAns3("Commission");
    }
  }, [isAbove1000]);

  const handleQuesAnsClick = (e, answer, id) => {
    e.preventDefault();
    if (id === 1) {
      setAns1(answer);
    } else if (id === 2) {
      setAns2(answer);
    } else if (id === 3) {
      setAns3(answer);
    }
  };

  const handleProceedNextStep = (e) => {
    e.preventDefault();
    if (ans1 && ans2 && ans3) {
      dispatch(
        persistProgressData({
          step: "step2",
          payload: {
            agreementTimeRead: agreementTimeRead,
            earningPlans: {
              doHaveCar: ans1,
              rentalPeriod: ans2,
              rentalPlan: ans3,
            },
          },
        })
      );
      setCurrentStep(3);
    } else {
      setWarningModal(true);
    }
  };

  const handleUpdateAgreementTimeRead = () => {
    const time = new Date();
    setAgreementTimeRead(time.toISOString());
    TrackGoogleAnalyticsEvent(
      RentVehicleCategory,
      ViewedTerms,
      window.location.pathname
    );
  };

  const planQuestions = isAbove1000
    ? earningPlans.questions.slice(0, 2)
    : earningPlans.questions;

  if (currentStep !== 2) {
    return null;
  }

  return (
    <StepFormContainer className="pt-lg-4">
      <ScrollToTop />
      <div className="text-dark-gray max-w-800 text-center font-17 my-3 my-md-4">
        These are the earnings you will get when you rent your selected car to
        Fleetsimplify. The less the amount you get, the more you de-risk
        yourself or the better the plan. For detailed explanation of each
        feature/perk listed, read the provided “Agreements” for each plan.
      </div>
      <div className="p-2 max-w-1000 section_wrapper text-center">
        <PricingEarning
          handleUpdateAgreementTimeRead={handleUpdateAgreementTimeRead}
        />
        {planQuestions.map((item) => {
          const { id, question, answers } = item;
          const instruction1 = earningPlans.instructions[0];
          const instruction2 = earningPlans.instructions[1];

          let tempAnswers = [...answers];
          if (id === 3) {
            if (isAbove1000) {
              tempAnswers = tempAnswers.slice(3);
            } else {
              tempAnswers = tempAnswers.slice(0, 3);
            }
          }

          return (
            <div key={id} className="plan_question my-5 px-md-4 px-lg-5">
              <div className="d-flex gap-3 align-items-center">
                <div className="quesNo bg-blue font-weight-bold text-white p-3 rounded-circle font-17">
                  Q.{id}
                </div>
                <h2 className="text-start font-weight-bold text-light-black font-30 mb-0">
                  {question}
                </h2>
              </div>
              <div className="text-start instructions mt-3 mt-lg-0">
                <p className="text-medium-gray font-17">
                  {id === 3 ? instruction1 : instruction2}
                </p>
                <div className="d-flex flex-wrap gap-3 gap-md-5 justify-content-center">
                  {tempAnswers.map((item) => {
                    return (
                      <ButtonOutlineBlue
                        title={id === 1 ? ans1 : id === 2 ? ans2 : ans3}
                        key={item.id}
                        maxWidth={"225px"}
                        buttonText={item.answer}
                        short={item.short}
                        onButtonClick={(e) => {
                          handleQuesAnsClick(e, item.short, id);
                          TrackGoogleAnalyticsEvent(
                            RentVehicleCategory,
                            AnsweredQuestion,
                            `Question ${id}`
                          );
                        }}
                        image={item.image}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
        <CustomStyledButton
          title={
            allQuesAnswered
              ? "Thanks for expressing interest in the plan. Onboard car or sign up"
              : "Answer the above 3 questions then proceed to 3rd and last step"
          }
          classNames={`my-3 my-md-4 text-white font-23 font-weight-bold 
          ${allQuesAnswered ? "bg-steel-blue" : "bg-maroon"}`}
          onClick={(e) => {
            TrackGoogleAnalyticsEvent(
              RentVehicleCategory,
              GoToLastStep,
              window.location.pathname
            );
            handleProceedNextStep(e);
          }}
          hasRightIcon
        />
        <p className="text-medium-gray font-17 mb-4 mb-md-5">
          For efficiency and for our zeal to provide stellar services, we only
          accept a limited number of cars per month. Make sure you provide your
          contact details in the next step and tap{" "}
          <span className="font-weight-bold text-dark-gray">
            “Prioritize me”
          </span>{" "}
          for us to prioritize you on our waitlist.
        </p>
      </div>
      {warningModal && (
        <AnsQuestionWarningModal
          show={warningModal}
          onHide={() => setWarningModal(false)}
          emptyQuesNo={emptyQuesNo}
        />
      )}
    </StepFormContainer>
  );
};

export default Step2;

const StepFormContainer = styled.div`
  .section_wrapper {
    background: #fffffe;
    border: 0.5px solid #998d8d;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    min-height: 500px;
  }

  .plan_question {
    .instructions {
      margin-left: 4.6rem;
    }
  }
`;
