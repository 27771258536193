import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../dist/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../redux/actions/auth_actions";

const AdminForgotPassword = () => {
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [data, setData] = useState({
    email: "",
    role: "ADMIN",
  });

  const token = useSelector(
    (state) => state.auth_reducers.auth_credentials.token
  );

  useEffect(() => {
    if (token) {
      navigate("/admin/dashboard");
    }
  }, [navigate, token]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleForgotPassword = async (e) => {
    try {
      e.preventDefault();

      if (data && data.email === "") {
        throw Error("Email field cannot be empty");
      } else {
        // setLoading(true);
        const response = await dispatch(forgotPassword(data));
        if (response.response_code !== 200) {
          throw Error(response.response_data.split("_").join(" "));
        } else if (response) {
          setSuccess(
            "Password reset link sent successfully. Please check your email"
          );

          setData({
            email: "",
            role: "ADMIN",
          });
        }

        // setLoading(false);

        setTimeout(() => {
          setError("");
          setSuccess("");
        }, 7000);
      }
    } catch (err) {
      setError(err.message);

      setTimeout(() => {
        setError("");
        setSuccess("");
      }, 7000);
    }
  };

  return (
    <div className="row admin-forgot-password min-height-100">
      <div className="col-md-6 admin-forgot-password-left"></div>
      <div className="col-md-6">
        <div className="admin-forgot-password-right bg-blue ">
          <form
            className="d-flex align-items-center justify-content-center flex-column"
            onSubmit={handleForgotPassword}
          >
            <p>
              <img
                src={logo}
                alt="Fleetsimplify Logo"
                className="cursor-pointer logo"
                onClick={() => navigate("/")}
              />
            </p>
            <h2 className="title  my-3 text-uppercase text-white">
              Forgot Password
            </h2>
            {error && (
              <p className="error-box my-3">
                {error && error[0].toUpperCase() + error.slice(1).toLowerCase()}
              </p>
            )}
            {success && <p className="success-box my-3">{success}</p>}

            <div className="form-group">
              <span className="fa fa-user"></span>
              <input
                type="email"
                name="email"
                id="email"
                className="form-control"
                autoComplete="off"
                placeholder="Email address"
                onChange={handleChange}
                value={data && data.email}
              />
            </div>

            <button
              type="submit"
              className="btn btn-outline-blue my-3"
              onClick={handleForgotPassword}
            >
              Reset Password
            </button>
            <Link to="/admin/login" className="f-password text-white">
              <span className="fa fa-long-arrow-left"></span> Back to Login
            </Link>
          </form>
        </div>
      </div>
      {/* <AdminCoverSide /> */}
    </div>
  );
};

export default AdminForgotPassword;
